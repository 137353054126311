import React from "react";
import "./Products.css";
import { Link } from "react-router-dom";
import contact from "../Images/Group-of-customer-service-representatives.jpg";

export default function ProductMixItaly() {
  return (
    <>
      <div className="products "></div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-4 ">
            <Link to="/products/artichoke">
              {" "}
              <div className="ARTICHOKE"> </div>
            </Link>
            <p style={{ color: "#4e732c", textAlign: "center" }}> CARCIOFO</p>
          </div>
          <div className="col-md-4 ">
            <Link to="/products/lemon">
              {" "}
              <div className="LEMON"> </div>{" "}
            </Link>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              LIMONE + CIPOLLA
            </p>
          </div>
          <div className="col-md-4 ">
            <Link to="/products/cauliflower">
              <div className="CAULIFLOWER"> </div>
            </Link>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              CAVOLFIORE + CETRIOLO
            </p>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="contactContect">
                  <h6> contattaci</h6>

                  <p>quando hai una domanda siamo qui per aiutarti</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}

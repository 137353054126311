import React from "react";
import "./Packages.css";
import contact from "../Images/Group-of-customer-service-representatives.jpg";
import image1 from "../Images/Mr.Olive/packages/Glass/_KH13145.jpg";
import image2 from "../Images/Mr.Olive/packages/Metal/jpg web/4.jpg";
import image3 from "../Images/Mr.Olive/packages/plastic/Bucket 12 Litres.jpg";

import { Link } from "react-router-dom";

export default function Packages() {
  return (
    <>
      <div className="packages ">
        <div className="container" style={{ height: "100%" }}>
          <div className="row" style={{ height: "100%" }}>
            <div
              className="col-md-12 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <h1 className="text-white">PACKAGES</h1>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container P-4">
        <div className="row  p-4">
          <div className="col-md-4 ">
            <div className=" imgItem">
              <Link to="/products/subCategory8">
                <img
                  id="3"
                  src={image3}
                  alt="image not found"
                  className="packagaya"
                />
              </Link>{" "}
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>بلاستيك</p>
          </div>
          <div className="col-md-4 ">
            <div className=" imgItem">
              <Link to="/products/subCategory7">
                <img
                  id="3"
                  src={image2}
                  alt="image not found"
                  className="packagaya"
                />
              </Link>{" "}
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>صفائح</p>
          </div>
          <div className="col-md-4 ">
            <div className=" imgItem">
              <Link to="/products/subCategory6">
                <img
                  id="3"
                  src={image1}
                  alt="image not found"
                  className="packagaya"
                />{" "}
              </Link>{" "}
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>زجاج</p>
          </div>
        </div>
      </div>
      {/* <div className="row"  style={{backgroundColor:'#4e732c', color:'white' , margin:'0', display:'flex', justifyContent:'end'}}>
          <div className="col-md-7 col-sm-8" style={{marginTop:'25px'}}>
            <div className="container">
            <div className="row " >
              <div className="col-md-12" style={{display:'flex', justifyContent:'center'}}>
                <div className="contactContect" >
                <h6 > CONTACT US</h6>
           

           <p >when you have a question we're here to help</p>  
                </div>
          
              </div>
         
</div>
            </div>
           
          </div>
          <div className="col-md-1 col-sm-4" style={{ backgroundColor:'#4e732c'}}></div>
          <div className="col-md-4 col-sm-12 " style={{padding:'0'}}>
<img src={contact} alt="" className='contactMeFooter'/>
          </div>
        </div>  */}
    </>
  );
}

import React from "react";
import image1 from "../Images/Mr.Olive//packages/Glass/Glass Jar 370 Ml (2).jpg";
import image2 from "../Images/Mr.Olive//packages/Glass/Glass Jar 370 Ml.jpg";
import image3 from "../Images/Mr.Olive//packages/Glass/Glass Jar 720 Ml.jpg";
import image4 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (1).jpg";
import image5 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (3).jpg";
import image6 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (4).jpg";
import image7 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (5).jpg";
import image8 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (6).jpg";
import image9 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (7).jpg";
import image10 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (8).jpg";
import image11 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (9).jpg";
import image12 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (10).jpg";
import image13 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (11).jpg";
import image14 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (12).jpg";
import image15 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (13).jpg";
import image16 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (14).jpg";
import image17 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml.jpg";
import image18 from "../Images/Mr.Olive/packages/Glass/فلفل مخلل.jpg";

import contact from "../Images/Group-of-customer-service-representatives.jpg";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  EffectCube,
  EffectFlip,
  Autoplay,
  EffectCoverflow,
} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import "swiper/css/effect-cube";


import "swiper/css/navigation";
import "swiper/css/effect-fade";
export default function SubCategory6Italy() {


  let imgList = Array.from(document.getElementsByClassName("imgItem"));
  let lightContainer = document.querySelector(".lightContainer");
  let lightBox = document.querySelector(".lightBox");
  let currentIndex = 0;
  console.log(lightContainer);

  function showImage(x) {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-none", "d-flex");
    // console.log("ayhagaaasa")
    document.querySelector(".very").style.backgroundImage = `url(${x.src})`;
    document.querySelector(".productName").innerHTML = x.name;

    currentIndex = x.id;
    // console.log(currentIndex)
  }

  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }
  function showForm() {
    document
      .querySelector(".showForm")
      .classList.replace("d-none", "d-inline-block");
  }
  return (
    <>
      <div className="products " onClick={closeSlide}>
  
      </div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-6">
            <table class="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">Nome del pacchetto</th>
                  <th scope="col">Articoli all'interno del pacco</th>
                  <th scope="col">Numero di barattoli in una scatola</th>
                  <th scope="col">Peso sgocciolato per lattina/kg</th>
                  <th scope="col">Peso lordo per cartone/kg</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" rowspan="4">
                    370 ml
                  </th>
                  <td style={{ padding: "0" }}> Olive intere </td>
                  <td style={{ padding: "0" }}> 24 barattoli</td>
                  <td>0.200 kg</td>
                  <td>13.920 kg</td>
                </tr>
                <tr>
                  <td>Olive denocciolate, affettate e ripiene</td>
                  <td> 24 barattoli</td>
                  <td>0.180 kg </td>
                  <td>13.920 kg </td>
                </tr>
                <tr>
                  <td> Carciofo</td>
                  <td>24 barattoli </td>
                  <td> 0.180 kg</td>
                  <td> 13.920 kg</td>
                </tr>
                <tr>
                  <td> Peperoni</td>
                  <td>24 barattoli </td>
                  <td>0.180 kg </td>
                  <td>13.920 kg </td>
                </tr>
                {/**---------------------------- */}
                <tr>
                  <th scope="row" rowspan="4">
                    720 ml
                  </th>
                  <td style={{ padding: "0" }}> Olive intere </td>
                  <td style={{ padding: "0" }}> 12 barattoli</td>
                  <td>0.420 kg</td>
                  <td>12.00 kg</td>
                </tr>
                <tr>
                  <td> Olive denocciolate, affettate e ripiene</td>
                  <td> 12 barattoli</td>
                  <td>0.360 kg </td>
                  <td>12.00 kg </td>
                </tr>
                <tr>
                  <td> Carciofo</td>
                  <td>12 barattoli </td>
                  <td> 0.420 kg</td>
                  <td> 12.00 kg</td>
                </tr>
                <tr>
                  <td> Peperoni</td>
                  <td>12 barattoli </td>
                  <td>0.360 kg </td>
                  <td>12.00 kg </td>
                </tr>
                <tr>
                  <th scope="row" rowspan="4">
                    1000 ml
                  </th>
                  <td style={{ padding: "0" }}> Olive intere </td>
                  <td style={{ padding: "0" }}> 6 barattoli</td>
                  <td>0.650 kg</td>
                  <td>8.880 kg</td>
                </tr>
                <tr>
                  <td> Olive denocciolate, affettate e ripiene</td>
                  <td> 6 barattoli</td>
                  <td>0.550 kg </td>
                  <td>8.880 kg </td>
                </tr>
                <tr>
                  <td> Carciofo</td>
                  <td>6 barattoli </td>
                  <td> 0.650 kg</td>
                  <td> 8.880 kg</td>
                </tr>
                <tr>
                  <td> Peperoni</td>
                  <td>6 barattoli </td>
                  <td>0.550 kg </td>
                  <td>8.880 kg </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="col-md-6"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Swiper
              // install Swiper modules
              modules={[
                Navigation,
                Pagination,
                Autoplay,
                EffectFade,
                EffectFlip,
                EffectCoverflow,
                EffectCube,
              ]}
              spaceBetween={50}
              slidesPerView={2}
              navigation
              autoplay={true}
              loop={true}
            >
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {" "}
                <figure>
                  {" "}
                  <img src={image1} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro 370 Ml (2)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image2} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro 370 Ml</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image3} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro 720 Ml</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {" "}
                <figure>
                  {" "}
                  <img src={image4} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro 1000 Ml (1)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image5} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro 1000 Ml (3)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image6} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro 1000 Ml (4)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image7} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro 1000 Ml (5)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image8} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro 1000 Ml (6)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image9} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro 1000 Ml (7)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image10} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro 1000 Ml (8)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image11} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro 1000 Ml (9)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image12} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro 1000 Ml (10)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image13} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro 1000 Ml (11)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image14} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro 1000 Ml (12)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image15} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro 1000 Ml (13)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image16} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro 1000 Ml (14)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image17} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro 1000 Ml</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image18} alt="" style={{ width: "100%" }} />
                  <figcaption>Barattolo di vetro </figcaption>
                </figure>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image1 from "../Images/Mr.Olive/BlackOlive/زيتون أسود مؤكسد سليم.jpg";
import image2 from "../Images/Mr.Olive/BlackOlive/زيتون بيكوال أسود طبيعي سليم.jpg";
import image3 from "../Images/Mr.Olive/BlackOlive/زيتون دولسي أسود طبيعي سليم.jpg";
import image4 from "../Images/Mr.Olive/BlackOlive/زيتون سيرس كولا أسود سليم.jpg";
import image5 from "../Images/Mr.Olive/BlackOlive/زيتون كالاماتا أسود طبيعي سليم.jpg";
import image6 from "../Images/Mr.Olive/BlackOlive/زيتون منزانلو أسود  طبيعي سليم.jpg";
import contact from "../Images/Group-of-customer-service-representatives.jpg";

export default function SubCategory4Italy() {
  const [currID, setCurrID] = useState("");
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  async function sendQuote(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `https://mrolive-back-end-2c3o.onrender.com/api/v1/quote`,
        {
          name: name,
          email: email,
          productName: productName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.msg);
        setName("");
        setEmail("");
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
      })
      .catch((e) => {
        toast.error(e?.data?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let imgList = Array.from(document.getElementsByClassName("imgItem"));
  let lightContainer = document.querySelector(".lightContainer");
  let lightBox = document.querySelector(".lightBox");
  let currentIndex = 0;
  let tmp = [
    {
      id: "1",
      text: (
        <p>
          L'olivo Picula è il più diffuso nelle spese delle aziende agricole
          della nostra azienda, dopo le olive Ajizi. Le olive Picual nere
          naturali si distinguono per le loro grandi dimensioni e l'attraente
          colore viola. Li produciamo nel nostro stabilimento utilizzando metodi
          sani che preservano il valore nutrizionale dell'oliva e lo
          classifichiamo anche in 3 tipi Essi sono:
          <br />
          1. Olive Picual nere intere naturali <br />
          2. Olive Picual nere denocciolate naturali <br />
          3. Fette di oliva Picual nere naturali
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descrizione dell'articolo</th>
              <th>Grandezza(Standard greci)</th>
              <th>Pezzi di olive/KG(Standard spagnolo)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="13">Oliva Picual Naturale Nera</th>
              <td> Mammut </td>
              <td>101-110</td>
            </tr>
            <tr>
              <td>Super Colossalee</td>
              <td> 111-120</td>
            </tr>
            <tr>
              <td> Colossale</td>
              <td>121-141</td>
            </tr>
            <tr>
              <td> Giganti</td>
              <td>141-160</td>
            </tr>
            <tr>
              <td>Extra Gigantesco</td>
              <td>161-180</td>
            </tr>
            <tr>
              <td> Gigantesco</td>
              <td> 181-200</td>
            </tr>
            <tr>
              <td>Extra Grande</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>Grande</td>
              <td> 231-260</td>
            </tr>
            <tr>
              <td>Superiore</td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>Brilliante </td>
              <td> 291-320</td>
            </tr>
            <tr>
              <td>Fine</td>
              <td> 321-350</td>
            </tr>

            <tr>
              <td>Proiettili</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td></td>
              <td>381-400</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "2",
      text: (
        <p>
          Questa varietà è chiamata Kalamata egiziana, e l'albero dulci è uno
          degli alberi più produttivi, e questo albero è caratterizzato dal suo
          adattamento ai cambiamenti climatici. Questa qualità è molto richiesta
          dal mercato locale, dai mercati arabi e da alcuni mercati europei. Il
          dulse nero naturale si distingue per la sua forma unica, il suo
          attraente colore viola e la sua abbondanza di olio, che conferisce un
          gusto delizioso, e lo produciamo nella nostra fabbrica utilizzando
          metodi sani che preservano il valore nutrizionale dell'oliva.
        </p>
      ),

      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descrizione dell'articolo</th>
              <th>Pezzi di olive/KG(Standard spagnolo)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="6"> Oliva Dolce Naturale Nera</th>
              <td>201-250 </td>
            </tr>
            <tr>
              <td>251-300</td>
            </tr>
            <tr>
              <td> 301-350</td>
            </tr>
            <tr>
              <td> 351-400 </td>
            </tr>
            <tr>
              <td>401-450</td>
            </tr>
            <tr>
              <td> 451-500</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "3",
      text: (
        <p>
          Questo tipo è uno dei tipi più abbondanti di olio d'oliva, e questa
          qualità è stata utilizzata con grande successo nel decapaggio ed è
          diventata uno dei tipi più importanti richiesti dai mercati globali
          negli ultimi anni La nostra azienda ha assegnato molte aree per
          espandere la coltivazione di questa qualità Questa qualità è molto
          richiesta dal mercato locale, Mercati arabi e alcuni dei mercati La
          cola fermentata nera naturale con la sua forma unica, il colore viola
          attraente e la sua abbondanza in olio, che dà un gusto delizioso, e la
          produciamo nella nostra fabbrica utilizzando metodi sani che
          preservano il valore nutrizionale dell'oliva e anche noi la
          classifichiamo in <br />
          1- Olive fermentate a cola nera naturale
          <br />
          2- Olive nere cola cirus fermentate naturali <br />
          3- Fette di oliva cola sarsa nera naturale <br />
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descrizione dell'articolo</th>
              <th>Grandezza(Standard greci)</th>
              <th>Pezzi di olive/KG(Standard spagnolo)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="10"> Oliva nera naturale Serse cola</th>
              <td> Gigantesco </td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Extra Grande</td>
              <td> 201-230</td>
            </tr>
            <tr>
              <td> Grande</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td>Superiore</td>
              <td>261-290</td>
            </tr>
            <tr>
              <td>Brilliante</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td> Fine</td>
              <td>321-350</td>
            </tr>
            <tr>
              <td>Proiettili</td>
              <td>351-380</td>
            </tr>
            <tr>
              <td></td>
              <td>381-400</td>
            </tr>
            <tr>
              <td></td>
              <td>401-450</td>
            </tr>
            <tr>
              <td> </td>
              <td>451-500</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "4",
      text: (
        <p>
          Questa varietà è stata chiamata Kalamata a causa della sua
          coltivazione nella città di Kalamata, in Grecia, l'origine di questa
          qualità originale. Le aziende agricole della nostra azienda hanno
          assegnato aree designate per la coltivazione di questo tipo (Kalamata)
          per l'autosufficienza e per soddisfare le esigenze dei nostri clienti
          a livello locale e globale. Il kalamata nero naturale si distingue per
          la sua forma unica, il suo attraente colore viola e la sua abbondanza
          di olio che dà un gusto delizioso. Lo produciamo nel nostro
          stabilimento utilizzando metodi sani che preservano il valore
          nutrizionale dell'oliva e lo classifichiamo anche in 3 tipi Essi sono:
          <br />
          1. Olive nere naturali kalamta intere
          <br />
          2. Olive nere naturali di Kalamata denocciolate <br />
          3. Olive nere kalamata naturali affettate <br />
          4. Olive nere di Kalamata naturali a metà
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descrizione dell'articolo</th>
              <th>Grandezza(Standard greci)</th>
              <th>Pezzi di olive/KG(Standard spagnolo)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="11">Olive naturali nere Kalamata</th>
              <td> Giganti </td>
              <td>141-160</td>
            </tr>
            <tr>
              <td>Extra Gigantesco</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td> Gigantesco</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Extra Grande</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>Grande</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td> Superiore</td>
              <td>261-290</td>
            </tr>
            <tr>
              <td>Brilliante</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td>Fine</td>
              <td>321-350</td>
            </tr>
            <tr>
              <td>Proiettili</td>
              <td>351-380</td>
            </tr>
            <tr>
              <td> </td>
              <td>381-400</td>
            </tr>

            <tr>
              <td> </td>
              <td>401-450</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "5",
      text: (
        <p>
          Questa varietà è la seconda faccia dell'oliva verde manzanilla, ed è
          una delle varietà più diffuse nelle fattorie e nella fabbrica della
          nostra azienda, a causa della grande richiesta da parte dei nostri
          clienti esterni. La manzanilla nera naturale è caratterizzata dalle
          sue grandi dimensioni e dall'attraente colore viola, e la produciamo
          nella nostra fabbrica utilizzando metodi sani che preservano il valore
          nutrizionale del seme di oliva, e lo classifichiamo anche in 3 tipi
          Essi sono:
          <br />
          1. Olive nere manzanilla naturali intere <br />
          2. Olive manzanilla nere denocciolate naturali
          <br />
          3. Olive manzanilla nere naturali affettate <br />
          4. Olive Manzanilla nere naturali
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descrizione dell'articolo</th>
              <th>Grandezza(Standard greci)</th>
              <th>Pezzi di olive/KG(Standard spagnolo)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="11">Olive Manzanilla Naturali Nere</th>
              <td> Colossale </td>
              <td>121-141</td>
            </tr>
            <tr>
              <td>Giganti</td>
              <td>141-160</td>
            </tr>
            <tr>
              <td> Extra Gigantesco</td>
              <td>161-180</td>
            </tr>
            <tr>
              <td> Gigantesco</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Extra Grande</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td> Grande</td>
              <td> 231-260</td>
            </tr>
            <tr>
              <td>Superiore</td>
              <td>261-290</td>
            </tr>
            <tr>
              <td>Brilliante</td>
              <td> 291-320</td>
            </tr>
            <tr>
              <td>Fine</td>
              <td> 321-350</td>
            </tr>
            <tr>
              <td> Proiettili</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td></td>
              <td> 381-400</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "6",
      text: (
        <p>
          Le olive nere ossidate ottengono il loro colore nero dopo un processo
          di ossidazione industriale, durante il quale le olive verdi conservate
          in acqua e sale subiscono un forte trattamento alcalino. Contenitori I
          contenitori vengono sterilizzati in autoclave per ottenere un prodotto
          delizioso, sano e sicuro. Le olive nere ossidate si dividono in tre:{" "}
          <br />
          1. Olive nere intere ossidate <br />
          2. Olive nere ossidate affettate <br />
          3. Olive nere ossidate all'aceto
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descrizione dell'articolo</th>
              <th>Grandezza(Standard greci)</th>
              <th>Pezzi di olive/KG(Standard spagnolo)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="14">
                Olive nere mature intere Olive nere mature affettate Olive nere
                mature denocciolate
              </th>
              <td> Super Mammut </td>
              <td>91-100</td>
            </tr>
            <tr>
              <td> Mammut</td>
              <td> 101-110</td>
            </tr>
            <tr>
              <td> Super Colossalee</td>
              <td>111-120</td>
            </tr>
            <tr>
              <td>Colossale</td>
              <td>121-141</td>
            </tr>
            <tr>
              <td>Giganti</td>
              <td>141-160</td>
            </tr>
            <tr>
              <td> Extra Gigantesco</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td>Jumbo</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Extra Grande</td>
              <td> 201-230</td>
            </tr>
            <tr>
              <td>Grande</td>
              <td> 231-260</td>
            </tr>
            <tr>
              <td>Superiore </td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>Brilliante</td>
              <td> 291-320</td>
            </tr>

            <tr>
              <td>Fine</td>
              <td> 321-350</td>
            </tr>

            <tr>
              <td>Proiettili</td>
              <td> 351-380</td>
            </tr>

            <tr>
              <td></td>
              <td> 381-400</td>
            </tr>
          </tbody>
        </table>
      ),
    },
  ];
  function showImage(x) {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-none", "d-flex");
    // console.log("ayhagaaasa")
    document.querySelector(".very").style.backgroundImage = `url(${x.src})`;
    document.querySelector(".productName").innerHTML = x.name;

    currentIndex = x.id;
    // console.log(currentIndex)
    setCurrID(currentIndex);
  }

  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }
  function showForm() {
    document
      .querySelector(".showForm")
      .classList.replace("d-none", "d-inline-block");
  }
  return (
    <>
      <ToastContainer />

      <div className="products " onClick={closeSlide}></div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="1"
                src={image2}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Oliva Picual Naturale Nera");
                }}
                alt="image not found"
                className="photo"
                name="Oliva Picual Naturale Nera"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              {" "}
              Oliva Picual Naturale Nera
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="2"
                src={image3}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Oliva Dolce Naturale Nera");
                }}
                alt="image not found"
                className="photo"
                name="Oliva Dolce Naturale Nera"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Oliva Dolce Naturale Nera
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="3"
                src={image4}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Oliva nera naturale Serse cola");
                }}
                alt="image not found"
                className="photo"
                name="Oliva nera naturale Serse cola"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Oliva nera naturale Serse cola
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="4"
                src={image5}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Olive naturali nere Kalamata");
                }}
                alt="image not found"
                className="photo"
                name="Olive naturali nere Kalamata"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Olive naturali nere Kalamata
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="5"
                src={image6}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Olive Manzanilla Naturali Nere");
                }}
                alt="image not found"
                className="photo"
                name="Olive Manzanilla Naturali Nere"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Olive Manzanilla Naturali Nere
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="6"
                src={image1}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("oliva ossidata");
                }}
                alt="image not found"
                className="photo"
                name="oliva ossidata"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              oliva ossidata{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="lightContainer2 d-none  align-items-center">
        <div className="lightBox2  ">
          <div className="container">
            <div className="row d-flex justify-content-center ">
              <div className="ayhaga" style={{ marginTop: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img className=" very" alt="" style={{ padding: "0" }} />
                </div>
                <h4
                  className="productName"
                  style={{
                    color: "#4e732c",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></h4>
                {tmp.map((each) => {
                  if (each.id === currID) {
                    return (
                      <div>
                        <p style={{ color: "white" }}> {each.text} </p>
                        <div>{each.table}</div>
                      </div>
                    );
                  }
                })}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <button
                    className="btn get bgDark"
                    style={{ marginBottom: "5px" }}
                    onClick={showForm}
                  >
                    Ottieni un preventivo
                  </button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <form onSubmit={sendQuote}>
                    <div className="showForm d-none ">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Inserisci il tuo nome"
                        style={{ marginBottom: "5px" }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <input
                        type="email"
                        className="form-control"
                        // id="exampleFormControlInput2"
                        id="email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        placeholder="Inserisci la tua email"
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px",
                        }}
                      >
                        <button
                          className="btn get"
                          style={{ marginTop: "5px" }}
                          type="submit"
                        >
                          {loading ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : (
                            "Invia"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <img src={image1} alt="image not found" className='lightBox'/> */}

          <i
            style={{
              color: "black",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
            id="close"
            className="fa-solid fa-xmark"
            onClick={closeSlide}
          ></i>
        </div>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="contactContect">
                  <h6> contattaci</h6>

                  <p>quando hai una domanda siamo qui per aiutarti</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}

import React from "react";
import "./AboutUs.css";
import contact from "../Images/Group-of-customer-service-representatives.jpg";
import oliveOilAbout from "../Images/Mr.Olive/packages/_KH13115.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Certificate1 from "../Images/Mr.Olive/Certificate/Certificate 1.jpg";
import Certificate2 from "../Images/Mr.Olive/Certificate/Certificate 2.jpg";
import Certificate3 from "../Images/Mr.Olive/Certificate/Certificate 3.jpg";

export default function AboutUsRuss() {
  let Certificates = [
    { img: Certificate1 },
    { img: Certificate2 },
    { img: Certificate3 },
  ];

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  return (
    <>
      <div className="aboutUs ">
        <div className="container" style={{ height: "100%" }}>
          <div className="row" style={{ height: "100%" }}>
            <div
              className="col-md-12 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <h1 className="text-white">о нас</h1>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container p-3">
        <div className="row p-3">
          <div className="col-md-8">
            <h4 style={{ color: "#4e732c" }}>Сельское хозяйство:</h4>
            <p>
              &emsp;Г-н Олив начал заниматься выращиванием оливок в 1986 году на
              дороге Каир-Александрия в пустыне, особенно в районе (Аль-Хататба
              - Абу-Галеб - Международная дорога в Эль-Аламейне). Сегодня
              посевная площадь достигла 1000 гектаров оливок различных видов,
              где используются современные методы орошения, удобрения и
              избегания любых следов пестицидов в урожае.
            </p>
            <h4 style={{ color: "#4e732c" }}>Область производства:</h4>
            <p>
              {" "}
              &emsp; Компания была создана как компания солидарности в 1998 году
              в области производства и переработки столовых оливок. Производство
              и производственный процесс осуществляется с использованием
              новейших производственных и упаковочных линий в соответствии с
              международными стандартами. Компания стала пионером в области
              производства и маркетинга продукции на местном и глобальном
              уровнях с производственной мощностью 10 000 тонн оливок. Компания,
              как правило, добавляла другие продукты, такие как перец, овощи,
              соленые огурцы и маринованные артишоки.
            </p>
            <h4 style={{ color: "#4e732c" }}>Цель компании:</h4>
            <p>
              {" "}
              &emsp; Наша цель - предоставить нашим клиентам во всем мире
              международные стандарты качества и спецификации, расширяясь,
              открывая новые рынки и увеличивая инвестиции.
            </p>
          </div>
          <div className="col-md-4 ">
            <img src={oliveOilAbout} className="oliveOilAbout" alt="" />
          </div>
        </div>
      </div>
      <div className="container mb-3">
        <Slider {...settings}>
          {Certificates.map((certificate, index) => (
            <div key={index}>
              <img
                className="w-75"
                src={certificate.img}
                alt={`Certificate ${index + 1}`}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="contactContect">
                  <h6> свяжитесь с нами</h6>

                  <p>когда у вас есть вопрос, мы здесь, чтобы помочь</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}

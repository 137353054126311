import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import image1 from "../Images/Mr.Olive/BlackOlive/زيتون أسود مؤكسد مخلي.jpg";
import image2 from "../Images/Mr.Olive/BlackOlive/زيتون بيكوال أسود طبيعي مخلي.jpg";
import image3 from "../Images/Mr.Olive/BlackOlive/زيتون سيرس كولا أسود مخلي.jpg";
import image4 from "../Images/Mr.Olive/BlackOlive/زيتون منزانلو أسود طبيعي مخلي.jpg";
import image5 from "../Images/Mr.Olive/BlackOlive/كالاماتا مخلي.jpg";

import contact from "../Images/Group-of-customer-service-representatives.jpg";

export default function SubCategory5Ar() {
  const [currID, setCurrID] = useState("");
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  async function sendQuote(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `https://mrolive-back-end-2c3o.onrender.com/api/v1/quote`,
        {
          name: name,
          email: email,
          productName: productName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.msg);
        setName("");
        setEmail("");
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
      })
      .catch((e) => {
        toast.error(e?.data?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let imgList = Array.from(document.getElementsByClassName("imgItem"));
  let lightContainer = document.querySelector(".lightContainer");
  let lightBox = document.querySelector(".lightBox");
  let currentIndex = 0;
  let tmp = [
    {
      id: "1",
      text: (
        <p dir="rtl" lang="ar">
          يعد الزيتون البيكوال الاسود الطبيعي من النوعيات الاكثر انتشارا في
          الاسواق على المستوى المحلي والعالمي وتعد شجرة الزيتو ن البيكوال الاكثر
          انتشار في مصروفي مزراع شركتنا مستراوليف للصناعات الغذائية بعد الزيتون
          العجيزي يتميز الزيتون البيكوال الاسود الطبيعي باحجامه الكبيرة ولونه
          الجذاب البنفسجي ونقوم في مصنعنا بتصنيعه ااستخدام الطرق الصحية التي
          تحافظة على القيمة الغذائية لحبة الزيتون وايضا نقوم بتصنيفه الى 3
          نوعيات وهما :
          <br />
          1. زيتون بيكوال اسود طبيعي سليم <br />
          2. زيتون بيكوال اسود طبيعي مخلي <br />
          3. زيتون بيكوال اسود طبيعي شرائح
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>وصف السلعة</th>
              <th>الحجم (المعايير اليونانية) </th>
              <th>قطع زيتون / كيلو جرام (معيار اسباني)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="13"> زيتون بيكوال طبيعي اسود</th>
              <td> ماموث </td>
              <td>101-110</td>
            </tr>
            <tr>
              <td>سوبر كلوسال</td>
              <td> 111-120</td>
            </tr>
            <tr>
              <td> كلوسال</td>
              <td>121-141</td>
            </tr>
            <tr>
              <td> جانتس</td>
              <td>141-160</td>
            </tr>
            <tr>
              <td>اكسترا جامبو</td>
              <td>161-180</td>
            </tr>
            <tr>
              <td> جامبو</td>
              <td> 181-200</td>
            </tr>
            <tr>
              <td>اكسترا لارج</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>لارج</td>
              <td> 231-260</td>
            </tr>
            <tr>
              <td>سوبريور</td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>برلينت </td>
              <td> 291-320</td>
            </tr>
            <tr>
              <td>فاين</td>
              <td> 321-350</td>
            </tr>

            <tr>
              <td>بولتس</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td></td>
              <td>381-400</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "2",
      text: (
        <p dir="rtl" lang="ar">
          تعد هذه النوعية من اكثر النوعيات الوفيرة بزيت الزيتون واقد استخدمت هذه
          النوعية نجاحا كبيرا في التخليل واصبحت من اهم نوعيات طلبا من الاسواق
          العالمية خلال السنوات الماضية وقامت شركتنا بتخصيص مساحات كثيرة للتوسع
          في زراعة هذه النوعية وتعتبر هذه النوعية مطلوبة جدا من السوق المحلي
          والاسواق العربية وبعض من الاسواق السرس كولا الاسود الطبيعي بشكله
          الفريد من نوعه ولونه الجذاب البنفسجي وايضاوفرته بالزيت الذي يعطي طعما
          لذيذا ونقوم في مصنعنا بتصنيعه ااستخدام الطرق الصحية التي تحافظة على
          القيمة الغذائية لحبة الزيتون وايضا نقوم بتصنيفه الى
          <br />
          1- زيتون سرس كولا اسود طبيعي سليم <br />
          2- زيتون سرس كولا اسود طبيعي مخلي <br />
          3- زيتون سرس كولا اسود طبيعي شرائح
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>وصف السلعة</th>
              <th>الحجم (المعايير اليونانية) </th>
              <th>قطع زيتون / كيلو جرام (معيار اسباني)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="10"> زيتون سيرس كولا طبيعي أسود</th>
              <td> جامبو </td>
              <td>181-200</td>
            </tr>
            <tr>
              <td> اكسترا لارج</td>
              <td> 201-230</td>
            </tr>
            <tr>
              <td>لارج</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td>سوبريور</td>
              <td>261-290</td>
            </tr>
            <tr>
              <td>برلينت</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td> فاين</td>
              <td>321-350</td>
            </tr>
            <tr>
              <td>بولتس</td>
              <td>351-380</td>
            </tr>
            <tr>
              <td></td>
              <td>381-400</td>
            </tr>
            <tr>
              <td></td>
              <td>401-450</td>
            </tr>
            <tr>
              <td> </td>
              <td>451-500</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "3",
      text: (
        <p dir="rtl" lang="ar">
          يعد هذا الصنف الوجه الثاني للزيتون المنزانيلا الاخضر ويعد من الاصناف
          اكثر انتشارا في مزارع ومصنع شرركتنا لوجود طلب كبير عليه من عملائنا
          الخارجين يتميز المنزانيلا الاسود الطبيعي باحجامه الكبيرة ولونه الجذاب
          البنفسجي ونقوم في مصنعنا بتصنيعه ااستخدام الطرق الصحية التي تحافظة على
          القيمة الغذائية لحبة الزيتون وايضا نقوم بتصنيفه الى 3 نوعيات وهما :
          <br />
          1. زيتون منزانيلا اسود طبيعي سليم <br />
          2. زيتون منزانيلا اسود طبيعي مخلي <br />
          3. زيتون منزانيلا اسود طبيعي شرائح <br />
          4. زيتون منازنيلا اسود طبيعي انصاف
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>وصف السلعة</th>
              <th>الحجم (المعايير اليونانية) </th>
              <th>قطع زيتون / كيلو جرام (معيار اسباني)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="11"> زيتون مانزانيلا طبيعي أسود</th>
              <td> كلوسال </td>
              <td>121-141</td>
            </tr>
            <tr>
              <td>جانتس</td>
              <td>141-160</td>
            </tr>
            <tr>
              <td> اكسترا جامبو</td>
              <td>161-180</td>
            </tr>
            <tr>
              <td> جامبو</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>اكسترا لارج</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td> لارج</td>
              <td> 231-260</td>
            </tr>
            <tr>
              <td>سوبريور</td>
              <td>261-290</td>
            </tr>
            <tr>
              <td>برلينت</td>
              <td> 291-320</td>
            </tr>
            <tr>
              <td>فاين</td>
              <td> 321-350</td>
            </tr>
            <tr>
              <td> بولتس</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td></td>
              <td> 381-400</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "4",
      text: (
        <p dir="rtl" lang="ar">
          سمي هذا الصنف باسم كالاماتا نظره لزراعته في مدينة كالاماتا باليونان
          منشأ هذه النوعية الاصلي, واثبتت هذه النوعية نجاحا كبيرا من حيث الزراعة
          في مصر, واصبحت من اهم الدول المنافسة لليونان في هذه النوعية بالاخص.
          وخصصت مزراع شركتنا مساحات مخصصة لزراعة هذه النوعية (الكالاماتا)
          للاستكفاء الذاتي وتلبية حاجات عملائنا محليا وعالميا يتميز الكالاماتا
          الاسود الطبيعي بشكله الفريد من نوعه ولونه الجذاب البنفسجي وايضاوفرته
          بالزيت الذي يعطي طعما لذيذا ونقوم في مصنعنا بتصنيعه ااستخدام الطرق
          الصحية التي تحافظة على القيمة الغذائية لحبة الزيتون وايضا نقوم بتصنيفه
          الى 3 نوعيات وهما :<br />
          1. زيتون كالاماتا اسود طبيعي سليم <br />
          2. زيتون كالاماتا اسود طبيعي مخلي <br />
          3. زيتون كالاماتا اسود طبيعي شرائح
          <br />
          4. زيتون كالاماتا اسود طبيعي انصاف
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>وصف السلعة</th>
              <th>الحجم (المعايير اليونانية) </th>
              <th>قطع زيتون / كيلو جرام (معيار اسباني)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="11"> زيتون كالاماتا طبيعي أسود</th>
              <td> جانتس </td>
              <td>141-160</td>
            </tr>
            <tr>
              <td>اكسترا جامبو</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td> جامبو</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>اكسترا لارج</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>لارج</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td> سوبريور</td>
              <td>261-290</td>
            </tr>
            <tr>
              <td>برلينت</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td>فاين</td>
              <td>321-350</td>
            </tr>
            <tr>
              <td>بولتس</td>
              <td>351-380</td>
            </tr>
            <tr>
              <td> </td>
              <td>381-400</td>
            </tr>

            <tr>
              <td> </td>
              <td>401-450</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "5",
      text: (
        <p dir="rtl" lang="ar">
          يحصل الزيتون الأسود المؤكسد علي لونه الأسود بعد إجراء عملية أكسدة
          صناعية يخضع خلالها الزيتون الأخضرالمحفوظ في الماء والملح لمعالجة قلوية
          قوية و يتم تثبيت اللون الأسود بإستخدام عنصر الحديد (جليكونات الحديدوز)
          وتسمى هذه الطريقة بطريقة كاليفرونيا ثم يتم التعبئة في صفائح معدنية
          خاصة مثل صفائح ووغيرها من العبوات و يتم تعقيم العبوات في الأوتوكلاف
          للحصول علي منتج شهي وصحي و آمن . ويقسم الزيتون الاسود المؤكسد الى ثلاث
          :<br />
          1. زيتون أسود مؤكسد سليم <br />
          2. زيتون أسود مؤكسد شرائح <br />
          3. زيتون أسود مؤكسد مخلي
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>وصف السلعة</th>
              <th>الحجم (المعايير اليونانية) </th>
              <th>قطع زيتون / كيلو جرام (معيار اسباني)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="14">
                زيتون أسود مؤكسد سليم زيتون أسود مؤكسد شرائح زيتون أسود مؤكسد
                مخلي
              </th>
              <td> سوبر ماموث </td>
              <td>91-100</td>
            </tr>
            <tr>
              <td> ماموث</td>
              <td> 101-110</td>
            </tr>
            <tr>
              <td> سوبر كلوسال</td>
              <td>111-120</td>
            </tr>
            <tr>
              <td>كلوسال</td>
              <td>121-141</td>
            </tr>
            <tr>
              <td>جانتس</td>
              <td>141-160</td>
            </tr>
            <tr>
              <td> اكسترا جامبو</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td>جامبو</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>اكسترا لارج</td>
              <td> 201-230</td>
            </tr>
            <tr>
              <td>لارج</td>
              <td> 231-260</td>
            </tr>
            <tr>
              <td>سوبريور </td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>برلينت</td>
              <td> 291-320</td>
            </tr>

            <tr>
              <td>فاين</td>
              <td> 321-350</td>
            </tr>

            <tr>
              <td>بولتس</td>
              <td> 351-380</td>
            </tr>

            <tr>
              <td></td>
              <td> 381-400</td>
            </tr>
          </tbody>
        </table>
      ),
    },
  ];
  function showImage(x) {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-none", "d-flex");
    document.querySelector(".very").style.backgroundImage = `url(${x.src})`;
    document.querySelector(".productName").innerHTML = x.name;

    currentIndex = x.id;
    setCurrID(currentIndex);
  }

  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }
  function showForm() {
    document
      .querySelector(".showForm")
      .classList.replace("d-none", "d-inline-block");
  }
  return (
    <>
      <ToastContainer />

      <div className="products" onClick={closeSlide}></div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="1"
                src={image2}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("زيتون بيكوال");
                }}
                alt="image not found"
                className="photo"
                name="زيتون بيكوال"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              زيتون بيكوال{" "}
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="2"
                src={image3}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("زيتون سيرس كولا");
                }}
                alt="image not found"
                className="photo"
                name="زيتون سيرس كولا"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              زيتون سيرس كولا{" "}
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="3"
                src={image4}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("زيتون مانزانيلا");
                }}
                alt="image not found"
                className="photo"
                name="زيتون مانزانيلا"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              زيتون مانزانيلا{" "}
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="4"
                src={image5}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("زيتون كالاماتا");
                }}
                alt="image not found"
                className="photo"
                name="زيتون كالاماتا"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              زيتون كالاماتا{" "}
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="5"
                src={image1}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("زيتون أسود مؤكسد");
                }}
                alt="image not found"
                className="photo"
                name="زيتون أسود مؤكسد"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              زيتون أسود مؤكسد{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="lightContainer2 d-none  align-items-center">
        <div className="lightBox2  ">
          <div className="container">
            <div className="row d-flex justify-content-center ">
              <div className="ayhaga" style={{ marginTop: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img className=" very" alt="" style={{ padding: "0" }} />
                </div>
                <h4
                  className="productName"
                  style={{
                    color: "#4e732c",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></h4>
                {tmp.map((each) => {
                  if (each.id === currID) {
                    return (
                      <div>
                        <p style={{ color: "white" }}> {each.text} </p>
                        <div>{each.table}</div>
                      </div>
                    );
                  }
                })}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <button
                    className="btn get bgDark"
                    style={{ marginBottom: "5px" }}
                    onClick={showForm}
                  >
                    احصل علي عرض الاسعار
                  </button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <form onSubmit={sendQuote}>
                    <div className="showForm d-none ">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="ادخل اسمك"
                        style={{ marginBottom: "5px" }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="ادخل البريد الالكتروني"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px",
                        }}
                      >
                        <button
                          className="btn get"
                          style={{ marginTop: "5px" }}
                          type="submit"
                        >
                          {loading ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : (
                            "ارسال"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <i
            style={{
              color: "black",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
            id="close"
            className="fa-solid fa-xmark"
            onClick={closeSlide}
          ></i>
        </div>
      </div>

      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div className="contactContect">
                  <h6 style={{ display: "flex", justifyContent: "end" }}>
                    {" "}
                    اتصل بنا
                  </h6>

                  <p>عندما يكون لديك سؤال فنحن هنا للمساعدة</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}

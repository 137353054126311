import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image1 from "../Images/Mr.Olive/GreenOlive/زيتون أخضر شرائح عجيزي.jpg";
import image2 from "../Images/Mr.Olive/GreenOlive/أنصاف أخضر.jpg";
import image3 from "../Images/Mr.Olive/GreenOlive/زيتون أخضر شرائح منزانلو.jpg";
import image4 from "../Images/Mr.Olive/GreenOlive/زيتون بيكوال أخضر شرائح.jpg";
import contact from "../Images/Group-of-customer-service-representatives.jpg";

export default function SubCategoryRuss() {
  const [currID, setCurrID] = useState("");
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  async function sendQuote(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `https://mrolive-back-end-2c3o.onrender.com/api/v1/quote`,
        {
          name: name,
          email: email,
          productName: productName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.msg);
        setName("");
        setEmail("");
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
      })
      .catch((e) => {
        toast.error(e?.data?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let imgList = Array.from(document.getElementsByClassName("imgItem"));
  let lightContainer = document.querySelector(".lightContainer");
  let lightBox = document.querySelector(".lightBox");
  let currentIndex = 0;
  let tmp = [
    {
      id: "1",
      text: (
        <p>
          Этот сорт имеет египетское происхождение, и его выращивание
          сосредоточено в районе Файюм и на Александрийской пустынной дороге.
          Этот сорт характеризуется большими объемами, которые постоянно
          востребованы на мировых рынках. Этот сорт обрабатывается испанским
          способом и традиционным методом (вода и соль), то есть обработкой
          оливок, подготовленных для окисления. Мы также изготавливаем его и
          делим на несколько видов:
          <br />
          1. Целые зеленые выдержанные оливки
          <br />
          2. Бескостные зеленые выдержанные оливки
          <br />
          3. Нарезанные зеленые выдержанные оливки
          <br />
          4. Полуфабрикаты из зеленых оливок{" "}
        </p>
      ),
    },
    {
      id: "2",
      text: (
        <p>
          Этот сорт французского происхождения, и этот сорт добился больших
          успехов в своем выращивании. Его выращивание сосредоточено в районе
          пустынной дороги Исмаилии и Александрийской пустынной дороги. Он
          характеризуется особым вкусом, интенсивным выращиванием и очень
          обильным производством. Этот сорт обрабатывается испанским способом и
          традиционным методом (вода и соль).
        </p>
      ),
    },
    {
      id: "3",
      text: (
        <p>
          Зеленая оливка Мансанилья Этот сорт имеет испанское происхождение, и
          этот сорт добился больших успехов в выращивании в Египте из-за
          разнообразия его использования, а его выращивание сосредоточено в
          районе пустынной дороги Исмаилия и Александрийской пустынной дороги.
          Этот сорт отличается особым вкусом, так как содержит высокий процент
          масла. Этот сорт обрабатывается испанским способом и традиционным
          методом (вода и соль), то есть обработкой оливок, подготовленных для
          окисления. Мы также изготавливаем его и делим на несколько видов:
          <br />
          1. Целые зеленые оливки Мансанилья <br />
          2. зеленые оливки мансанилья без косточек <br />
          3. Нарезанные зеленые оливки мансанилья{" "}
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>описание товара</th>
              <th>Размер (Греческие стандарты)</th>
              <th>Кусочки оливок/кг (Испанский стандарт)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="12">Зеленые оливки Мансанилья</th>
              <td> Гиганты </td>
              <td>141-160</td>
            </tr>
            <tr>
              <td> Экстра Джамбо</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td> Джамбо</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Очень большой</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>большой</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td> Начальство</td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>Блестящий</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td>Отлично</td>
              <td> 321-350</td>
            </tr>
            <tr>
              <td>Пули</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td> </td>
              <td> 381-420</td>
            </tr>
            <tr>
              <td></td>
              <td> 421-450</td>
            </tr>

            <tr>
              <td></td>
              <td> 451-500</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "4",
      text: (
        <p>
          Этот сорт имеет испанское происхождение, и этот сорт добился больших
          успехов при выращивании в Египте. Этот тип является наиболее
          распространенным в Египте после зеленой оливки Аль-Аджизи из-за
          разнообразия ее использования, а ее выращивание сосредоточено в районе
          пустынной дороги Исмаилия и Александрийской пустынной дороги. Он
          отличается особым вкусом, так как содержит высокий процент масла. Этот
          сорт обрабатывается на нашей фабрике испанским способом и традиционным
          методом (вода и соль), то есть обработкой оливок, подготовленных для
          окисления. Мы также изготавливаем его и делим на несколько видов:
          <br />
          1. Целые зеленые оливки Пикуаль <br />
          2. Маринованные зеленые оливки Пикуаль <br />
          3. Нарезанные зеленые оливки Пикуаль
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>описание товара</th>
              <th>Размер(Греческие стандарты)</th>
              <th>Кусочки оливок/кг (Испанский стандарт)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="12">Зеленые пикуальские оливки</th>
              <td> Гиганты </td>
              <td>141-160</td>
            </tr>
            <tr>
              <td>Экстра Джамбо</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td> Джамбо</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Очень большой</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>большой</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td> Начальство</td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>Блестящий</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td>Отлично</td>
              <td> 321-350</td>
            </tr>
            <tr>
              <td>Пули</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td> </td>
              <td> 381-420</td>
            </tr>
            <tr>
              <td></td>
              <td> 421-450</td>
            </tr>

            <tr>
              <td></td>
              <td> 451-500</td>
            </tr>
          </tbody>
        </table>
      ),
    },
  ];
  function showImage(x) {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-none", "d-flex");
    // console.log("ayhagaaasa")
    document.querySelector(".very").style.backgroundImage = `url(${x.src})`;
    // document.querySelector(".productName").style.color = '#fff';
    document.querySelector(".productName").innerHTML = x.name;

    currentIndex = x.id;
    setCurrID(currentIndex);

    // console.log(currentIndex)
  }

  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }
  function showForm() {
    document
      .querySelector(".showForm")
      .classList.replace("d-none", "d-inline-block");
  }
  return (
    <>
      <ToastContainer />

      <div className="products" onClick={closeSlide}></div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="1"
                src={image1}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Зеленая оливка Азии");
                }}
                alt="image not found"
                className="photo"
                name="Зеленая оливка Азии"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Зеленая оливка Азии{" "}
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="2"
                src={image2}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Половинки зеленой оливки");
                }}
                alt="image not found"
                className="photo"
                name="Половинки зеленой оливки"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Половинки зеленой оливки{" "}
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="3"
                src={image3}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Зеленые оливки Мансанилья");
                }}
                alt="image not found"
                className="photo"
                name="Зеленые оливки Мансанилья"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Зеленые оливки Мансанилья{" "}
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="4"
                src={image4}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Зеленые пикуальские оливки");
                }}
                alt="image not found"
                className="photo"
                name="Зеленые пикуальские оливки"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Зеленые пикуальские оливки{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="lightContainer2 d-none  align-items-center">
        <div className="lightBox2  ">
          <div className="container">
            <div className="row d-flex justify-content-center ">
              <div className="ayhaga" style={{ marginTop: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img className=" very" alt="" style={{ padding: "0" }} />
                </div>
                <h4
                  className="productName"
                  style={{
                    color: "#4e732c",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></h4>
                {tmp.map((each) => {
                  if (each.id === currID) {
                    return (
                      <div>
                        <p style={{ color: "white" }}> {each.text} </p>
                        <div>{each.table}</div>
                      </div>
                    );
                  }
                })}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <button
                    className="btn get bgDark"
                    style={{ marginBottom: "5px" }}
                    onClick={showForm}
                  >
                    Получить предложение
                  </button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <form onSubmit={sendQuote}>
                    <div className="showForm d-none ">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Введите ваше имя"
                        style={{ marginBottom: "5px" }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <input
                        type="email"
                        className="form-control"
                        // id="exampleFormControlInput2"
                        id="email"
                        placeholder="Введите адрес электронной почты"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px",
                        }}
                      >
                        <button
                          className="btn get"
                          style={{ marginTop: "5px" }}
                          type="submit"
                        >
                          {loading ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : (
                            "Отправить"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <img src={image1} alt="image not found" className='lightBox'/> */}

          <i
            style={{
              color: "black",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
            id="close"
            className="fa-solid fa-xmark"
            onClick={closeSlide}
          ></i>
        </div>
      </div>

      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="contactContect">
                  <h6> свяжитесь с нами</h6>

                  <p>когда у вас есть вопрос, мы здесь, чтобы помочь</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}

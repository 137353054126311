import React, { useState } from "react";
import "./ContactUs.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required("El nombre es obligatorio"),
  email: Yup.string()
    .email("Dirección de correo electrónico no válida")
    .required("Se requiere correo electrónico"),
  subject: Yup.string().required("El asunto es obligatorio"),
  message: Yup.string().required("El mensaje es obligatorio"),
});

export default function ContactUsSpain() {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const response = await axios({
          method: "post",
          url: `https://mrolive-back-end-2c3o.onrender.com/api/v1/contact-us`,
          data: values,
          headers: { "Content-Type": "application/json" },
        });

        toast.success(response?.data?.msg);
        formik.resetForm();
      } catch (e) {
        toast.error(e.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <ToastContainer />
      <div className="contact ">\</div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 ">
            <p className="space ">
              Nuestro objetivo es proporcionar estándares y especificaciones
              internacionales de calidad a nuestros clientes en todo el mundo
              mediante la expansión en la apertura de nuevos mercados y el
              aumento de la inversión.{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 p-3">
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label for="name" className="form-label">
                  Te llamas
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Ingresa tu nombre"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="text-danger">{formik.errors.name}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label for="email" className="form-label">
                  Su correo electrónico
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Ingresa tu correo electrónico"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-danger">{formik.errors.email}</div>
                ) : null}
              </div>{" "}
              <div className="mb-3">
                <label for="subject" className="form-label">
                  Asunto
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="subject"
                  name="subject"
                  placeholder="Introduzca el asunto"
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.subject && formik.errors.subject ? (
                  <div className="text-danger">{formik.errors.subject}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label for="message" className="form-label">
                  Su mensaje
                </label>
                <textarea
                  className="form-control"
                  rows="10"
                  id="message"
                  name="message"
                  placeholder="Ingresa tu mensaje"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                ></textarea>
                {formik.touched.message && formik.errors.message ? (
                  <div className="text-danger">{formik.errors.message}</div>
                ) : null}
              </div>
              <div className="buttonContainer1">
                <button type="submit" className="btn send">
                  {loading ? (
                    <i className="fa-solid fa-spinner fa-spin"></i>
                  ) : (
                    "ENVIAR AHORA"
                  )}
                </button>
              </div>
            </form>
          </div>
          <div className="col-md-6  p-3">
            <div className="row">
              <div className="contactUs "></div>
            </div>
            <div className="row">
              <div>
                <p className="text-muted fw-bold fs-4">
                  <i
                    className="fa-regular fa-building fs-4 p-1"
                    style={{ color: "#4e732c" }}
                  ></i>
                  Oficina:
                </p>
                <p className="text-muted fs-6">
                  <i
                    className="fa-solid fa-location-dot p-1 fs-6"
                    style={{ color: "#4e732c" }}
                  ></i>
                  Apartamento n.º 7,8 - Edificio n.º 8 - Distrito de 7 edificios
                  - zona 11 - Ciudad de Sadat - Menoufeia - Egipto
                </p>
                <p className="text-muted">
                  <i
                    class="fa-solid fa-phone fs-6 p-1"
                    style={{ color: "#4e732c" }}
                  ></i>{" "}
                  <a
                    className="text-decoration-none text-muted"
                    href="tel:0020482653951"
                  >
                    +20482653951{" "}
                  </a>
                </p>
                <p className="text-muted fw-bold fs-4">
                  <i
                    className="fa-solid fa-industry fs-4 p-1"
                    style={{ color: "#4e732c" }}
                  ></i>
                  Fábrica:
                </p>

                <p className="text-muted fs-6">
                  <i
                    className="fa-solid fa-location-dot p-1 fs-6"
                    style={{ color: "#4e732c" }}
                  ></i>
                  Alex Desert - Road kilo 84- El Khatatba Entrance - Menoufeia -
                  Egypt
                </p>

                <p className="text-muted">
                  <i
                    class="fa-solid fa-phone fs-6 p-1"
                    style={{ color: "#4e732c" }}
                  ></i>{" "}
                  <a
                    className="text-decoration-none text-muted"
                    href="tel:00201014583439"
                  >
                    +201014583439{" "}
                  </a>
                  -{" "}
                  <a
                    className="text-decoration-none text-muted"
                    href="tel:00201062624946"
                  >
                    +201062624946{" "}
                  </a>
                  -{" "}
                  <a
                    className="text-decoration-none text-muted"
                    href="tel:00201007546999"
                  >
                    +201007546999{" "}
                  </a>
                  -{" "}
                  <a
                    className="text-decoration-none text-muted"
                    href="tel:02201229940045"
                  >
                    +2201229940045
                  </a>
                </p>
                <p className="text-muted">
                  <i
                    className="p-1 fa-regular fa-envelope fs-6 p-1"
                    style={{ color: "#4e732c" }}
                  ></i>{" "}
                  <a
                    className="text-decoration-none text-muted"
                    href="mailto:info@mr-olive.com"
                  >
                    info@mr-olive.com
                  </a>{" "}
                  -{" "}
                  <a
                    className="text-decoration-none text-muted"
                    href="mailto:Mohamedgamal@mr-olive.com"
                  >
                    Mohamedgamal@mr-olive.com
                  </a>{" "}
                  - <br />
                  <a
                    className="text-decoration-none text-muted"
                    href="mailto:HussamAhmed@mr-olive.com"
                  >
                    HussamAhmed@mr-olive.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container p-3">
        <div className="row">
          <iframe
            className="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d860.5940983750168!2d30.682743164897023!3d30.368668131949246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8ecb975783e3e3c0!2zMzDCsDIyJzA2LjkiTiAzMMKwNDAnNTYuOSJF!5e0!3m2!1sen!2seg!4v1665414277140!5m2!1sen!2seg"
          ></iframe>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image1 from "../Images/Mr.Olive/GreenOlive/زيتون أخضر بيكوال مخلي.jpg";
import image2 from "../Images/Mr.Olive/GreenOlive/زيتون منزانلو أخضر مخلي.jpg";
import image3 from "../Images/Mr.Olive/GreenOlive/عجيزي مخلي 4.jpg";

import image4 from "../Images/Mr.Olive/mix/زيتون مشوي.jpg";
import contact from "../Images/Group-of-customer-service-representatives.jpg";

export default function SubCategory2() {
  const [currID, setCurrID] = useState("");
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  async function sendQuote(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `https://mrolive-back-end-2c3o.onrender.com/api/v1/quote`,
        {
          name: name,
          email: email,
          productName: productName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.msg);
        setName("");
        setEmail("");
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
      })
      .catch((e) => {
        toast.error(e?.data?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let imgList = Array.from(document.getElementsByClassName("imgItem"));
  let lightContainer = document.querySelector(".lightContainer");
  let lightBox = document.querySelector(".lightBox");
  let currentIndex = 0;
  // console.log(lightContainer);
  let tmp = [
    {
      id: "1",
      text: (
        <p>
          This variety is of Spanish origin, and this variety has achieved great
          success in its cultivation in Egypt. This type is the most widespread
          in Egypt after the Al-Ajizi green olive because of the diversity of
          its use and its cultivation is concentrated in the area of the
          Ismailia Desert Road and the Alexandria Desert Road. This is
          distinguished by its special taste because it contains a high
          percentage of oil. This variety is treated in our factory in the
          Spanish way and the traditional method (water and salt), which is the
          treatment of olives prepared for oxidation. We also manufacture it and
          divide it into several types: <br />
          1. Healthy green Picual olives <br />
          2. Pickled green Picual olives <br />
          3. Sliced green Picual olives
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th>Size(Greek Standards)</th>
              <th>Pieces of olives/KG(Spanish Standard)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="12"> Green Picual Olives</th>
              <td> Giants </td>
              <td>141-160</td>
            </tr>
            <tr>
              <td> Extra Jumbo</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td> Jumbo</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td> Extra Large</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>Large</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td> Superior</td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>Brilliant</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td>Fine</td>
              <td> 321-350</td>
            </tr>
            <tr>
              <td>Bullets</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td> </td>
              <td> 381-420</td>
            </tr>
            <tr>
              <td></td>
              <td> 421-450</td>
            </tr>

            <tr>
              <td></td>
              <td> 451-500</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "2",
      text: (
        <p>
          Green Manzanilla Olive This variety is of Spanish origin, and this
          variety has achieved great success in its cultivation in Egypt due to
          the diversity of its use and its cultivation is concentrated in the
          area of the Ismailia Desert Road and the Alexandria Desert Road. This
          variety is distinguished by its special taste because it contains a
          high percentage of oil. This variety is treated in the Spanish way and
          the traditional method (water and salt), which is the treatment of
          olives prepared for oxidation. We also manufacture it and divide it
          into several types: <br />
          1. Healthy Green Manzanilla Olives <br />
          2. pitted green manzanilla olives <br />
          3. Sliced green manzanilla olives
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th>Size(Greek Standards)</th>
              <th>Pieces of olives/KG(Spanish Standard)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="12"> Green Manzanilla Olives</th>
              <td> Giants </td>
              <td>141-160</td>
            </tr>
            <tr>
              <td> Extra Jumbo</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td> Jumbo</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td> Extra Large</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>Large</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td> Superior</td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>Brilliant</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td>Fine</td>
              <td> 321-350</td>
            </tr>
            <tr>
              <td>Bullets</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td> </td>
              <td> 381-420</td>
            </tr>
            <tr>
              <td></td>
              <td> 421-450</td>
            </tr>

            <tr>
              <td></td>
              <td> 451-500</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "3",
      text: (
        <p>
          This variety is of French origin, and this variety has achieved great
          success in its cultivation. Its cultivation is concentrated in the
          area of the Ismailia Desert Road and the Alexandria Desert Road. This
          is characterized by its special taste, intensive cultivation and very
          abundant production This variety is treated in the Spanish way and the
          traditional method (water and salt). We also manufacture it and divide
          it into several types: <br />
          1. Healthy green aged olives <br />
          2. Boneless green aged olives <br />
          3. Sliced green aged olives <br />
          4. Semi-finished green olives
        </p>
      ),
    },
    {
      id: "4",
      text: (
        <p>
          This variety is of Egyptian origin and its cultivation is concentrated
          in the Fayoum area and the Alexandria Desert Road. This variety is
          characterized by its high volumes that are constantly required in the
          global markets. This variety is treated in the Spanish way and the
          traditional method (water and salt), which is the treatment of olives
          prepared for oxidation. We also manufacture it and divide it into
          several types: <br />
          1. Healthy green aged olives <br />
          2. Boneless green aged olives <br />
          3. Sliced green aged olives <br />
          4. Semi-finished green olives
        </p>
      ),
    },
  ];

  function showImage(x) {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-none", "d-flex");
    document.querySelector(".very").style.backgroundImage = `url(${x.src})`;
    document.querySelector(".productName").innerHTML = x.name;
    currentIndex = x.id;
    setCurrID(currentIndex);
  }

  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }
  function showForm() {
    document
      .querySelector(".showForm")
      .classList.replace("d-none", "d-inline-block");
  }

  return (
    <>
      <ToastContainer />

      <div className="products " onClick={closeSlide}></div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="1"
                src={image1}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Picual olive");
                }}
                alt="image not found"
                className="photo"
                name="Picual olive"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Picual olive
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="2"
                src={image2}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Manzanilla olive");
                }}
                alt="image not found"
                className="photo"
                name="Manzanilla olive"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Manzanilla olive
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="3"
                src={image3}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Azizi olive");
                }}
                alt="image not found"
                className="photo"
                name="Azizi olive"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>Azizi olive</p>
          </div>

          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="4"
                src={image4}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("grilled olives");
                }}
                alt="image not found"
                className="photo"
                name="grilled olives"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              grilled olives
            </p>
          </div>
        </div>
      </div>
      <div className="lightContainer2 d-none  align-items-center">
        <div className="lightBox2  ">
          <div className="container">
            <div className="row d-flex justify-content-center ">
              <div className="ayhaga" style={{ marginTop: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img className=" very" alt="" style={{ padding: "0" }} />
                </div>
                <h4
                  className="productName"
                  style={{
                    color: "#4e732c",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></h4>
                {tmp.map((each) => {
                  if (each.id === currID) {
                    return (
                      <div>
                        <p style={{ color: "white" }}> {each.text} </p>
                        <div>{each.table}</div>
                      </div>
                    );
                  }
                })}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <button
                    className="btn get bgDark"
                    style={{ marginBottom: "5px" }}
                    onClick={showForm}
                  >
                    Get Quote
                  </button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <form onSubmit={sendQuote}>
                    <div className="showForm d-none ">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter Your Name"
                        style={{ marginBottom: "5px" }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter Your Email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px",
                        }}
                      >
                        <button
                          className="btn get"
                          style={{ marginTop: "5px" }}
                          type="submit"
                        >
                          {loading ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <img src={image1} alt="image not found" className='lightBox'/> */}

          <i
            style={{
              color: "black",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
            id="close"
            className="fa-solid fa-xmark"
            onClick={closeSlide}
          ></i>
        </div>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="contactContect">
                  <h6> CONTACT US</h6>

                  <p>when you have a question we're here to help</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image1 from "../Images/Mr.Olive/BlackOlive/أنصاف كالاماتا.jpg";
import image2 from "../Images/Mr.Olive/BlackOlive/زيتون أسود مؤكسد شرائح.jpg";
import image3 from "../Images/Mr.Olive/BlackOlive/زيتون بيكوال أسود طبيعي شرائح.jpg";
import image4 from "../Images/Mr.Olive/BlackOlive/زيتون سيرس كولا أسود طبيعي.jpg";

import image5 from "../Images/Mr.Olive/BlackOlive/زيتون منزانلو أسود طبيعي شرائح.jpg";
import image6 from "../Images/Mr.Olive/BlackOlive/مانزانيلا.jpg";

import contact from "../Images/Group-of-customer-service-representatives.jpg";

export default function SubCategory3Russ() {
  const [currID, setCurrID] = useState("");
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  async function sendQuote(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `https://mrolive-back-end-2c3o.onrender.com/api/v1/quote`,
        {
          name: name,
          email: email,
          productName: productName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.msg);
        setName("");
        setEmail("");
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
      })
      .catch((e) => {
        toast.error(e?.data?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let imgList = Array.from(document.getElementsByClassName("imgItem"));
  let lightContainer = document.querySelector(".lightContainer");
  let lightBox = document.querySelector(".lightBox");
  let currentIndex = 0;

  let tmp = [
    {
      id: "1",
      text: (
        <p>
          Этот сорт был назван Каламата из-за его выращивания в городе Каламата,
          Греция, откуда исходит это оригинальное качество. Фермы нашей компании
          выделили площади, предназначенные для выращивания этого типа
          (Каламата) для самообеспечения и удовлетворения потребностей наших
          клиентов на местном и глобальном уровнях. Натуральная черная каламата
          отличается уникальной формой, привлекательным пурпурным цветом и
          обилием масла, придающего восхитительный вкус. Мы производим его на
          нашем заводе, используя здоровые методы, которые сохраняют питательную
          ценность оливок, и мы также классифицируем их на 3 типа. Они: <br />
          1. Натуральные цельные черные оливки каламата.
          <br />
          2. Натуральные черные оливки Каламата без косточек
          <br />
          3. Натуральные черные оливки каламата, нарезанные ломтиками.
          <br />
          4. Половина натуральных черных оливок Каламата
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>описание товара</th>
              <th>Размер(Греческие стандарты)</th>
              <th>Кусочки оливок/кг(Испанский стандарт)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="13"> Черные натуральные оливки Каламата</th>
              <td> Гиганты </td>
              <td>141-160</td>
            </tr>
            <tr>
              <td>Экстра Джамбо</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td> Джамбо</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Очень большой</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>большой</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td> Начальство</td>
              <td>261-290</td>
            </tr>
            <tr>
              <td>Блестящий</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td>Отлично</td>
              <td>321-350</td>
            </tr>
            <tr>
              <td>Пули</td>
              <td>351-380</td>
            </tr>
            <tr>
              <td> </td>
              <td>381-400</td>
            </tr>

            <tr>
              <td> </td>
              <td>401-450</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "2",
      text: (
        <p>
          Оливковое дерево Пикула является наиболее распространенным в расходах
          хозяйств нашей компании после оливок Аджизи. Натуральные черные оливки
          Пикуаль отличаются большими размерами и привлекательным фиолетовым
          цветом. Мы производим их на нашем заводе, используя здоровые методы,
          которые сохраняют питательную ценность оливок, и мы также
          классифицируем их на 3 типа. Это: <br />
          1. Натуральные полезные черные оливки Пикуаль <br />
          2. Натуральные черные оливки Пикуаль без косточек
          <br />
          3. Ломтики натуральных черных оливок Пикуаль
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>описание товара</th>
              <th>Размер(Греческие стандарты)</th>
              <th>Кусочки оливок/кг(Испанский стандарт)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="13">Черная натуральная олива Пикуаль</th>
              <td> мамонт </td>
              <td>101-110</td>
            </tr>
            <tr>
              <td>Супер Колоссальный</td>
              <td> 111-120</td>
            </tr>
            <tr>
              <td> Колоссальный</td>
              <td>121-141</td>
            </tr>
            <tr>
              <td> Гиганты</td>
              <td>141-160</td>
            </tr>
            <tr>
              <td>Экстра Джамбо</td>
              <td>161-180</td>
            </tr>
            <tr>
              <td> Джамбо</td>
              <td> 181-200</td>
            </tr>
            <tr>
              <td>Очень большой</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>большой</td>
              <td> 231-260</td>
            </tr>
            <tr>
              <td>Начальство</td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>Блестящий </td>
              <td> 291-320</td>
            </tr>
            <tr>
              <td>Отлично</td>
              <td> 321-350</td>
            </tr>

            <tr>
              <td>Пули</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td></td>
              <td>381-400</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "3",
      text: (
        <p>
          Этот тип является одним из самых распространенных видов оливкового
          масла, и это качество с большим успехом используется в мариновании и
          стало одним из самых важных типов, пользующихся спросом на мировых
          рынках в последние годы. Наша компания выделила много областей для
          расширения. выращивание этого качества. Это качество очень
          востребовано на местном рынке, арабских рынках и некоторых рынках.
          Натуральная черная ферментированная кола с ее уникальной формой,
          привлекательным фиолетовым цветом и обилием масла, которое придает
          восхитительный вкус, и мы производим его на нашем заводе, используя
          здоровые методы, которые сохраняют питательную ценность оливок, а
          также мы классифицируем их на
          <br />
          1- Натуральные оливки, ферментированные черной колой
          <br />
          2- Натуральные ферментированные черные маслины колы <br />
          3- Ломтики оливок сарса из натуральной черной колы
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>описание товара</th>
              <th>Размер(Греческие стандарты)</th>
              <th>Кусочки оливок/кг(Испанский стандарт)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="10"> Черные натуральные оливки Sersecola</th>
              <td> Джамбо </td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Очень большой</td>
              <td> 201-230</td>
            </tr>
            <tr>
              <td> большой</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td>Начальство</td>
              <td>261-290</td>
            </tr>
            <tr>
              <td>Блестящий</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td> Отлично</td>
              <td>321-350</td>
            </tr>
            <tr>
              <td>Пули</td>
              <td>351-380</td>
            </tr>
            <tr>
              <td></td>
              <td>381-400</td>
            </tr>
            <tr>
              <td></td>
              <td>401-450</td>
            </tr>
            <tr>
              <td> </td>
              <td>451-500</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "5",
      text: (
        <p>
          Этот сорт был назван Каламата из-за его выращивания в городе Каламата,
          Греция, откуда исходит это оригинальное качество. Фермы нашей компании
          выделили площади, предназначенные для выращивания этого типа
          (Каламата) для самообеспечения и удовлетворения потребностей наших
          клиентов на местном и глобальном уровнях. Натуральная черная каламата
          отличается уникальной формой, привлекательным пурпурным цветом и
          обилием масла, придающего восхитительный вкус. Мы производим его на
          нашем заводе, используя здоровые методы, которые сохраняют питательную
          ценность оливок, и мы также классифицируем их на 3 типа. Они: <br />
          1. Натуральные цельные черные оливки каламата. <br />
          2. Натуральные черные оливки Каламата без косточек <br />
          3. Натуральные черные оливки каламата, нарезанные ломтиками.
          <br />
          4. Половина натуральных черных оливок Каламата
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>описание товара</th>
              <th>Размер(Греческие стандарты)</th>
              <th>Кусочки оливок/кг(Испанский стандарт)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="11">Черные натуральные оливки Каламата</th>
              <td> Гиганты </td>
              <td>141-160</td>
            </tr>
            <tr>
              <td>Экстра Джамбо</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td> Джамбо</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Очень большой</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>большой</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td> Начальство</td>
              <td>261-290</td>
            </tr>
            <tr>
              <td>Блестящий</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td>Отлично</td>
              <td>321-350</td>
            </tr>
            <tr>
              <td>Пули</td>
              <td>351-380</td>
            </tr>
            <tr>
              <td> </td>
              <td>381-400</td>
            </tr>

            <tr>
              <td> </td>
              <td>401-450</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "4",
      text: (
        <p>
          Этот сорт является вторым лицом зеленых оливок мансанилья, и это один
          из наиболее распространенных сортов на фермах и фабриках нашей
          компании из-за большого спроса на него со стороны наших внешних
          клиентов. Натуральная черная мансанилья характеризуется большими
          размеры и привлекательный фиолетовый цвет, и мы производим его на
          нашем заводе, используя здоровые методы, которые сохраняют питательную
          ценность семян оливы, и мы также классифицируем их на 3 типа. Они:{" "}
          <br />
          1. Цельные натуральные черные оливки мансанилья
          <br />
          2. Натуральные черные оливки мансанилья без косточек <br />
          3. Нарезанные натуральные черные оливки мансанилья. <br />
          4. Натуральные черные оливки Мансанилья{" "}
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>описание товара</th>
              <th>Размер(Греческие стандарты)</th>
              <th>Кусочки оливок/кг(Испанский стандарт)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="11"> Черные натуральные оливки Мансанилья</th>
              <td> Колоссальный </td>
              <td>121-141</td>
            </tr>
            <tr>
              <td>Гиганты</td>
              <td>141-160</td>
            </tr>
            <tr>
              <td> Экстра Джамбо</td>
              <td>161-180</td>
            </tr>
            <tr>
              <td> Джамбо</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Очень большой</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td> большой</td>
              <td> 231-260</td>
            </tr>
            <tr>
              <td>Начальство</td>
              <td>261-290</td>
            </tr>
            <tr>
              <td>Блестящий</td>
              <td> 291-320</td>
            </tr>
            <tr>
              <td>Отлично</td>
              <td> 321-350</td>
            </tr>
            <tr>
              <td> Пули</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td></td>
              <td> 381-400</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "6",
      text: (
        <p>
          Окисленные черные оливки приобретают свой черный цвет после
          промышленного процесса окисления, во время которого зеленые оливки,
          консервированные в воде и соли, подвергаются сильной щелочной
          обработке. Тара Тара стерилизуется в автоклаве для получения вкусного,
          полезного и безопасного продукта. Окисленные черные оливки делятся на
          три:
          <br />
          1. Цельные окисленные черные оливки
          <br />
          2. Нарезанные окисленные черные оливки
          <br />
          3. Черные оливки, окисленные уксусом
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>описание товара</th>
              <th>Размер(Греческие стандарты)</th>
              <th>Кусочки оливок/кг(Испанский стандарт)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="14">
                {" "}
                Целые черные спелые оливки Нарезанные черные спелые оливки
                Черные спелые оливки без косточек
              </th>
              <td> Супер Маммут </td>
              <td>91-100</td>
            </tr>
            <tr>
              <td> Маммут</td>
              <td> 101-110</td>
            </tr>
            <tr>
              <td>Супер Колоссальный</td>
              <td>111-120</td>
            </tr>
            <tr>
              <td>Колоссальный</td>
              <td>121-141</td>
            </tr>
            <tr>
              <td>Гиганты</td>
              <td>141-160</td>
            </tr>
            <tr>
              <td> Экстра Джамбо</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td>Джамбо</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Очень большой</td>
              <td> 201-230</td>
            </tr>
            <tr>
              <td>большой</td>
              <td> 231-260</td>
            </tr>
            <tr>
              <td>Начальство </td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>Блестящий</td>
              <td> 291-320</td>
            </tr>

            <tr>
              <td>Отлично</td>
              <td> 321-350</td>
            </tr>

            <tr>
              <td>Пули</td>
              <td> 351-380</td>
            </tr>

            <tr>
              <td></td>
              <td> 381-400</td>
            </tr>
          </tbody>
        </table>
      ),
    },
  ];
  function showImage(x) {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-none", "d-flex");
    // console.log("ayhagaaasa")
    document.querySelector(".very").style.backgroundImage = `url(${x.src})`;
    document.querySelector(".productName").innerHTML = x.name;

    currentIndex = x.id;
    // console.log(currentIndex)
    setCurrID(currentIndex);
  }

  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }
  function showForm() {
    document
      .querySelector(".showForm")
      .classList.replace("d-none", "d-inline-block");
  }
  return (
    <>
      <ToastContainer />

      <div className="products " onClick={closeSlide}></div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="1"
                src={image1}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Половинки Каламата");
                }}
                alt="image not found"
                className="photo"
                name="Половинки Каламата"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Половинки Каламата
            </p>
          </div>

          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="2"
                src={image3}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Пикуаль олива");
                }}
                alt="image not found"
                className="photo"
                name="Пикуаль олива"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Пикуаль олива{" "}
            </p>
          </div>

          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="3"
                src={image4}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Черная оливка колы Natural Serse");
                }}
                alt="image not found"
                className="photo"
                name="Черная оливка колы Natural Serse"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Черная оливка колы Natural Serse
            </p>
          </div>

          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="4"
                src={image6}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Черная натуральная олива Мансанилья");
                }}
                alt="image not found"
                className="photo"
                name="Черная натуральная олива Мансанилья"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Черная натуральная олива Мансанилья
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="5"
                src={image5}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Черные натуральные оливки Каламата");
                }}
                alt="image not found"
                className="photo"
                name="Черные натуральные оливки Каламата"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Черные натуральные оливки Каламата
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="6"
                src={image2}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("окисленная олива");
                }}
                alt="image not found"
                className="photo"
                name="окисленная олива"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              окисленная олива
            </p>
          </div>
        </div>
      </div>
      <div className="lightContainer2 d-none  align-items-center">
        <div className="lightBox2  ">
          <div className="container">
            <div className="row d-flex justify-content-center ">
              <div className="ayhaga" style={{ marginTop: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img className=" very" alt="" style={{ padding: "0" }} />
                </div>
                <h4
                  className="productName"
                  style={{
                    color: "#4e732c",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></h4>
                {tmp.map((each) => {
                  if (each.id === currID) {
                    return (
                      <div>
                        <p style={{ color: "white" }}> {each.text} </p>
                        <div>{each.table}</div>
                      </div>
                    );
                  }
                })}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <button
                    className="btn get bgDark"
                    style={{ marginBottom: "5px" }}
                    onClick={showForm}
                  >
                    Получить предложение
                  </button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <form onSubmit={sendQuote}>
                    <div className="showForm d-none ">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Введите ваше имя"
                        style={{ marginBottom: "5px" }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <input
                        type="email"
                        className="form-control"
                        // id="exampleFormControlInput2"
                        id="email"
                        placeholder="Введите адрес электронной почты"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px",
                        }}
                      >
                        <button
                          className="btn get"
                          style={{ marginTop: "5px" }}
                          type="submit"
                        >
                          {loading ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : (
                            "Отправить"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <img src={image1} alt="image not found" className='lightBox'/> */}

          <i
            style={{
              color: "black",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
            id="close"
            className="fa-solid fa-xmark"
            onClick={closeSlide}
          ></i>
        </div>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8">
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="contactContect">
                  <h6> свяжитесь с нами</h6>

                  <p>когда у вас есть вопрос, мы здесь, чтобы помочь</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}

import React from "react";
import logo from "../Images/Logo1.png";
import "./Footer.css";

export default function Footer() {
  return (
    <>
      <footer
        className="bg-dark text-white text-center  bottom-0 start-0 end-0"
        dir="rtl"
      >
        <div className="container-fluid" style={{ marginTop: "25px" }}>
          <div className="row">
            <div className="col-md-4">
              <a href="/">
                <img src={logo} alt="Logo" style={{ width: "200px" , paddingTop:"1rem" }} />
              </a>
            </div>
            <div className="col-md-4 m-auto ">
              <h4 className="mb-3">الوصول السريع</h4>
              <ul className="list-unstyled footer-links">
                <li>
                  <a
                    href="/"
                    className="footer-link text-decoration-none fs-6 text-white"
                  >
                    الرئسية
                  </a>
                </li>
                <li>
                  <a
                    href="/about"
                    className="footer-link text-decoration-none fs-6 text-white"
                  >
                    معلومات عنا
                  </a>
                </li>
                <li>
                  <a
                    href="/products"
                    className="footer-link text-decoration-none fs-6 text-white"
                  >
                    المنتجات
                  </a>
                </li>
                <li>
                  <a
                    href="/packages"
                    className="footer-link text-decoration-none fs-6 text-white"
                  >
                    العبوات
                  </a>
                </li>
                <li>
                  <a
                    href="/photoGallery"
                    className="footer-link text-decoration-none fs-6 text-white"
                  >
                    معرض الصور
                  </a>
                </li>
                <li>
                  <a
                    href="/contact"
                    className="footer-link text-decoration-none fs-6 text-white"
                  >
                    اتصل بنا
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 m-auto">
              <div>
                <p className="text-muted">
                  <i
                    class="fa-solid fa-phone fs-6"
                    style={{ color: "white" }}
                  ></i>{" "}
                  <a
                    className="text-decoration-none text-white"
                    href="tel:0020482653951"
                  >
                    20482653951+{" "}
                  </a>
                </p>{" "}
              </div>
              <p className="text-muted">
                <i
                  className="p-1 fa-regular fa-envelope fs-6"
                  style={{ color: "white" }}
                ></i>{" "}
                <a
                  className="text-decoration-none text-white"
                  href="mailto:info@mr-olive.com"
                >
                  info@mr-olive.com
                </a>{" "}
              </p>
              <div className="social-links">
                <a
                  target="_blank"
                  href="https://www.facebook.com/MasteroliveEgy"
                >
                  <i
                    className="fa-brands fa-facebook fs-5 p-1"
                    style={{ color: "white" }}
                  ></i>
                </a>
                <a
                  target="_blank"
                  href="https://instagram.com/mr_olive_1?igshid=YmMyMTA2M2Y="
                >
                  <i
                    className="fa-brands fa-instagram fs-5 p-1"
                    style={{ color: "white" }}
                  ></i>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/mr-olive-company/"
                >
                  <i
                    className="fa-brands fa-linkedin fs-5 p-1"
                    style={{ color: "white" }}
                  ></i>
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UCWlZLZK8a8X_6nGoM_i3HZg"
                >
                  <i
                    className="fa-brands fa-youtube fs-5 p-1"
                    style={{ color: "white" }}
                  ></i>
                </a>
                <a
                  target="_blank"
                  href=""
                  onClick={() => {
                    window.open(`https://wa.me/+201014583439`);
                  }}
                >
                  <i
                    className="fa-brands fa-whatsapp fs-5 p-1"
                    style={{ color: "white" }}
                  ></i>
                </a>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-md-12">
              <p>
                Copyright &copy; 2022 by{" "}
                <span>
                  {" "}
                  <a
                    href="https://neoneg.com/"
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "white",
                    }}
                  >
                    NEON
                  </a>{" "}
                </span>
                <a
                  href=""
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    window.open(`https://wa.me/+201113588988`);
                  }}
                >
                  <i
                    className="fa-brands fa-whatsapp fs-5"
                    style={{ color: "white", marginRight: "10px" }}
                  ></i>
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

import React from "react";
import image1 from "../Images/Mr.Olive/packages/plastic/Pet Plastic Jar 2500 Ml.jpg";
import image2 from "../Images/Mr.Olive/packages/plastic/Pet Plastic Jar 3100 Ml (2).jpg";
import image3 from "../Images/Mr.Olive/packages/plastic/Pet PLastic Jar 3100 Ml.jpg";
import image4 from "../Images/Mr.Olive/packages/plastic/PLastic Jar 1 Kg.jpg";
import image5 from "../Images/Mr.Olive/packages/plastic/Pet Plastic Jar 2500 ML (2).jpg";
import image6 from "../Images/Mr.Olive/packages/plastic/Bucket 12 Litres.jpg";
import image7 from "../Images/Mr.Olive/packages/plastic/Bucket 16 Litres.jpg";
import image8 from "../Images/Mr.Olive/packages/plastic/كالاماتا.jpg";
import image9 from "../Images/Mr.Olive/packages/plastic/عجيزي.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  EffectCube,
  EffectFlip,
  Autoplay,
  EffectCoverflow,
} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import "swiper/css/effect-cube";

import "swiper/css/navigation";
import "swiper/css/effect-fade";
export default function SubCategory8() {
  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }

  return (
    <>
      <div className="products " onClick={closeSlide}>
 
      </div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-6">
            <table class="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col" dir="rtl" lang="ar">
                    اسم العبوة
                  </th>
                  <th scope="col" dir="rtl" lang="ar">
                    العناصر الموجودة داخل العبوة
                  </th>
                  <th scope="col" dir="rtl" lang="ar">
                    الوزن الصافي لكل علبة / كجم
                  </th>
                  <th scope="col" dir="rtl" lang="ar">
                    الوزن الاجمالي / كجم
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" rowspan="4" dir="rtl" lang="ar">
                    البرميل
                  </th>
                  <td dir="rtl" lang="ar">
                    {" "}
                    زيتون سليم{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    160 كجم
                  </td>
                  <td dir="rtl" lang="ar">
                    250 كجم
                  </td>
                </tr>
                <tr>
                  <td dir="rtl" lang="ar">
                    {" "}
                    زيتون مخلي وشرائح ومحشو
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    140 كجم
                  </td>
                  <td dir="rtl" lang="ar">
                    250 كجم{" "}
                  </td>
                </tr>
                <tr>
                  <td dir="rtl" lang="ar">
                    {" "}
                    خرشوف
                  </td>
                  <td dir="rtl" lang="ar">
                    150 كجم{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    250 كجم
                  </td>
                </tr>
                <tr>
                  <td dir="rtl" lang="ar">
                    {" "}
                    فلفل
                  </td>
                  <td dir="rtl" lang="ar">
                    140 كجم{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    250 كجم{" "}
                  </td>
                </tr>
                {/**---------------------------- */}
                <tr>
                  <th dir="rtl" lang="ar" scope="row" rowspan="3">
                    دلو
                  </th>
                  <td dir="rtl" lang="ar">
                    {" "}
                    جميع المنتجات{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    10 كجم
                  </td>
                  <td dir="rtl" lang="ar">
                    16 كجم
                  </td>
                </tr>
                <tr>
                  <td dir="rtl" lang="ar">
                    {" "}
                    جميع المنتجات{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    5 كجم
                  </td>
                  <td dir="rtl" lang="ar">
                    8 كجم{" "}
                  </td>
                </tr>
                <tr>
                  <td dir="rtl" lang="ar">
                    {" "}
                    جميع المنتجات{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    3 كجم{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    5 كجم
                  </td>
                </tr>

                <tr>
                  <th dir="rtl" lang="ar" scope="row">
                    برطمان (PET)
                  </th>
                  <td dir="rtl" lang="ar">
                    {" "}
                    جميع المنتجات{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    عدد 6 برطمانات للكرتون ويبلغ وزن البرطمان الصافي 1.500 كجم
                  </td>
                  <td dir="rtl" lang="ar">
                    الوزن الإجمالي للكرتون 18 كجم
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="col-md-6"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Swiper
              // install Swiper modules
              modules={[
                Navigation,
                Pagination,
                Autoplay,
                EffectFade,
                EffectFlip,
                EffectCoverflow,
                EffectCube,
              ]}
              spaceBetween={50}
              slidesPerView={2}
              navigation
              autoplay={true}
              loop={true}
            >
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {" "}
                <figure>
                  {" "}
                  <img src={image1} alt="" style={{ width: "100%" }} />
                  <figcaption>برطمان بلاستيك 2500 مل</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image2} alt="" style={{ width: "100%" }} />
                  <figcaption>برطمان بلاستيك 3100 مل</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image3} alt="" style={{ width: "100%" }} />
                  <figcaption>برطمان بلاستيك 3100 مل</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {" "}
                <figure>
                  {" "}
                  <img src={image4} alt="" style={{ width: "100%" }} />
                  <figcaption>برطمان بلاستيك 1 كجم</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image5} alt="" style={{ width: "100%" }} />
                  <figcaption>برطمان بلاستيك 2500 مل</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image6} alt="" style={{ width: "100%" }} />
                  <figcaption>دلو 12 لتر</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image7} alt="" style={{ width: "100%" }} />
                  <figcaption>دلو </figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image8} alt="" style={{ width: "100%" }} />
                  <figcaption>دلو </figcaption>
                </figure>
              </SwiperSlide>{" "}
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image9} alt="" style={{ width: "100%" }} />
                  <figcaption>دلو </figcaption>
                </figure>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

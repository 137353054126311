import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image1 from "../Images/Mr.Olive/mix/خرشوف قلوب.jpg";
import image2 from "../Images/Mr.Olive/mix/خرشوف قرص.jpg";
import image3 from "../Images/Mr.Olive/mix/خرشوف اربع اربع.jpg";

import contact from "../Images/Group-of-customer-service-representatives.jpg";

export default function Artichoke() {
  const [currID, setCurrID] = useState("");
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  async function sendQuote(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `https://mrolive-back-end-2c3o.onrender.com/api/v1/quote`,
        {
          name: name,
          email: email,
          productName: productName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.msg);
        setName("");
        setEmail("");
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
      })
      .catch((e) => {
        toast.error(e?.data?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let imgList = Array.from(document.getElementsByClassName("imgItem"));
  let lightContainer = document.querySelector(".lightContainer");
  let lightBox = document.querySelector(".lightBox");
  let currentIndex = 0;
  let tmp = [
    {
      id: "1",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>описание товара</th>
              <th>Штук / 1,5 кг</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Сердцевины артишоков</th>
              <td> 20 : 50 кусок </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "2",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>описание товара</th>
              <th>Толщина</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Нижняя часть артишока</th>
              <td>3 : 10 CM </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "3",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>описание товара</th>
              <th>Длина</th>
              <th>Толщина</th>
              <th>Штук на 1,5 кг</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Четвертинки артишока</th>
              <td> 5:7 CM </td>
              <td> 2 CM </td>
              <td>120 : 180 кусок </td>
            </tr>
          </tbody>
        </table>
      ),
    },
  ];
  function showImage(x) {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-none", "d-flex");
    // console.log("ayhagaaasa")
    document.querySelector(".very").style.backgroundImage = `url(${x.src})`;
    document.querySelector(".productName").innerHTML = x.name;

    currentIndex = x.id;
    setCurrID(currentIndex);
  }

  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }
  function showForm() {
    document
      .querySelector(".showForm")
      .classList.replace("d-none", "d-inline-block");
  }
  return (
    <>
      <ToastContainer />
      <div className="products " onClick={closeSlide}></div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="1"
                src={image1}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Сердцевины артишоков");
                }}
                alt="image not found"
                className="photo"
                name="Сердцевины артишоков"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Сердцевины артишоков
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="2"
                src={image2}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Нижняя часть артишока");
                }}
                alt="image not found"
                className="photo"
                name="Нижняя часть артишока"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Нижняя часть артишока
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="3"
                src={image3}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Четвертинки артишока");
                }}
                alt="image not found"
                className="photo"
                name="Четвертинки артишока"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Четвертинки артишока
            </p>
          </div>
        </div>
      </div>
      <div className="lightContainer2 d-none  align-items-center">
        <div className="lightBox2  ">
          <div className="container">
            <div className="row d-flex justify-content-center ">
              <div className="ayhaga" style={{ marginTop: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img className=" very" alt="" style={{ padding: "0" }} />
                </div>
                <h4
                  className="productName"
                  style={{
                    color: "#4e732c",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></h4>
                {tmp.map((each) => {
                  if (each.id === currID) {
                    return (
                      <div>
                        <p style={{ color: "white" }}>
                          Египет стал одной из ведущих стран по производству и
                          изготовлению артишоков всех видов. Наша компания
                          внедрила современные методы выращивания, производства
                          и упаковки, а также мировые конкурентоспособные цены.
                        </p>
                        <div>{each.table}</div>
                      </div>
                    );
                  }
                })}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <button
                    className="btn get bgDark"
                    style={{ marginBottom: "5px" }}
                    onClick={showForm}
                  >
                    Получить предложение
                  </button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <form onSubmit={sendQuote}>
                    <div className="showForm d-none ">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Введите ваше имя"
                        style={{ marginBottom: "5px" }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <input
                        type="email"
                        className="form-control"
                        // id="exampleFormControlInput2"
                        id="email"
                        placeholder="Введите адрес электронной почты"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px",
                        }}
                      >
                        <button
                          className="btn get"
                          style={{ marginTop: "5px" }}
                          type="submit"
                        >
                          {loading ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : (
                            "Отправить"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <i
            style={{
              color: "black",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
            id="close"
            className="fa-solid fa-xmark"
            onClick={closeSlide}
          ></i>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image1 from "../Images/Mr.Olive/GreenOlive/زيتون أخضر بيكوال سليم.jpg";
import image2 from "../Images/Mr.Olive/GreenOlive/زيتون أخضر دولسي.jpg";
import image3 from "../Images/Mr.Olive/GreenOlive/زيتون أخضر منزانلو.jpg";
import image4 from "../Images/Mr.Olive/GreenOlive/عجيزي سليم.jpg";

import contact from "../Images/Group-of-customer-service-representatives.jpg";

export default function SubCategory1Italy() {
  const [currID, setCurrID] = useState("");
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  async function sendQuote(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `https://mrolive-back-end-2c3o.onrender.com/api/v1/quote`,
        {
          name: name,
          email: email,
          productName: productName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.msg);
        setName("");
        setEmail("");
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
      })
      .catch((e) => {
        toast.error(e?.data?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let imgList = Array.from(document.getElementsByClassName("imgItem"));
  let lightContainer = document.querySelector(".lightContainer");
  let lightBox = document.querySelector(".lightBox");
  let currentIndex = 0;

  let tmp = [
    {
      id: "1",
      text: (
        <p>
          Questa varietà è di origine spagnola e questa varietà ha ottenuto un
          grande successo nella sua coltivazione in Egitto. Questo tipo è il più
          diffuso in Egitto dopo l'oliva verde Al-Ajizi a causa della diversità
          del suo uso e la sua coltivazione è concentrata nella zona della
          strada del deserto di Ismailia e della strada del deserto di
          Alessandria. Questo si distingue per il suo gusto speciale perché
          contiene un'alta percentuale di olio. Questa varietà viene trattata
          nel nostro stabilimento in modo spagnolo e il metodo tradizionale
          (acqua e sale), che è il trattamento delle olive preparate per
          l'ossidazione. Lo produciamo anche e lo dividiamo in diversi tipi:
          <br />
          1. Olive verdi Picual intere <br />
          2. Olive verdi Picual in salamoia <br />
          3. Olive Picual verdi a fette
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th> Descrizione dell'articolo</th>
              <th>Grandezza</th>
              <th>Pezzi di olive/KG</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="12"> Olive verdi Picual</th>
              <td> Giganti </td>
              <td>141-160</td>
            </tr>
            <tr>
              <td> Extra Gigantesco</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td> Gigantesco</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td> Extra Grande</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>Grande</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td> Superiore</td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>Brillante</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td>Fine</td>
              <td> 321-350</td>
            </tr>
            <tr>
              <td>Proiettili</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td> </td>
              <td> 381-420</td>
            </tr>
            <tr>
              <td></td>
              <td> 421-450</td>
            </tr>

            <tr>
              <td></td>
              <td> 451-500</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "2",
      text: (
        <p>
          Questa varietà è di origine francese e questa varietà ha ottenuto un
          grande successo nella sua coltivazione. La sua coltivazione è
          concentrata nell' area della strada del deserto di Ismailia e della
          strada del deserto di Alessandria. Questo è caratterizzato dal suo
          gusto speciale, coltivazione intensiva e produzione molto abbondante.
          Questa varietà è trattata nel modo spagnolo e il metodo tradizionale
          (acqua e sale).
        </p>
      ),
    },
    {
      id: "3",
      text: (
        <p>
          Questa varietà è di origine spagnola, e questa varietà ha raggiunto un
          grande successo nella sua coltivazione in Egitto a causa della
          diversità del suo uso e la sua coltivazione è concentrata nella zona
          della strada del deserto di Ismailia e della strada del deserto di
          Alessandria. Questa varietà si distingue per il suo gusto speciale
          perché contiene un'alta percentuale di olio. Questa varietà è trattata
          in modo spagnolo e il metodo tradizionale (acqua e sale), che è il
          trattamento delle olive preparate per l'ossidazione. Lo produciamo
          anche e lo dividiamo in diversi tipi: <br />
          1. Olive verdi Manzanilla intere
          <br />
          2. Olive verdi Manzanilla denocciolate
          <br />
          3. Olive manzanilla verdi affettate
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th> Descrizione dell'articolo</th>
              <th>(Standard greci)</th>
              <th>(Standard spagnolo)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="12"> Olive verdi Manzanilla</th>
              <td> Giganti </td>
              <td>141-160</td>
            </tr>
            <tr>
              <td> Extra Gigantesco</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td> Gigantesco</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td> Extra Grande</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>Grande</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td> Superiore</td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>Brillante</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td>Fine</td>
              <td> 321-350</td>
            </tr>
            <tr>
              <td>Proiettili</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td> </td>
              <td> 381-420</td>
            </tr>
            <tr>
              <td></td>
              <td> 421-450</td>
            </tr>

            <tr>
              <td></td>
              <td> 451-500</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "4",
      text: (
        <p>
          Questa varietà è di origine egiziana e la sua coltivazione è
          concentrata nella zona di Fayoum e nella strada del deserto di
          Alessandria. Questa varietà è caratterizzata dai suoi elevati volumi
          che sono costantemente richiesti nei mercati globali. Questa varietà è
          trattata in modo spagnolo e il metodo tradizionale (acqua e sale), che
          è il trattamento delle olive preparate per l'ossidazione. Lo
          produciamo anche e lo dividiamo in diversi tipi: <br />
          1. Olive intere verdi invecchiate <br />
          2. Olive disossate verdi invecchiate
          <br />
          3. Olive verdi invecchiate a fette
          <br />
          4. Olive verdi semilavorate
        </p>
      ),
    },
  ];

  function showImage(x) {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-none", "d-flex");
    // console.log("ayhagaaasa")
    document.querySelector(".very").style.backgroundImage = `url(${x.src})`;
    document.querySelector(".productName").innerHTML = x.name;
    // document.querySelector(".productParagraph").innerHTML  = x.about;

    currentIndex = x.id;
    setCurrID(currentIndex);
    //  console.log(currentIndex)
  }

  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }
  function showForm() {
    document
      .querySelector(".showForm")
      .classList.replace("d-none", "d-inline-block");
  }
  return (
    <>
      <ToastContainer />

      <div className="products " onClick={closeSlide}></div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-4 photoContainer2">
            <div className="imgItem" id="imgItems">
              <img
                id="1"
                src={image1}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Verde picual Oliva");
                }}
                alt="image not found"
                className="photo"
                name="Verde picual Oliva"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              {" "}
              Verde picual Oliva
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="2"
                src={image2}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Verde Dolci Oliva");
                }}
                alt="image not found"
                className="photo"
                name="Verde Dolci Oliva"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Verde Dolci Oliva
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="3"
                src={image3}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Oliva verde Manzanilla");
                }}
                alt="image not found"
                className="photo"
                name="Oliva verde Manzanilla"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Oliva verde Manzanilla
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="4"
                src={image4}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Oliva verde Azizi");
                }}
                alt="image not found"
                className="photo"
                name="Oliva verde Azizi"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Oliva verde Azizi
            </p>
          </div>
        </div>
      </div>
      <div className="lightContainer2 d-none  align-items-center">
        <div className="lightBox2  ">
          <div className="container">
            <div className="row d-flex justify-content-center ">
              <div className="ayhaga" style={{ marginTop: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img className=" very" alt="" style={{ padding: "0" }} />
                </div>
                <h4
                  className="productName"
                  style={{
                    color: "#4e732c",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></h4>
                {tmp.map((each) => {
                  if (each.id === currID) {
                    return (
                      <div>
                        <p style={{ color: "white" }}> {each.text} </p>
                        <div>{each.table}</div>
                      </div>
                    );
                  }
                })}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <button
                    className="btn get bgDark"
                    style={{ marginBottom: "5px" }}
                    onClick={showForm}
                  >
                    Ottieni un preventivo
                  </button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <form onSubmit={sendQuote}>
                    <div className="showForm d-none ">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Inserisci il tuo nome"
                        style={{ marginBottom: "5px" }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <input
                        type="email"
                        className="form-control"
                        // id="exampleFormControlInput2"
                        id="email"
                        placeholder="Inserisci la tua email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px",
                        }}
                      >
                        <button
                          className="btn get"
                          style={{ marginTop: "5px" }}
                          type="submit"
                        >
                          {loading ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : (
                            "Invia"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <img src={image1} alt="image not found" className='lightBox'/> */}

          <i
            style={{
              color: "black",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
            id="close"
            className="fa-solid fa-xmark"
            onClick={closeSlide}
          ></i>
        </div>
      </div>

      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="contactContect">
                  <h6> contattaci</h6>

                  <p>quando hai una domanda siamo qui per aiutarti</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}

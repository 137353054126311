import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image1 from "../Images/Mr.Olive/BlackOlive/زيتون أسود مؤكسد سليم.jpg";
import image2 from "../Images/Mr.Olive/BlackOlive/زيتون بيكوال أسود طبيعي سليم.jpg";
import image3 from "../Images/Mr.Olive/BlackOlive/زيتون دولسي أسود طبيعي سليم.jpg";
import image4 from "../Images/Mr.Olive/BlackOlive/زيتون سيرس كولا أسود سليم.jpg";
import image5 from "../Images/Mr.Olive/BlackOlive/زيتون كالاماتا أسود طبيعي سليم.jpg";
import image6 from "../Images/Mr.Olive/BlackOlive/زيتون منزانلو أسود  طبيعي سليم.jpg";
import contact from "../Images/Group-of-customer-service-representatives.jpg";

export default function SubCategory4() {
  const [currID, setCurrID] = useState("");
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  async function sendQuote(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `https://mrolive-back-end-2c3o.onrender.com/api/v1/quote`,
        {
          name: name,
          email: email,
          productName: productName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.msg);
        setName("");
        setEmail("");
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
      })
      .catch((e) => {
        toast.error(e?.data?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let imgList = Array.from(document.getElementsByClassName("imgItem"));
  let lightContainer = document.querySelector(".lightContainer");
  let lightBox = document.querySelector(".lightBox");
  let currentIndex = 0;
  let tmp = [
    {
      id: "1",
      text: (
        <p>
          The Picula olive tree is the most widespread in the expenses of our
          company's farms, after the Ajizi olives. Natural black Picual olives
          are distinguished by their large sizes and attractive violet color. We
          manufacture them in our factory using healthy methods that preserve
          the nutritional value of the olive, and we also classify it into 3
          types They are: <br />
          1. Natural whole black Picual olives <br />
          2. Natural pitted black Picual olives <br />
          3. Natural black Picual olive slices
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th>Size(Greek Standards)</th>
              <th>Pieces of olives/KG(Spanish Standard)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="13"> Black Natural Picual Olives</th>
              <td> Mammouth </td>
              <td>101-110</td>
            </tr>
            <tr>
              <td>Souper Colossal</td>
              <td> 111-120</td>
            </tr>
            <tr>
              <td> Colossal</td>
              <td>121-141</td>
            </tr>
            <tr>
              <td> Giants</td>
              <td>141-160</td>
            </tr>
            <tr>
              <td>Extra Jumbo</td>
              <td>161-180</td>
            </tr>
            <tr>
              <td> Jumbo</td>
              <td> 181-200</td>
            </tr>
            <tr>
              <td>Extra Large</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>Large</td>
              <td> 231-260</td>
            </tr>
            <tr>
              <td>Superior</td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>Brilliant </td>
              <td> 291-320</td>
            </tr>
            <tr>
              <td>Fine</td>
              <td> 321-350</td>
            </tr>

            <tr>
              <td>Bullets</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td></td>
              <td>381-400</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "2",
      text: (
        <p>
          This variety is called the Egyptian Kalamata, and the dulci tree is
          one of the most productive trees, and this tree is characterized by
          its adaptation to climatic changes. This quality is very much in
          demand from the local market, Arab markets and some European markets
          The natural black dulse is distinguished by its unique shape, its
          attractive violet color, and its abundance in oil, which gives a
          delicious taste, and we manufacture it in our factory using healthy
          methods that preserve the nutritional value of the olive
        </p>
      ),

      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th>Pieces of olives/KG(Spanish Standard)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="6"> Black Natural Dolci Olives</th>
              <td>201-250 </td>
            </tr>
            <tr>
              <td>251-300</td>
            </tr>
            <tr>
              <td> 301-350</td>
            </tr>
            <tr>
              <td> 351-400 </td>
            </tr>
            <tr>
              <td>401-450</td>
            </tr>
            <tr>
              <td> 451-500</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "3",
      text: (
        <p>
          This type is one of the most abundant types of olive oil, and this
          quality has been used with great success in pickling and has become
          one of the most important types in demand from global markets during
          the past years Our company has allocated many areas to expand the
          cultivation of this quality This quality is very much in demand from
          the local market, Arab markets and some of the markets The natural
          black fermented cola with its unique shape, attractive violet color,
          and its abundance in oil, which gives a delicious taste, and we
          manufacture it in our factory using healthy methods that preserve the
          nutritional value of the olive and also we classify it into <br />
          1- Natural black cola fermented olives <br />
          2- Natural fermented black cola cirus olives <br />
          3- Natural black cola sarsa olive slices <br />
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th>Size(Greek Standards)</th>
              <th>Pieces of olives/KG(Spanish Standard)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="10"> Black Natural Sersecola Olives</th>
              <td> Jumbo </td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Extra Large</td>
              <td> 201-230</td>
            </tr>
            <tr>
              <td> Large</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td>Superior</td>
              <td>261-290</td>
            </tr>
            <tr>
              <td>Brilliant</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td> Fine</td>
              <td>321-350</td>
            </tr>
            <tr>
              <td>Bullets</td>
              <td>351-380</td>
            </tr>
            <tr>
              <td></td>
              <td>381-400</td>
            </tr>
            <tr>
              <td></td>
              <td>401-450</td>
            </tr>
            <tr>
              <td> </td>
              <td>451-500</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "4",
      text: (
        <p>
          This variety was named Kalamata due to its cultivation in the city of
          Kalamata, Greece, the origin of this original quality. Our company's
          farms have allocated areas designated for the cultivation of this type
          (Kalamata) for self-sufficiency and to meet the needs of our customers
          locally and globally. The natural black kalamata is distinguished by
          its unique shape, its attractive purple color, and its abundance in
          oil that gives a delicious taste. We manufacture it in our factory
          using healthy methods that preserve the nutritional value of the
          olive, and we also classify it into 3 types They are: <br />
          1. Natural whole black kalamata olives <br />
          2. Natural pitted black Kalamata olives <br />
          3. Natural black kalamata olives sliced <br />
          4. Half natural black Kalamata olives
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th>Size(Greek Standards)</th>
              <th>Pieces of olives/KG(Spanish Standard)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="11">Black Natural Kalamata Olives</th>
              <td> Giants </td>
              <td>141-160</td>
            </tr>
            <tr>
              <td>Extra Jumbo</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td> Jumbo</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Extra Large</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>Large</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td> Superior</td>
              <td>261-290</td>
            </tr>
            <tr>
              <td>Brilliant</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td>Fine</td>
              <td>321-350</td>
            </tr>
            <tr>
              <td>Bullets</td>
              <td>351-380</td>
            </tr>
            <tr>
              <td> </td>
              <td>381-400</td>
            </tr>

            <tr>
              <td> </td>
              <td>401-450</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "5",
      text: (
        <p>
          This variety is the second face of the green manzanilla olive, and it
          is one of the more prevalent varieties in the farms and factory of our
          company, due to the great demand for it from our external customers
          The natural black manzanilla is characterized by its large sizes and
          attractive violet color, and we manufacture it in our factory using
          healthy methods that preserve the nutritional value of the olive seed,
          and we also classify it into 3 types They are: <br />
          1. Whole natural black manzanilla olives <br />
          2. Natural pitted black manzanilla olives <br />
          3. Sliced natural black manzanilla olives <br />
          4. Natural Black Manzanilla Olives
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th>Size(Greek Standards)</th>
              <th>Pieces of olives/KG(Spanish Standard)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="11"> Black Natural Manzanilla Olives</th>
              <td> Colossal </td>
              <td>121-141</td>
            </tr>
            <tr>
              <td>Giants</td>
              <td>141-160</td>
            </tr>
            <tr>
              <td> Extra Jumbo</td>
              <td>161-180</td>
            </tr>
            <tr>
              <td> Jumbo</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Extra Large</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td> Large</td>
              <td> 231-260</td>
            </tr>
            <tr>
              <td>Superior</td>
              <td>261-290</td>
            </tr>
            <tr>
              <td>Brilliant</td>
              <td> 291-320</td>
            </tr>
            <tr>
              <td>Fine</td>
              <td> 321-350</td>
            </tr>
            <tr>
              <td> Bullets</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td></td>
              <td> 381-400</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "6",
      text: (
        <p>
          Oxidized black olives obtain their black color after an industrial
          oxidation process, during which green olives preserved in water and
          salt undergo a strong alkaline treatment. Containers The containers
          are sterilized in an autoclave to obtain a delicious, healthy and safe
          product. Oxidized black olives are divided into three: <br />
          1. Whole Oxidized Black Olives <br />
          2. Sliced oxidized black olives <br />
          3. Vinegar oxidized black olives
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th>Size(Greek Standards)</th>
              <th>Pieces of olives/KG(Spanish Standard)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="14">
                {" "}
                Whole Black Ripe Olives Sliced Black Ripe Olives Pitted Black
                Ripe Olives
              </th>
              <td> Super Mammouth </td>
              <td>91-100</td>
            </tr>
            <tr>
              <td> Mammouth</td>
              <td> 101-110</td>
            </tr>
            <tr>
              <td> Souper Colossal</td>
              <td>111-120</td>
            </tr>
            <tr>
              <td>Colossal</td>
              <td>121-141</td>
            </tr>
            <tr>
              <td>Giants</td>
              <td>141-160</td>
            </tr>
            <tr>
              <td> Extra Jumbo</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td>Jumbo</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Extra Large</td>
              <td> 201-230</td>
            </tr>
            <tr>
              <td>Large</td>
              <td> 231-260</td>
            </tr>
            <tr>
              <td>Superior </td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>Brilliant</td>
              <td> 291-320</td>
            </tr>

            <tr>
              <td>Fine</td>
              <td> 321-350</td>
            </tr>

            <tr>
              <td>Bullets</td>
              <td> 351-380</td>
            </tr>

            <tr>
              <td></td>
              <td> 381-400</td>
            </tr>
          </tbody>
        </table>
      ),
    },
  ];
  function showImage(x) {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-none", "d-flex");
    // console.log("ayhagaaasa")
    document.querySelector(".very").style.backgroundImage = `url(${x.src})`;
    document.querySelector(".productName").innerHTML = x.name;

    currentIndex = x.id;
    // console.log(currentIndex)
    setCurrID(currentIndex);
  }

  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }
  function showForm() {
    document
      .querySelector(".showForm")
      .classList.replace("d-none", "d-inline-block");
  }
  return (
    <>
      <ToastContainer />

      <div className="products " onClick={closeSlide}></div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="1"
                src={image2}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Picual Olives");
                }}
                alt="image not found"
                className="photo"
                name="Picual Olives"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              {" "}
              Picual Olives
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="2"
                src={image3}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Dolci olives");
                }}
                alt="image not found"
                className="photo"
                name="Dolci olives"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Dolci olives
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="3"
                src={image4}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Serse Cola olives");
                }}
                alt="image not found"
                className="photo"
                name="Serse Cola olives"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Serse Cola olives
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="4"
                src={image5}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Kalamata olives");
                }}
                alt="image not found"
                className="photo"
                name="Kalamata olives"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Kalamata olives
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="5"
                src={image6}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Manzanilla olives");
                }}
                alt="image not found"
                className="photo"
                name="Manzanilla olives"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Manzanilla olives
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="6"
                src={image1}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Black Oxidized olives");
                }}
                alt="image not found"
                className="photo"
                name="Black Oxidized olives"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Black Oxidized olives
            </p>
          </div>
        </div>
      </div>
      <div className="lightContainer2 d-none  align-items-center">
        <div className="lightBox2  ">
          <div className="container">
            <div className="row d-flex justify-content-center ">
              <div className="ayhaga" style={{ marginTop: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img className=" very" alt="" style={{ padding: "0" }} />
                </div>
                <h4
                  className="productName"
                  style={{
                    color: "#4e732c",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></h4>
                {tmp.map((each) => {
                  if (each.id === currID) {
                    return (
                      <div>
                        <p style={{ color: "white" }}> {each.text} </p>
                        <div>{each.table}</div>
                      </div>
                    );
                  }
                })}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <button
                    className="btn get bgDark"
                    style={{ marginBottom: "5px" }}
                    onClick={showForm}
                  >
                    Get Quote
                  </button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <form onSubmit={sendQuote}>
                    <div className="showForm d-none ">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter Your Name"
                        style={{ marginBottom: "5px" }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <input
                        type="email"
                        className="form-control"
                        // id="exampleFormControlInput2"
                        id="email"
                        placeholder="Enter Your Email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px",
                        }}
                      >
                        <button
                          className="btn get"
                          style={{ marginTop: "5px" }}
                          type="submit"
                        >
                          {loading ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <img src={image1} alt="image not found" className='lightBox'/> */}

          <i
            style={{
              color: "black",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
            id="close"
            className="fa-solid fa-xmark"
            onClick={closeSlide}
          ></i>
        </div>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="contactContect">
                  <h6> CONTACT US</h6>

                  <p>when you have a question we're here to help</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image1 from "../Images/Mr.Olive/mix/قرنبيط.jpg";
import image2 from "../Images/Mr.Olive/mix/خيار مخلل.jpg";

import image4 from "../Images/Mr.Olive/mix/فلفل بنانا شرائح مخلي.jpg";

import image5 from "../Images/Mr.Olive/mix/مخلل مشكل.jpg";

import contact from "../Images/Group-of-customer-service-representatives.jpg";

export default function Cauliflower() {
  const [currID, setCurrID] = useState("");
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  async function sendQuote(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `https://mrolive-back-end-2c3o.onrender.com/api/v1/quote`,
        {
          name: name,
          email: email,
          productName: productName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.msg);
        setName("");
        setEmail("");
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
      })
      .catch((e) => {
        toast.error(e?.data?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let imgList = Array.from(document.getElementsByClassName("imgItem"));
  let lightContainer = document.querySelector(".lightContainer");
  let lightBox = document.querySelector(".lightBox");
  let currentIndex = 0;
  let tmp = [
    { id: "1", text: ``, table: "" },
    {
      id: "2",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th>No. of size</th>
              <th>Length</th>
              <th>Thickness</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="8"> Pickled Cucumber</th>
              <td>1 </td>
              <td> 75 MM </td>
              <td>19 MM </td>
            </tr>
            <tr>
              <td>2 </td>
              <td>85 MM </td>
              <td>19:22 MM </td>
            </tr>
            <tr>
              <td>3 </td>
              <td>95 MM </td>
              <td>22:24.5 MM </td>
            </tr>
            <tr>
              <td>4 </td>
              <td>105 MM </td>
              <td>24.5:27 MM </td>
            </tr>
            <tr>
              <td>5 </td>
              <td>130 MM </td>
              <td>27:29.5 MM </td>
            </tr>
            <tr>
              <td>6 </td>
              <td>135 MM </td>
              <td>29.5:31 MM </td>
            </tr>
            <tr>
              <td>7 </td>
              <td>140 MM </td>
              <td>31:33 MM </td>
            </tr>
            <tr>
              <td>8 </td>
              <td>140 MM </td>
              <td>33:34 MM </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "3",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th> 5 Fruits</th>
              <th>7 Fruits</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Mixed Vegetables</th>
              <td> Carrot + Turnip + Union + Cucumber + Pepper </td>
              <td>
                Carrot + Turnip + Union + Cucumber + Pepper + Lemon + Olives{" "}
              </td>
            </tr>
          </tbody>
        </table>
      ),
    },
  ];
  function showImage(x) {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-none", "d-flex");
    // console.log("ayhagaaasa")
    document.querySelector(".very").style.backgroundImage = `url(${x.src})`;
    document.querySelector(".productName").innerHTML = x.name;

    currentIndex = x.id;
    setCurrID(currentIndex);
  }

  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }
  function showForm() {
    document
      .querySelector(".showForm")
      .classList.replace("d-none", "d-inline-block");
  }
  return (
    <>
      <ToastContainer />

      <div className="products " onClick={closeSlide}></div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="1"
                src={image1}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Cauliflower");
                }}
                alt="image not found"
                className="photo"
                name="cauliflower"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>cauliflower</p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="2"
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Pickle Cucumber");
                }}
                alt="image not found"
                className="photo"
                name="Pickle Cucumber"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Pickle Cucumber
            </p>
          </div>

          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="3"
                src={image5}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Mixed Pickled Vegetables");
                }}
                alt="image not found"
                className="photo"
                name="mixed pickled vegetables"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              mixed pickled vegetables
            </p>
          </div>
        </div>
      </div>
      <div className="lightContainer2 d-none  align-items-center">
        <div className="lightBox2  ">
          <div className="container">
            <div className="row d-flex justify-content-center ">
              <div className="ayhaga" style={{ marginTop: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img className=" very" alt="" style={{ padding: "0" }} />
                </div>
                <h4
                  className="productName"
                  style={{
                    color: "#4e732c",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></h4>
                {tmp.map((each) => {
                  if (each.id === currID) {
                    return (
                      <div>
                        <div>{each.table}</div>
                      </div>
                    );
                  }
                })}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <button
                    className="btn get bgDark"
                    style={{ marginBottom: "5px" }}
                    onClick={showForm}
                  >
                    Get Quote
                  </button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <form onSubmit={sendQuote}>
                    <div className="showForm d-none ">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter Your Name"
                        style={{ marginBottom: "5px" }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <input
                        type="email"
                        className="form-control"
                        // id="exampleFormControlInput2"
                        id="email"
                        placeholder="Enter Your Email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px",
                        }}
                      >
                        <button
                          className="btn get"
                          style={{ marginTop: "5px" }}
                          type="Submit"
                        >
                          {loading ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <i
            style={{
              color: "black",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
            id="close"
            className="fa-solid fa-xmark"
            onClick={closeSlide}
          ></i>
        </div>
      </div>
    </>
  );
}

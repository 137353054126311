import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image1 from "../Images/Mr.Olive/GreenOlive/زيتون أخضر محشي ثوم.jpg";
import image2 from "../Images/Mr.Olive/GreenOlive/زيتون أخضر محشي جزر.jpg";
import image3 from "../Images/Mr.Olive/GreenOlive/زيتون أخضر محشي خيار.jpg";
import image4 from "../Images/Mr.Olive/GreenOlive/زيتون أخضر محشي فلفل أحمر.jpg";
import image5 from "../Images/Mr.Olive/GreenOlive/زيتون أخضر محشي فلفل هاليبينو.jpg";
import image6 from "../Images/Mr.Olive/GreenOlive/زيتون أخضر محشي لوز.jpg";
import image7 from "../Images/Mr.Olive/GreenOlive/زيتون أخضر محشي ليمون.jpg";

import contact from "../Images/Group-of-customer-service-representatives.jpg";

export default function ProductStuffed() {
  const [currID, setCurrID] = useState("");
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  async function sendQuote(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `https://mrolive-back-end-2c3o.onrender.com/api/v1/quote`,
        {
          name: name,
          email: email,
          productName: productName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.msg);
        setName("");
        setEmail("");
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
      })
      .catch((e) => {
        toast.error(e?.data?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let imgList = Array.from(document.getElementsByClassName("imgItem"));
  let lightContainer = document.querySelector(".lightContainer");
  let lightBox = document.querySelector(".lightBox");
  let currentIndex = 0;
  // console.log(lightContainer);

  function showImage(x) {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-none", "d-flex");
    // console.log("ayhagaaasa")
    document.querySelector(".very").style.backgroundImage = `url(${x.src})`;
    document.querySelector(".productName").innerHTML = x.name;
    currentIndex = x.id;
    // console.log(currentIndex)
    setCurrID(currentIndex);
  }

  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }
  function showForm() {
    document
      .querySelector(".showForm")
      .classList.replace("d-none", "d-inline-block");
  }

  return (
    <>
      <ToastContainer />

      <div className="products " onClick={closeSlide}></div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="1"
                src={image1}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Green olives stuffed with garlic");
                }}
                alt="image not found"
                className="photo"
                name="Green olives stuffed with garlic"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Green olives stuffed with garlic
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="2"
                src={image2}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Green olives stuffed with carrots");
                }}
                alt="image not found"
                className="photo"
                name="Green olives stuffed with carrots"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Green olives stuffed with carrots
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="3"
                src={image3}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Green olives stuffed with cucumber");
                }}
                alt="image not found"
                className="photo"
                name="Green olives stuffed with cucumber"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Green olives stuffed with cucumber
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="4"
                src={image4}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Green olives stuffed with red pepper");
                }}
                alt="image not found"
                className="photo"
                name="Green olives stuffed with red pepper"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Green olives stuffed with red pepper
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="5"
                src={image5}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Green olives stuffed with jalapeno");
                }}
                alt="image not found"
                className="photo"
                name="Green olives stuffed with jalapeno"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Green olives stuffed with jalapeno
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="6"
                src={image6}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Green olives stuffed with almonds");
                }}
                alt="image not found"
                className="photo"
                name="Green olives stuffed with almonds"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Green olives stuffed with almonds
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="7"
                src={image7}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Green olives stuffed with lemon");
                }}
                alt="image not found"
                className="photo"
                name="Green olives stuffed with lemon"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Green olives stuffed with lemon
            </p>
          </div>
        </div>
      </div>
      <div className="lightContainer2 d-none  align-items-center">
        <div className="lightBox2  ">
          <div className="container">
            <div className="row d-flex justify-content-center ">
              <div className="ayhaga" style={{ marginTop: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img className=" very" alt="" style={{ padding: "0" }} />
                </div>
                <h4
                  className="productName"
                  style={{
                    color: "#4e732c",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></h4>

                <div>
                  <p style={{ color: "white" }}>
                    Our factory stuffs green olives by hand according to
                    international specifications and standards Its types are as
                    follows:-
                    <br />
                    1. Green olive stuffed with Carrot.
                    <br />
                    2. Green olive stuffed with red pepper.
                    <br />
                    3. Green olives stuffed with green jalapeno.
                    <br />
                    4. Green olive stuffed with garlic.
                    <br />
                    5. Green olive stuffed with Almond.
                    <br />
                    6. Green olive stuffed with lemon.
                    <br />
                    7. Green olive stuffed with Gherkin.{" "}
                  </p>
                  <div>
                    {" "}
                    <table class="  table-bordered ">
                      <thead>
                        <tr>
                          <th>Item Description</th>
                          <th>Size(Greek Standards)</th>
                          <th>Pieces of olives/KG(Spanish Standard)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th rowSpan="10">Stuffed Green Olives</th>
                          <td> Atlas </td>
                          <td>70-90</td>
                        </tr>
                        <tr>
                          <td> Super Mammouth</td>
                          <td> 91-100</td>
                        </tr>
                        <tr>
                          <td> Mammouth</td>
                          <td>101-110</td>
                        </tr>
                        <tr>
                          <td> Souper Colossal</td>
                          <td>111-120</td>
                        </tr>
                        <tr>
                          <td>Colossal</td>
                          <td>121-141</td>
                        </tr>
                        <tr>
                          <td> Giants</td>
                          <td> 141-160</td>
                        </tr>
                        <tr>
                          <td>Extra Jumbo</td>
                          <td>161-180</td>
                        </tr>
                        <tr>
                          <td>Jumbo</td>
                          <td> 181-200</td>
                        </tr>
                        <tr>
                          <td>Extra Large</td>
                          <td> 201-230</td>
                        </tr>
                        <tr>
                          <td>Large </td>
                          <td> 231-260</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <button
                    className="btn get bgDark"
                    style={{ marginBottom: "5px" }}
                    onClick={showForm}
                  >
                    Get Quote
                  </button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <form onSubmit={sendQuote}>
                    <div className="showForm d-none ">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter Your Name"
                        style={{ marginBottom: "5px" }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter Your Email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px",
                        }}
                      >
                        <button
                          className="btn get"
                          style={{ marginTop: "5px" }}
                          type="submit"
                        >
                          {loading ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <i
            style={{
              color: "black",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
            id="close"
            className="fa-solid fa-xmark"
            onClick={closeSlide}
          ></i>
        </div>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="contactContect">
                  <h6> CONTACT US</h6>

                  <p>when you have a question we're here to help</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}

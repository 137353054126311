import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Products.css";
import { Link } from "react-router-dom";
import contact from "../Images/Group-of-customer-service-representatives.jpg";

import image1 from "../Images/Mr.Olive/jalapeno/فلفل شيري سليم.jpg";
import image2 from "../Images/Mr.Olive/jalapeno/فلفل شيري مخلي.jpg";
import image3 from "../Images/Mr.Olive/jalapeno/فلفل هاليبينو أحمر سليم.jpg";
import image4 from "../Images/Mr.Olive/jalapeno/فلفل هاليبينو أحمر شرائح.jpg";
import image5 from "../Images/Mr.Olive/jalapeno/فلفل لمباردي.jpg";
import image6 from "../Images/Mr.Olive/jalapeno/فلفل مكسيكي.jpg";
import image7 from "../Images/Mr.Olive/jalapeno/فلفل هاليبينو أخضر سليم 1.jpg";
import image8 from "../Images/Mr.Olive/jalapeno/هاليبينو اخضر.jpg";
import image9 from "../Images/Mr.Olive/jalapeno/هريسة.jpg";
import image10 from "../Images/Mr.Olive/mix/فلفل بنانا شرائح مخلي.jpg";
import image24 from "../Images/Mr.Olive/packages/_KH13102-Recovered.jpg";
import image25 from "../Images/Mr.Olive/jalapeno/edited 3.jpg";
import image26 from "../Images/Mr.Olive/jalapeno/edited 4.jpg";

export default function ProductCat3Spain() {
  const [currID, setCurrID] = useState("");
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  async function sendQuote(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `https://mrolive-back-end-2c3o.onrender.com/api/v1/quote`,
        {
          name: name,
          email: email,
          productName: productName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.msg);
        setName("");
        setEmail("");
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
      })
      .catch((e) => {
        toast.error(e?.data?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let imgList = Array.from(document.getElementsByClassName("imgItem"));
  let lightContainer = document.querySelector(".lightContainer");
  let lightBox = document.querySelector(".lightBox");
  let currentIndex = 0;
  let tmp = [
    {
      id: "1",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descripción del Artículo</th>
              <th>Longitud</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Pimientos Cherry Rojos Enteros</th>
              <td> 25-50 MM </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "2",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descripción del Artículo</th>
              <th>Longitud</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th> Pimientos Rojos Cherry Deshuesados</th>
              <td> 25-50 MM </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "3",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descripción del Artículo</th>
              <th>Longitud</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th> Chiles Jalapeños Rojos Enteros</th>
              <td> 4-8 CM </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "4",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descripción del Artículo</th>
              <th>Espesor de rebanadas </th>
              <th>Diámetro de rebanadas</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th> Chiles Jalapeños Rojos Rebanados</th>
              <td>1 cm </td>
              <td>1.5 cm : 3.5 cm </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    { id: "5", text: ``, table: "" },
    {
      id: "6",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descripción del Artículo</th>
              <th colSpan="2">Longitud</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="2">Chiles Mexicanos</th>
              <td> Medio </td>
              <td> 3-6 CM </td>
            </tr>
            <tr>
              <td> Largo </td>
              <td> 6-10 CM </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "7",
      text: ` `,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descripción del Artículo</th>
              <th>Longitud</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th> Whole Green Jalapeno Peppers</th>
              <td> 4-8 CM </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "8",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descripción del Artículo</th>
              <th>Espesor de rebanadas </th>
              <th>Diámetro de rebanadas</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th> Chile jalapeño verde en rodajas</th>
              <td> 0.5 cm </td>
              <td> 1.5 cm : 3 cm </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    { id: "9", text: `` },
    {
      id: "10",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descripción del Artículo</th>
              <th>Espesor de rebanadas </th>
              <th>Diámetro de rebanadas</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th> Pimientos de plátano en rodajas</th>
              <td> 6 MM </td>
              <td>3 cm : 6 cm </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    { id: "11", text: `` },
  ];
  function showImage(x) {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-none", "d-flex");
    // console.log("ayhagaaasa")
    document.querySelector(".very").style.backgroundImage = `url(${x.src})`;
    document.querySelector(".productName").innerHTML = x.name;

    currentIndex = x.id;
    setCurrID(currentIndex);

    // console.log(currentIndex)
  }

  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }
  function showForm() {
    document
      .querySelector(".showForm")
      .classList.replace("d-none", "d-inline-block");
  }
  return (
    <>
      <ToastContainer />

      <div className="products "></div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="1"
                src={image1}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Pimientos Cherry Rojos Enteros");
                }}
                alt="image not found"
                className="photo"
                name="Pimientos Cherry Rojos Enteros"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Pimientos Cherry Rojos Enteros
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="2"
                src={image2}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Pimientos Rojos Cherry Deshuesados");
                }}
                alt="image not found"
                className="photo"
                name="Pimientos Rojos Cherry Deshuesados"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Pimientos Rojos Cherry Deshuesados
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="3"
                src={image3}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Chiles Jalapeños Rojos Enteros");
                }}
                alt="image not found"
                className="photo"
                name="Chiles Jalapeños Rojos Enteros"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Chiles Jalapeños Rojos Enteros
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="4"
                src={image4}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Chiles Jalapeños Rojos Rebanados");
                }}
                alt="image not found"
                className="photo"
                name="Chiles Jalapeños Rojos Rebanados"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Chiles Jalapeños Rojos Rebanados
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="5"
                src={image5}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Pimienta lombarda");
                }}
                alt="image not found"
                className="photo"
                name="Pimienta lombarda"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Pimienta lombarda
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="6"
                src={image6}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Chiles Mexicanos");
                }}
                alt="image not found"
                className="photo"
                name="Chiles Mexicanos"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Chiles Mexicanos
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="7"
                src={image7}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Whole Green Jalapeno Peppers");
                }}
                alt="image not found"
                className="photo"
                name="Whole Green Jalapeno Peppers"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Whole Green Jalapeno Peppers
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="8"
                src={image8}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Chiles Jalapeños Verdes Rebanados");
                }}
                alt="image not found"
                className="photo"
                name="Chiles Jalapeños Verdes Rebanados"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Chiles Jalapeños Verdes Rebanados
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="9"
                src={image9}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("pimiento rojo triturado");
                }}
                alt="image not found"
                className="photo"
                name="pimiento rojo triturado"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              pimiento rojo triturado
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="10"
                src={image10}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Pimientos de plátano en rodajas");
                }}
                alt="image not found"
                className="photo"
                name="Pimientos de plátano en rodajas"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Pimientos de plátano en rodajas
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className=" imgItem">
              <img
                id="11"
                src={image24}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("media pimienta");
                }}
                alt="image not found"
                className="photo"
                name="media pimienta"
              />{" "}
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}></p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className=" imgItem">
              <img
                id="25"
                src={image25}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Pimiento rojo");
                }}
                alt="image not found"
                className="photo"
                name="Pimiento rojo"
              />{" "}
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}></p>
          </div>{" "}
          <div className="col-md-4 photoContainer2">
            <div className=" imgItem">
              <img
                id="26"
                src={image26}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("medio pimiento rojo");
                }}
                alt="image not found"
                className="photo"
                name="medio pimiento rojo"
              />{" "}
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}></p>
          </div>
        </div>
      </div>
      <div className="lightContainer2 d-none  align-items-center">
        <div className="lightBox2  ">
          <div className="container">
            <div className="row d-flex justify-content-center ">
              <div className="ayhaga" style={{ marginTop: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img className=" very" alt="" style={{ padding: "0" }} />
                </div>
                <h4
                  className="productName"
                  style={{
                    color: "#4e732c",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></h4>
                {tmp.map((each) => {
                  if (each.id === currID) {
                    return (
                      <div>
                        <p style={{ color: "white" }}>
                          {" "}
                          Egipto se ha convertido en uno de los países líderes
                          en la producción y fabricación de pimientos de todo
                          tipo. Nuestra empresa ha introducido métodos modernos
                          en agricultura, fabricación y envasado, así como
                          precios competitivos a nivel mundial.
                        </p>
                        <div>{each.table}</div>
                      </div>
                    );
                  }
                })}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <button
                    className="btn get bgDark"
                    style={{ marginBottom: "5px" }}
                    onClick={showForm}
                  >
                    Obtener cotización
                  </button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <form onSubmit={sendQuote}>
                    <div className="showForm d-none ">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Introduzca su nombre"
                        style={{ marginBottom: "5px" }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <input
                        type="email"
                        className="form-control"
                        // id="exampleFormControlInput2"
                        id="email"
                        placeholder="Introduce tu correo electrónico"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px",
                        }}
                      >
                        <button
                          className="btn get"
                          style={{ marginTop: "5px" }}
                          type="submit"
                        >
                          {loading ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : (
                            "Enviar"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <img src={image1} alt="image not found" className='lightBox'/> */}

          <i
            style={{
              color: "black",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
            id="close"
            className="fa-solid fa-xmark"
            onClick={closeSlide}
          ></i>
        </div>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="contactContect">
                  <h6> contáctenos</h6>

                  <p>cuando tienes una pregunta estamos aquí para ayudarte</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image1 from "../Images/Mr.Olive/GreenOlive/زيتون أخضر شرائح عجيزي.jpg";
import image2 from "../Images/Mr.Olive/GreenOlive/أنصاف أخضر.jpg";
import image3 from "../Images/Mr.Olive/GreenOlive/زيتون أخضر شرائح منزانلو.jpg";
import image4 from "../Images/Mr.Olive/GreenOlive/زيتون بيكوال أخضر شرائح.jpg";
import contact from "../Images/Group-of-customer-service-representatives.jpg";

export default function SubCategoryAr() {
  const [currID, setCurrID] = useState("");
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  async function sendQuote(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `https://mrolive-back-end-2c3o.onrender.com/api/v1/quote`,
        {
          name: name,
          email: email,
          productName: productName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.msg);
        setName("");
        setEmail("");
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
      })
      .catch((e) => {
        toast.error(e?.data?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let imgList = Array.from(document.getElementsByClassName("imgItem"));
  let lightContainer = document.querySelector(".lightContainer");
  let lightBox = document.querySelector(".lightBox");
  let currentIndex = 0;
  let tmp = [
    {
      id: "1",
      text: (
        <p dir="rtl" lang="ar">
          هذا الصنف مصري المنشأ وتتركز زراعته في منطقة الفيوم وطريق الاسكندرية
          الصحراوي . ويتميز هذا الصنف باحجامه العالية المطلوبة باستمرار في
          الاسواق العالمية ويعامل هذ الصنف بالطريقة الاسبانية والطريقة التقليدية
          (الماء والملح ) وهي معاملة الزيتون المعد للاكسدة ونقوم بتصنيعه ايضا
          وتقسيمه الى عدة انواع :
          <br />
          1. زيتون عجيزي أخضر سليم
          <br />
          2. زيتون عجيزي أخضر مخلي
          <br />
          3. زيتون عجيزي أخضر شرائح
          <br />
          4. زيتون عجيزي أخضر انصاف
        </p>
      ),
    },
    {
      id: "2",
      text: (
        <p dir="rtl" lang="ar">
          هذا التنوع من أصل فرنسي ، وقد حقق هذا التنوع نجاحًا كبيرًا في زراعته.
          تتركز زراعته في منطقة طريق الإسماعيلية الصحراوي وطريق الإسكندرية
          الصحراوي. وتتميز بمذاقها الخاص وزراعتها المكثفة وإنتاجها الوفير للغاية
          يتم معالجة هذا الصنف بالطريقة الإسبانية والطريقة التقليدية (الماء
          والملح).
        </p>
      ),
    },
    {
      id: "3",
      text: (
        <p dir="rtl" lang="ar">
          هذا الصنف اسباني المنشأ وحقق هذا الصنف نجاحا كبيرا في زراعته في
          مصربسبب تنوع استخدامته وتتركز زراعته في منطقة طريق الاسماعيلية
          الصحراوي وطريق الاسكندرية الصحراوي . ويتميزهذا بمذاقه الاخص لاحتواءه
          على نسبة عالية من الزيت ويعامل هذ الصنف بالطريقة الاسبانية والطريقة
          التقليدية (الماء والملح ) وهي معاملة الزيتون المعد للاكسدة ونقوم
          بتصنيعه ايضا وتقسيمه الى عدة انواع :
          <br />
          1. زيتون منزانيلا أخضر سليم
          <br />
          2. زيتون منزانيلا أخضر مخلي
          <br />
          3. زيتون منزانيلا أخضر شرائح
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>وصف السلعة</th>
              <th>الحجم (المعايير اليونانية) </th>
              <th>قطع زيتون / كيلو جرام (معيار اسباني)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="12">زيتون مانزانيلا أخضر</th>
              <td> جانتس </td>
              <td>141-160</td>
            </tr>
            <tr>
              <td> اكسترا جامبو</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td> جامبو</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td> اكسترا لارج</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>لارج</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td> سوبريور</td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>برلينت</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td>فاين</td>
              <td> 321-350</td>
            </tr>
            <tr>
              <td>بولتس</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td> </td>
              <td> 381-420</td>
            </tr>
            <tr>
              <td></td>
              <td> 421-450</td>
            </tr>

            <tr>
              <td></td>
              <td> 451-500</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "4",
      text: (
        <p dir="rtl" lang="ar">
          هذا الصنف اسباني المنشأ وحقق هذا الصنف نجاحا كبيرا في زراعته في مصر
          تعد هذه النوعية الاكثر انتشار في مصر بعد الزيتون الاخضر العجيزي بسبب
          تنوع استخدامته وتتركز زراعته في منطقة طريق الاسماعيلية الصحراوي وطريق
          الاسكندرية الصحراوي . ويتميزهذا بمذاقه الاخص لاحتواءه على نسبة عالية
          من الزيت ويعامل هذ الصنف في مصنعنا بالطريقة الاسبانية والطريقة
          التقليدية (الماء والملح ) وهي معاملة الزيتون المعد للاكسدة ونقوم
          بتصنيعه ايضا وتقسيمه الى عدة انواع :
          <br />
          1. زيتون بيكوال أخضر سليم
          <br />
          2. زيتون بيكوال أخضر مخلي
          <br />
          3. زيتون بيكوال أخضر شرائح
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>وصف السلعة</th>
              <th>الحجم (المعايير اليونانية) </th>
              <th>قطع زيتون / كيلو جرام (معيار اسباني)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="12">زيتون بيكوال أخضر</th>
              <td> جانتس </td>
              <td>141-160</td>
            </tr>
            <tr>
              <td> اكسترا جامبو</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td> جامبو</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td> اكسترا لارج</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>لارج</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td> سوبريور</td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>برلينت</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td>فاين</td>
              <td> 321-350</td>
            </tr>
            <tr>
              <td>بولتس</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td> </td>
              <td> 381-420</td>
            </tr>
            <tr>
              <td></td>
              <td> 421-450</td>
            </tr>

            <tr>
              <td></td>
              <td> 451-500</td>
            </tr>
          </tbody>
        </table>
      ),
    },
  ];
  function showImage(x) {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-none", "d-flex");
    document.querySelector(".very").style.backgroundImage = `url(${x.src})`;
    document.querySelector(".productName").innerHTML = x.name;

    currentIndex = x.id;
    setCurrID(currentIndex);
  }

  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }
  function showForm() {
    document
      .querySelector(".showForm")
      .classList.replace("d-none", "d-inline-block");
  }
  return (
    <>
      <ToastContainer />

      <div className="products" onClick={closeSlide}></div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="1"
                src={image1}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("زيتون عجيزي");
                }}
                alt="image not found"
                className="photo"
                name="زيتون عجيزي"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              زيتون عجيزي{" "}
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="2"
                src={image2}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("أنصاف أخضر");
                }}
                alt="image not found"
                className="photo"
                name="أنصاف أخضر"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>أنصاف أخضر </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="3"
                src={image3}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("زيتون مانزانيلا");
                }}
                alt="image not found"
                className="photo"
                name="زيتون مانزانيلا"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              زيتون مانزانيلا{" "}
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="4"
                src={image4}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("زيتون بيكوال");
                }}
                alt="image not found"
                className="photo"
                name="زيتون بيكوال"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              زيتون بيكوال{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="lightContainer2 d-none  align-items-center">
        <div className="lightBox2  ">
          <div className="container">
            <div className="row d-flex justify-content-center ">
              <div className="ayhaga" style={{ marginTop: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img className=" very" alt="" style={{ padding: "0" }} />
                </div>
                <h4
                  className="productName"
                  style={{
                    color: "#4e732c",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></h4>
                {tmp.map((each) => {
                  if (each.id === currID) {
                    return (
                      <div>
                        <p style={{ color: "white" }}> {each.text} </p>
                        <div>{each.table}</div>
                      </div>
                    );
                  }
                })}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <button
                    className="btn get bgDark"
                    style={{ marginBottom: "5px" }}
                    onClick={showForm}
                  >
                    احصل علي عرض الاسعار
                  </button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <form onSubmit={sendQuote}>
                    <div className="showForm d-none ">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="ادخل اسمك"
                        style={{ marginBottom: "5px" }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="ادخل البريد الالكتروني"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px",
                        }}
                      >
                        <button
                          className="btn get"
                          style={{ marginTop: "5px" }}
                          type="submit"
                        >
                          {loading ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : (
                            "ارسال"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <i
            style={{
              color: "black",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
            id="close"
            className="fa-solid fa-xmark"
            onClick={closeSlide}
          ></i>
        </div>
      </div>

      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div className="contactContect">
                  <h6 style={{ display: "flex", justifyContent: "end" }}>
                    {" "}
                    اتصل بنا
                  </h6>

                  <p>عندما يكون لديك سؤال فنحن هنا للمساعدة</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}

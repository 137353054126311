import React from "react";
import "./Products.css";
import { Link } from "react-router-dom";
import contact from "../Images/Group-of-customer-service-representatives.jpg";

export default function ProductCatRuss() {
  return (
    <>
      <div className="products"></div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-4 ">
            <Link to="/products/subCategory1">
              {" "}
              <div className="  pickled"> </div>{" "}
            </Link>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              {" "}
              целые зеленые оливки
            </p>
          </div>
          <div className="col-md-4 ">
            <Link to="/products/subCategory2">
              {" "}
              <div className="  pitted"> </div>
            </Link>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              зеленые оливки без косточек
            </p>
          </div>
          <div className="col-md-4 ">
            <Link to="/products/subCategory ">
              {" "}
              <div className=" Slices "> </div>
            </Link>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Ломтики зеленых оливок
            </p>
          </div>
          <div className="col-md-4 ">
            <Link to="/products/productStuffed">
              {" "}
              <div className=" stuffed "> </div>
            </Link>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              фаршированные зеленые оливки
            </p>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="contactContect">
                  <h6> свяжитесь с нами</h6>

                  <p>когда у вас есть вопрос, мы здесь, чтобы помочь</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}

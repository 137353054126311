import React from "react";
import "./Packages.css";
import contact from "../Images/Group-of-customer-service-representatives.jpg";
import image1 from "../Images/Mr.Olive/packages/Glass/_KH13145.jpg";
import image2 from "../Images/Mr.Olive/packages/Metal/jpg web/4.jpg";
import image3 from "../Images/Mr.Olive/packages/plastic/Bucket 12 Litres.jpg";

import { Link } from "react-router-dom";

export default function PackagesSpain() {
  return (
    <>
      <div className="packages ">
        <div className="container" style={{ height: "100%" }}>
          <div className="row" style={{ height: "100%" }}>
            <div
              className="col-md-12 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <h1 className="text-black">paquetes</h1>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container P-4">
        <div className="row  p-4">
          <div className="col-md-4 ">
            <div className=" imgItem">
              <Link to="/products/subCategory6">
                <img
                  id="3"
                  src={image1}
                  alt="image not found"
                  className="packagaya"
                />{" "}
              </Link>{" "}
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>Vidrio</p>
          </div>
          <div className="col-md-4 ">
            <div className=" imgItem">
              <Link to="/products/subCategory7">
                <img
                  id="3"
                  src={image2}
                  alt="image not found"
                  className="packagaya"
                />
              </Link>{" "}
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>Metal</p>
          </div>
          <div className="col-md-4 ">
            <div className=" imgItem">
              <Link to="/products/subCategory8">
                <img
                  id="3"
                  src={image3}
                  alt="image not found"
                  className="packagaya"
                />
              </Link>{" "}
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}> plástico </p>
          </div>
        </div>
      </div>
    </>
  );
}

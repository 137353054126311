import React from "react";
import "./AboutUs.css";
import contact from "../Images/Group-of-customer-service-representatives.jpg";
import oliveOilAbout from "../Images/Mr.Olive/packages/_KH13115.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Certificate1 from "../Images/Mr.Olive/Certificate/Certificate 1.jpg";
import Certificate2 from "../Images/Mr.Olive/Certificate/Certificate 2.jpg";
import Certificate3 from "../Images/Mr.Olive/Certificate/Certificate 3.jpg";
export default function AboutUsItaly() {
  let Certificates = [
    { img: Certificate1 },
    { img: Certificate2 },
    { img: Certificate3 },
  ];

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  return (
    <>
      <div className="aboutUs ">
        <div className="container" style={{ height: "100%" }}>
          <div className="row" style={{ height: "100%" }}>
            <div
              className="col-md-12 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
                // style={{position:'relative'}}
              >
                <h1
                  // style={{position:'absolute' , top:'130px', left:'230px'}}
                  className="text-white"
                >
                  chi siamo
                </h1>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container p-3">
        <div className="row p-3">
          <div className="col-md-8">
            <h4 style={{ color: "#4e732c" }}>Settore agricolo:</h4>
            <p>
              &emsp;  Mr. Olive ha iniziato nel campo della coltivazione
              dell'olivo in 1986 sulla strada del deserto Cairo-Alessandria, in
              particolare nella zona (Al-Khatatba - Abu Ghaleb - International
              Road a El Alamein). Oggi la superficie coltivata ha raggiunto i
              1.000 ettari di olive di vario genere, dove vengono utilizzati
              metodi moderni per l'irrigazione, la concimazione ed evitando
              eventuali tracce di pesticidi nella coltura.
            </p>
            <h4 style={{ color: "#4e732c" }}>Settore manifatturiero:</h4>
            <p>
              {" "}
              &emsp; L'azienda nasce come società solidale nel 1998 nel settore
              della produzione e lavorazione delle olive da tavola. Il processo
              di produzione e produzione viene eseguito utilizzando le più
              recenti linee di produzione e confezionamento in conformità con
              gli standard internazionali. L'azienda è diventata un pioniere nel
              campo della produzione e commercializzazione di prodotti a livello
              locale e globale, con una capacità produttiva di 10,000 tonnellate
              di olive. L'azienda ha teso ad aggiungere altri prodotti come
              peperoni, verdure, sottaceti e carciofi sottaceto.
            </p>
            <h4 style={{ color: "#4e732c" }}>Obiettivo dell'azienda:</h4>
            <p>
              {" "}
              &emsp; Il nostro obiettivo è fornire standard e specifiche
              internazionali di qualità ai nostri clienti in tutto il mondo,
              espandendoci nell'apertura di nuovi mercati e aumentando gli
              investimenti.
            </p>
          </div>
          <div className="col-md-4 ">
            <img src={oliveOilAbout} className="oliveOilAbout" alt="" />
          </div>
        </div>
      </div>
      <div className="container mb-3">
        <Slider {...settings}>
          {Certificates.map((certificate, index) => (
            <div key={index}>
              <img
                className="w-75"
                src={certificate.img}
                alt={`Certificate ${index + 1}`}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="contactContect">
                  <h6> contattaci</h6>

                  <p>quando hai una domanda siamo qui per aiutarti</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}

import React, { useEffect } from "react";
import "./PhotoGallery.css";
import image1 from "../Images/Mr.Olive/photoGallery/1.jpg";
import image2 from "../Images/Mr.Olive/photoGallery/2.jpg";
import image3 from "../Images/Mr.Olive/photoGallery/3.jpg";
import image4 from "../Images/Mr.Olive/photoGallery/4.jpg";
import image5 from "../Images/Mr.Olive/photoGallery/5.jpg";
import image6 from "../Images/Mr.Olive/photoGallery/6.jpg";
import image7 from "../Images/Mr.Olive/photoGallery/7.jpg";
import image8 from "../Images/Mr.Olive/photoGallery/8.jpg";
import image9 from "../Images/Mr.Olive/photoGallery/9.jpg";
import image10 from "../Images/Mr.Olive/photoGallery/10.jpg";
import image11 from "../Images/Mr.Olive/photoGallery/11.jpg";
import image12 from "../Images/Mr.Olive/photoGallery/12.jpg";
import image13 from "../Images/Mr.Olive/photoGallery/13.jpg";
import image14 from "../Images/Mr.Olive/photoGallery/14.jpg";
import image15 from "../Images/Mr.Olive/photoGallery/15.jpg";
import image16 from "../Images/Mr.Olive/photoGallery/16.jpg";
import image17 from "../Images/Mr.Olive/photoGallery/17.jpg";
import image18 from "../Images/Mr.Olive/photoGallery/18.jpg";
import image19 from "../Images/Mr.Olive/photoGallery/19.jpg";
import image20 from "../Images/Mr.Olive/photoGallery/20.jpg";
import image21 from "../Images/Mr.Olive/photoGallery/21.jpg";
import image22 from "../Images/Mr.Olive/photoGallery/22.jpg";
import image23 from "../Images/Mr.Olive/photoGallery/23.jpg";
import image24 from "../Images/Mr.Olive/photoGallery/24.jpg";
import image25 from "../Images/Mr.Olive/photoGallery/25.jpg";
import image26 from "../Images/Mr.Olive/photoGallery/26.jpg";
import image27 from "../Images/Mr.Olive/photoGallery/27.jpg";
import image28 from "../Images/Mr.Olive/photoGallery/28.jpg";

import contact from "../Images/Group-of-customer-service-representatives.jpg";

export default function PhotoGallerySpain() {
  let imgList = Array.from(document.getElementsByClassName("imgItem"));
  let lightContainer = document.querySelector(".lightContainer");
  let lightBox = document.querySelector(".lightBox");
  let currentIndex = 0;
  // console.log(lightContainer);

  function showImage(x) {
    document
      .querySelector(".lightContainer")
      .classList.replace("d-none", "d-flex");
    // console.log("ayhagaaasa")
    document.querySelector(".lightBox").style.backgroundImage = `url(${x.src})`;
    currentIndex = x.id;
    //  console.log(currentIndex)
  }

  function nextSlide() {
    currentIndex++;
    console.log(currentIndex);

    if (currentIndex == 28) {
      currentIndex = 0;
    }

    let imgSrc = document
      .getElementById(JSON.stringify(currentIndex))
      .getAttribute("src");
    document.querySelector(
      ".lightBox"
    ).style.backgroundImage = `url(${imgSrc})`;
  }

  {
    /*--------------------------------------- L button bta3 l prev-----------------------------*/
  }

  function prevSlide() {
    //  console.log(currentIndex)
    if (currentIndex == 0) {
      currentIndex = 28;
    }
    currentIndex--;
    let imgSrc = document
      .getElementById(JSON.stringify(currentIndex))
      .getAttribute("src");
    document.querySelector(
      ".lightBox"
    ).style.backgroundImage = `url(${imgSrc})`;
  }
  {
    /*--------------------------------------- L button bta3 l close-----------------------------*/
  }

  function closeSlide() {
    document
      .querySelector(".lightContainer")
      .classList.replace("d-flex", "d-none");
  }

  return (
    <>
      <div className="gallery "></div>
      <div className="container p-4">
        <div className="row p-4 ">
          <div className="col-md-3 photoContainer">
            <div className="  imgItem">
              <img
                id="0"
                src={image1}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className="  imgItem">
              <img
                id="1"
                src={image2}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className=" imgItem">
              <img
                id="2"
                src={image3}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className="  imgItem">
              <img
                id="3"
                src={image4}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
        </div>
        <div className="row  p-4">
          <div className="col-md-3 photoContainer">
            <div className="  imgItem">
              <img
                id="4"
                src={image5}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className=" imgItem">
              <img
                id="5"
                src={image6}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className="  imgItem">
              <img
                id="6"
                src={image7}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className=" imgItem">
              <img
                id="7"
                src={image8}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
        </div>
        <div className="row  p-4">
          <div className="col-md-3 photoContainer">
            <div className=" imgItem">
              {" "}
              <img
                id="8"
                src={image9}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className="  imgItem">
              {" "}
              <img
                id="9"
                src={image10}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className="  imgItem">
              {" "}
              <img
                id="10"
                src={image11}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className=" imgItem">
              {" "}
              <img
                id="11"
                src={image12}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
        </div>
        <div className="row  p-4">
          <div className="col-md-3 photoContainer">
            <div className=" imgItem">
              {" "}
              <img
                id="12"
                src={image13}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className="  imgItem">
              {" "}
              <img
                id="13"
                src={image14}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className="  imgItem">
              {" "}
              <img
                id="14"
                src={image15}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className=" imgItem">
              {" "}
              <img
                id="15"
                src={image16}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
        </div>
        <div className="row  p-4">
          <div className="col-md-3 photoContainer">
            <div className=" imgItem">
              {" "}
              <img
                id="16"
                src={image17}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className="  imgItem">
              {" "}
              <img
                id="17"
                src={image18}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className="  imgItem">
              {" "}
              <img
                id="18"
                src={image19}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className=" imgItem">
              {" "}
              <img
                id="19"
                src={image20}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
        </div>
        <div className="row  p-4">
          <div className="col-md-3 photoContainer">
            <div className=" imgItem">
              {" "}
              <img
                id="20"
                src={image21}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className="  imgItem">
              {" "}
              <img
                id="21"
                src={image22}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className="  imgItem">
              {" "}
              <img
                id="22"
                src={image23}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className=" imgItem">
              {" "}
              <img
                id="23"
                src={image24}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
        </div>
        <div className="row  p-4">
          <div className="col-md-3 photoContainer">
            <div className=" imgItem">
              {" "}
              <img
                id="24"
                src={image25}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className="  imgItem">
              {" "}
              <img
                id="25"
                src={image26}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className="  imgItem">
              {" "}
              <img
                id="26"
                src={image27}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
          <div className="col-md-3 photoContainer">
            <div className=" imgItem">
              {" "}
              <img
                id="27"
                src={image28}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="specificPhoto"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="lightContainer d-none justify-content-center align-items-center">
        <div className="lightBox d-flex justify-content-between align-items-center ">
          {/* <img src={image1} alt="image not found" className='lightBox'/> */}
          <div className="buttonContainer" onClick={prevSlide}>
            <i className="fa-solid fa-chevron-left"></i>
          </div>
          <i
            style={{ color: "red" }}
            id="close"
            className="fa-solid fa-xmark"
            onClick={closeSlide}
          ></i>

          <div className="buttonContainer" onClick={nextSlide}>
            {" "}
            <i className="fa-solid fa-chevron-right"></i>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="contactContect">
                  <h6> CONTACT US</h6>

                  <p>when you have a question we're here to help</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}

import React from "react";
import image1 from "../Images/Mr.Olive//packages/Glass/Glass Jar 370 Ml (2).jpg";
import image2 from "../Images/Mr.Olive//packages/Glass/Glass Jar 370 Ml.jpg";
import image3 from "../Images/Mr.Olive//packages/Glass/Glass Jar 720 Ml.jpg";
import image4 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (1).jpg";
import image5 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (3).jpg";
import image6 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (4).jpg";
import image7 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (5).jpg";
import image8 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (6).jpg";
import image9 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (7).jpg";
import image10 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (8).jpg";
import image11 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (9).jpg";
import image12 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (10).jpg";
import image13 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (11).jpg";
import image14 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (12).jpg";
import image15 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (13).jpg";
import image16 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml (14).jpg";
import image17 from "../Images/Mr.Olive/packages/Glass/Glass Jar 1000 Ml.jpg";
import image18 from "../Images/Mr.Olive/packages/Glass/فلفل مخلل.jpg";

import contact from "../Images/Group-of-customer-service-representatives.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  EffectCube,
  EffectFlip,
  Autoplay,
  EffectCoverflow,
} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import "swiper/css/effect-cube";

import "swiper/css/navigation";
import "swiper/css/effect-fade";
export default function SubCategory6() {


  let imgList = Array.from(document.getElementsByClassName("imgItem"));
  let lightContainer = document.querySelector(".lightContainer");
  let lightBox = document.querySelector(".lightBox");
  let currentIndex = 0;
  console.log(lightContainer);

  function showImage(x) {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-none", "d-flex");
    // console.log("ayhagaaasa")
    document.querySelector(".very").style.backgroundImage = `url(${x.src})`;
    document.querySelector(".productName").innerHTML = x.name;

    currentIndex = x.id;
    // console.log(currentIndex)
  }

  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }
  function showForm() {
    document
      .querySelector(".showForm")
      .classList.replace("d-none", "d-inline-block");
  }
  return (
    <>
      <div className="products " onClick={closeSlide}>


      </div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-6">
            <table class="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col" dir="rtl" lang="ar">
                    اسم العبوة
                  </th>
                  <th scope="col" dir="rtl" lang="ar">
                    العناصر الموجودة داخل العبوة
                  </th>
                  <th scope="col" dir="rtl" lang="ar">
                    عدد البرطمانات في الكرتون الواحد
                  </th>
                  <th scope="col" dir="rtl" lang="ar">
                    الوزن الصافي لكل علبة / كجم
                  </th>
                  <th scope="col" dir="rtl" lang="ar">
                    الوزن الإجمالي للكرتون / كجم
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" rowspan="4">
                    370 مل
                  </th>
                  <td dir="rtl" lang="ar">
                    {" "}
                    زيتون سليم{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    24 برطمان
                  </td>
                  <td dir="rtl" lang="ar">
                    0.200 كجم
                  </td>
                  <td dir="rtl" lang="ar">
                    13.920 كجم
                  </td>
                </tr>
                <tr>
                  <td dir="rtl" lang="ar">
                    {" "}
                    زيتون مخلي وشرائح ومحشو
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    24 برطمان
                  </td>
                  <td dir="rtl" lang="ar">
                    0.180 كجم{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    13.920 كجم{" "}
                  </td>
                </tr>
                <tr>
                  <td dir="rtl" lang="ar">
                    {" "}
                    خرشوف
                  </td>
                  <td dir="rtl" lang="ar">
                    24 برطمان{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    0.180 كجم
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    13.920 كجم
                  </td>
                </tr>
                <tr>
                  <td dir="rtl" lang="ar">
                    {" "}
                    فلفل
                  </td>
                  <td dir="rtl" lang="ar">
                    24 برطمان{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    0.180 كجم{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    13.920 كجم{" "}
                  </td>
                </tr>
                {/**---------------------------- */}
                <tr>
                  <th scope="row" dir="rtl" lang="ar" rowspan="4">
                    720 مل
                  </th>
                  <td dir="rtl" lang="ar">
                    {" "}
                    زيتون سليم{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    12 برطمان
                  </td>
                  <td dir="rtl" lang="ar">
                    0.420 كجم
                  </td>
                  <td dir="rtl" lang="ar">
                    12.00 كجم
                  </td>
                </tr>
                <tr>
                  <td dir="rtl" lang="ar">
                    {" "}
                    زيتون مخلي وشرائح ومحشو
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    12 برطمان
                  </td>
                  <td dir="rtl" lang="ar">
                    0.360 كجم{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    12.00 كجم{" "}
                  </td>
                </tr>
                <tr>
                  <td dir="rtl" lang="ar">
                    {" "}
                    خرشوف
                  </td>
                  <td dir="rtl" lang="ar">
                    12 برطمان{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    0.420 كجم
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    12.00 كجم
                  </td>
                </tr>
                <tr>
                  <td dir="rtl" lang="ar">
                    {" "}
                    فلفل
                  </td>
                  <td dir="rtl" lang="ar">
                    12 برطمان{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    0.360 كجم{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    12.00 كجم{" "}
                  </td>
                </tr>
                <tr>
                  <th scope="row" dir="rtl" lang="ar" rowspan="4">
                    1000 مل
                  </th>
                  <td dir="rtl" lang="ar">
                    {" "}
                    زيتون سليم{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    6 برطمان
                  </td>
                  <td dir="rtl" lang="ar">
                    0.650 كجم
                  </td>
                  <td dir="rtl" lang="ar">
                    8.880 كجم
                  </td>
                </tr>
                <tr>
                  <td dir="rtl" lang="ar">
                    {" "}
                    زيتون مخلي وشرائح ومحشو
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    6 برطمان
                  </td>
                  <td dir="rtl" lang="ar">
                    0.550 كجم{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    8.880 كجم{" "}
                  </td>
                </tr>
                <tr>
                  <td dir="rtl" lang="ar">
                    {" "}
                    خرشوف
                  </td>
                  <td dir="rtl" lang="ar">
                    6 برطمان{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    0.650 كجم
                  </td>
                  <td dir="rtl" lang="ar">
                    {" "}
                    8.880 كجم
                  </td>
                </tr>
                <tr>
                  <td dir="rtl" lang="ar">
                    {" "}
                    فلفل
                  </td>
                  <td dir="rtl" lang="ar">
                    6 برطمان{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    0.550 كجم{" "}
                  </td>
                  <td dir="rtl" lang="ar">
                    8.880 كجم{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="col-md-6"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Swiper
              // install Swiper modules
              modules={[
                Navigation,
                Pagination,
                Autoplay,
                EffectFade,
                EffectFlip,
                EffectCoverflow,
                EffectCube,
              ]}
              spaceBetween={50}
              slidesPerView={2}
              navigation
              autoplay={true}
              loop={true}
            >
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {" "}
                <figure>
                  {" "}
                  <img src={image1} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان 370 مل (2)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image2} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان 370 مل</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image3} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان 720 مل</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {" "}
                <figure>
                  {" "}
                  <img src={image4} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان 1000 مل (1)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image5} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان 1000 مل (3)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image6} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان 1000 مل (4)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image7} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان 1000 مل (5)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image8} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان 1000 مل (6)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image9} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان 1000 مل (7)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image10} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان 1000 مل (8)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image11} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان 1000 مل (9)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image12} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان 1000 مل (10)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image13} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان 1000 مل (11)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image14} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان 1000 مل (12)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image15} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان 1000 مل (13)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image16} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان 1000 مل (14)</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image17} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان 1000 مل</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image18} alt="" style={{ width: "100%" }} />
                  <figcaption>زجاج برطمان </figcaption>
                </figure>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import image1 from "../Images/Mr.Olive/packages/Metal/jpg web/1.jpg";
import image2 from "../Images/Mr.Olive/packages/Metal/jpg web/2.jpg";
import image3 from "../Images/Mr.Olive/packages/Metal/jpg web/3.jpg";
import image4 from "../Images/Mr.Olive/packages/Metal/jpg web/4.jpg";
import image5 from "../Images/Mr.Olive/packages/Metal/jpg web/5.jpg";
import image6 from "../Images/Mr.Olive/packages/Metal/jpg web/6.jpg";
import image7 from "../Images/Mr.Olive/packages/Metal/jpg web/7.jpg";
import image8 from "../Images/Mr.Olive/packages/Metal/jpg web/8.jpg";
import image9 from "../Images/Mr.Olive/packages/Metal/jpg web/9.jpg";
import image10 from "../Images/Mr.Olive/packages/Metal/jpg web/10.jpg";
import image11 from "../Images/Mr.Olive/packages/Metal/jpg web/11.jpg";
import image12 from "../Images/Mr.Olive/packages/Metal/jpg web/12.jpg";
import image13 from "../Images/Mr.Olive/packages/Metal/jpg web/13.jpg";
import image14 from "../Images/Mr.Olive/packages/Metal/jpg web/14.jpg";
import image15 from "../Images/Mr.Olive/packages/Metal/jpg web/15.jpg";
import image16 from "../Images/Mr.Olive/packages/Metal/jpg web/16.jpg";
import image17 from "../Images/Mr.Olive/packages/Metal/jpg web/17.jpg";
import image18 from "../Images/Mr.Olive/packages/Metal/jpg web/زيتون اخضر سليم صفيح.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  EffectCube,
  EffectFlip,
  Autoplay,
  EffectCoverflow,
} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import "swiper/css/effect-cube";

import "swiper/css/navigation";
import "swiper/css/effect-fade";
export default function SubCategory7Russ() {
  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }

  return (
    <>
      <div className="products " onClick={closeSlide}>
     
      </div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-6">
            <table class="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">Название пакета</th>
                  <th scope="col">Предметы внутри упаковки</th>
                  <th scope="col">Количество банок в одной коробке</th>
                  <th scope="col">Сухой вес банки/кг</th>
                  <th scope="col">Вес брутто в коробке/кг</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">420 мл</th>
                  <td style={{ padding: "0" }}> Все продукты </td>
                  <td style={{ padding: "0" }}>24 Можно</td>
                  <td>0.170 КГ</td>
                  <td>11.520 КГ</td>
                </tr>
                {/**------------------------ */}
                <tr>
                  <th scope="row" rowspan="2">
                    99
                  </th>
                  <td style={{ padding: "0" }}>Целые оливки, Артишок </td>
                  <td style={{ padding: "0" }}> 12 Можно </td>
                  <td>0.465 КГ </td>
                  <td>12 КГ </td>
                </tr>
                <tr>
                  <td> Нарезанные, оливки без косточек, халапеньо </td>
                  <td> 12 Можно </td>
                  <td>0.380 КГ </td>
                  <td>12 КГ </td>
                </tr>

                {/**--------------------------- */}
                <tr>
                  <th scope="row" rowspan="4">
                    A9
                  </th>
                  <td style={{ padding: "0" }}> Целые оливки </td>
                  <td style={{ padding: "0" }}> 6 Можно</td>
                  <td>1.400 КГ</td>
                  <td>18.00 КГ</td>
                </tr>
                <tr>
                  <td> Нарезанные, оливки без косточек, халапеньо</td>
                  <td> 6 Можно</td>
                  <td>1.250 КГ </td>
                  <td>18.00 КГ</td>
                </tr>
                <tr>
                  <td> Артишок</td>
                  <td>6 Можно</td>
                  <td> 1.400 КГ</td>
                  <td>18.00 КГ</td>
                </tr>
                <tr>
                  <td> перец</td>
                  <td>6 Можно</td>
                  <td>1.250 КГ </td>
                  <td>18.00 КГ</td>
                </tr>
                {/**---------------------------- */}
                <tr>
                  <th scope="row" rowspan="4">
                    A10
                  </th>
                  <td style={{ padding: "0" }}> Целые оливки </td>
                  <td style={{ padding: "0" }}> 6 Можно</td>
                  <td>1.800 КГ</td>
                  <td>20.00 КГ</td>
                </tr>
                <tr>
                  <td> Нарезанные, оливки без косточек, халапеньо</td>
                  <td> 6 Можно</td>
                  <td>1.500 КГ</td>
                  <td>20.00 КГ</td>
                </tr>
                <tr>
                  <td> Артишок</td>
                  <td>6 Можно</td>
                  <td> 1.500 КГ</td>
                  <td>20.00 КГ</td>
                </tr>
                <tr>
                  <td> перец</td>
                  <td>6 Можно</td>
                  <td>1.500 КГ</td>
                  <td>20.00 КГ</td>
                </tr>
                {/**--------------------------------- */}
                <tr>
                  <th scope="row" rowspan="4">
                    A12
                  </th>
                  <td style={{ padding: "0" }}> Целые оливки </td>
                  <td style={{ padding: "0" }}> 4 Можно</td>
                  <td>2.500 КГ</td>
                  <td>20.00 КГ</td>
                </tr>
                <tr>
                  <td> Нарезанные, оливки без косточек, халапеньо</td>
                  <td> 4 Можно</td>
                  <td>2.00 КГ </td>
                  <td>20.00 КГ</td>
                </tr>
                <tr>
                  <td> Артишок</td>
                  <td>4 Можно</td>
                  <td> 2.500 КГ</td>
                  <td>20.00 КГ</td>
                </tr>
                <tr>
                  <td> перец</td>
                  <td>4 Можно</td>
                  <td>2.00 КГ </td>
                  <td>20.00 КГ</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="col-md-6"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Swiper
              // install Swiper modules
              modules={[
                Navigation,
                Pagination,
                Autoplay,
                EffectFade,
                EffectFlip,
                EffectCoverflow,
                EffectCube,
              ]}
              spaceBetween={50}
              slidesPerView={2}
              navigation
              autoplay={true}
              loop={true}
            >
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {" "}
                <figure>
                  {" "}
                  <img src={image1} alt="" style={{ width: "100%" }} />
                  <figcaption>Цельная зеленая оливка</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image2} alt="" style={{ width: "100%" }} />
                  <figcaption>Цельная черная оливка</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image3} alt="" style={{ width: "100%" }} />
                  <figcaption>Цельная черная оливка Каламата</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {" "}
                <figure>
                  {" "}
                  <img src={image4} alt="" style={{ width: "100%" }} />
                  <figcaption>Нарезанный красный перец халапеньо</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image5} alt="" style={{ width: "100%" }} />
                  <figcaption>Нарезанная зеленая оливка</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image6} alt="" style={{ width: "100%" }} />
                  <figcaption>Нарезанный зеленый перец халапеньо</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image7} alt="" style={{ width: "100%" }} />
                  <figcaption>Нарезанная черная оливка</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {" "}
                <figure>
                  {" "}
                  <img src={image8} alt="" style={{ width: "100%" }} />
                  <figcaption>Зеленая оливка без косточек</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image9} alt="" style={{ width: "100%" }} />
                  <figcaption>Черная оливка без косточек</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image10} alt="" style={{ width: "100%" }} />
                  <figcaption>Маринованные овощи</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {" "}
                <figure>
                  {" "}
                  <img src={image11} alt="" style={{ width: "100%" }} />
                  <figcaption>Маринованный перец</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image12} alt="" style={{ width: "100%" }} />
                  <figcaption>Маринованный лук</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image13} alt="" style={{ width: "100%" }} />
                  <figcaption>Огурцы маринованные</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image14} alt="" style={{ width: "100%" }} />
                  <figcaption>Половинки зеленых оливок</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {" "}
                <figure>
                  {" "}
                  <img src={image15} alt="" style={{ width: "100%" }} />
                  <figcaption>Четвертинки артишока</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image16} alt="" style={{ width: "100%" }} />
                  <figcaption>Сердце Артишока</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image17} alt="" style={{ width: "100%" }} />
                  <figcaption>
                    Зеленые оливки, фаршированные красным перцем
                  </figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {" "}
                <figure>
                  {" "}
                  <img src={image18} alt="" style={{ width: "100%" }} />
                  <figcaption>Цельная зеленая оливка</figcaption>
                </figure>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

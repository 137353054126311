import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image1 from "../Images/Mr.Olive/BlackOlive/زيتون أسود مؤكسد سليم.jpg";
import image2 from "../Images/Mr.Olive/BlackOlive/زيتون بيكوال أسود طبيعي سليم.jpg";
import image3 from "../Images/Mr.Olive/BlackOlive/زيتون دولسي أسود طبيعي سليم.jpg";
import image4 from "../Images/Mr.Olive/BlackOlive/زيتون سيرس كولا أسود سليم.jpg";
import image5 from "../Images/Mr.Olive/BlackOlive/زيتون كالاماتا أسود طبيعي سليم.jpg";
import image6 from "../Images/Mr.Olive/BlackOlive/زيتون منزانلو أسود  طبيعي سليم.jpg";
import contact from "../Images/Group-of-customer-service-representatives.jpg";

export default function SubCategory4Spain() {
  const [currID, setCurrID] = useState("");
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  async function sendQuote(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `https://mrolive-back-end-2c3o.onrender.com/api/v1/quote`,
        {
          name: name,
          email: email,
          productName: productName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.msg);
        setName("");
        setEmail("");
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
      })
      .catch((e) => {
        toast.error(e?.data?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let imgList = Array.from(document.getElementsByClassName("imgItem"));
  let lightContainer = document.querySelector(".lightContainer");
  let lightBox = document.querySelector(".lightBox");
  let currentIndex = 0;
  let tmp = [
    {
      id: "1",
      text: (
        <p>
          El olivo Picula es el más extendido en los gastos de las fincas de
          nuestra empresa, después de las aceitunas Ajizi. Las aceitunas Picual
          negras naturales se distinguen por sus grandes tamaños y atractivo
          color violeta. Los fabricamos en nuestra fábrica utilizando métodos
          saludables que preservan el valor nutricional de la aceituna, y además
          la clasificamos en 3 tipos que son: <br />
          1. Aceitunas Picual negras enteras naturales <br />
          2. Aceitunas Picual negras sin hueso natural <br />
          3. Rodajas de aceituna Picual negra natural
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descripción del artículo</th>
              <th>Tamaño(Normas griegas)</th>
              <th>Trozos de aceitunas/KG(Estándar español)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="13"> Aceitunas Picual Negras Naturales</th>
              <td> Mamut </td>
              <td>101-110</td>
            </tr>
            <tr>
              <td>Súper colosal</td>
              <td> 111-120</td>
            </tr>
            <tr>
              <td> colosal</td>
              <td>121-141</td>
            </tr>
            <tr>
              <td> Gigantes</td>
              <td>141-160</td>
            </tr>
            <tr>
              <td>Extra Jumbo</td>
              <td>161-180</td>
            </tr>
            <tr>
              <td> Jumbo</td>
              <td> 181-200</td>
            </tr>
            <tr>
              <td>Extra Grande</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>Grande</td>
              <td> 231-260</td>
            </tr>
            <tr>
              <td>Superior</td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>Briliante </td>
              <td> 291-320</td>
            </tr>
            <tr>
              <td>Multa</td>
              <td> 321-350</td>
            </tr>

            <tr>
              <td>Balas</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td></td>
              <td>381-400</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "2",
      text: (
        <p>
          Esta variedad se llama Kalamata egipcia, y el árbol dulci es uno de
          los árboles más productivos, y este árbol se caracteriza por su
          adaptación a los cambios climáticos. Esta calidad es muy demandada en
          el mercado local, mercados árabes y algunos mercados europeos. El
          dulse negro natural se distingue por su forma única, su atractivo
          color violeta y su abundancia en aceite, que le da un sabor delicioso,
          y lo fabricamos en nuestra fábrica utilizando métodos saludables que
          preservan el valor nutricional de la aceituna.
        </p>
      ),

      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descripción del artículo</th>
              <th>Trozos de aceitunas/KG(Estándar español)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="6">Aceitunas Dolci Negras Naturales</th>
              <td>201-250 </td>
            </tr>
            <tr>
              <td>251-300</td>
            </tr>
            <tr>
              <td> 301-350</td>
            </tr>
            <tr>
              <td> 351-400 </td>
            </tr>
            <tr>
              <td>401-450</td>
            </tr>
            <tr>
              <td> 451-500</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "3",
      text: (
        <p>
          Este tipo es uno de los tipos más abundantes de aceite de oliva, y
          esta calidad se ha utilizado con gran éxito en el decapado y se ha
          convertido en uno de los tipos más importantes en demanda de los
          mercados mundiales durante los últimos años. Nuestra empresa ha
          destinado muchas áreas para expandir el cultivo de esta calidad. Esta
          calidad es muy demandada en el mercado local, Mercados árabes y
          algunos de los mercados La cola fermentada negra natural con su forma
          única, atractivo color violeta y su abundancia en aceite, que le da un
          sabor delicioso, y la fabricamos en nuestra fábrica utilizando métodos
          saludables que preservan el valor nutricional de la aceituna y también
          la clasificamos en <br />
          1- Aceitunas fermentadas de cola negra natural <br />
          2- Aceitunas cirus negras fermentadas naturales <br />
          3- Rodajas de aceituna de cola sarsa negra natural <br />
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descripción del artículo</th>
              <th>Tamaño(Normas griegas)</th>
              <th>Trozos de aceitunas/KG(Estándar español)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="10"> Aceitunas Sersecola Naturales Negras</th>
              <td> Jumbo </td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Extra Grande</td>
              <td> 201-230</td>
            </tr>
            <tr>
              <td> Grande</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td>Superior</td>
              <td>261-290</td>
            </tr>
            <tr>
              <td>Briliante</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td> Multa</td>
              <td>321-350</td>
            </tr>
            <tr>
              <td>Balas</td>
              <td>351-380</td>
            </tr>
            <tr>
              <td></td>
              <td>381-400</td>
            </tr>
            <tr>
              <td></td>
              <td>401-450</td>
            </tr>
            <tr>
              <td> </td>
              <td>451-500</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "4",
      text: (
        <p>
          Esta variedad fue nombrada Kalamata debido a su cultivo en la ciudad
          de Kalamata, Grecia, el origen de esta calidad original. Las fincas de
          nuestra empresa han asignado áreas designadas para el cultivo de este
          tipo (Kalamata) para la autosuficiencia y para satisfacer las
          necesidades de nuestros clientes a nivel local y global. El kalamata
          negro natural se distingue por su forma única, su atractivo color
          púrpura y su abundancia en aceite que le da un sabor delicioso. La
          fabricamos en nuestra fábrica utilizando métodos saludables que
          preservan el valor nutricional de la aceituna, y además la
          clasificamos en 3 tipos que son: <br />
          1. Aceitunas kalamta negras enteras naturales <br />
          2. Aceitunas Kalamata negras sin hueso natural <br />
          3. Aceitunas kalamata negras naturales en rodajas <br />
          4. Aceitunas Kalamata negras medio naturales
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descripción del artículo</th>
              <th>Tamaño(Normas griegas)</th>
              <th>Trozos de aceitunas/KG(Estándar español)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="11">Aceitunas Kalamata naturales negras</th>
              <td> Gigantes </td>
              <td>141-160</td>
            </tr>
            <tr>
              <td>Extra Jumbo</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td> Jumbo</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Extra Grande</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td>Grande</td>
              <td>231-260</td>
            </tr>
            <tr>
              <td> Superior</td>
              <td>261-290</td>
            </tr>
            <tr>
              <td>Briliante</td>
              <td>291-320</td>
            </tr>
            <tr>
              <td>Multa</td>
              <td>321-350</td>
            </tr>
            <tr>
              <td>Balas</td>
              <td>351-380</td>
            </tr>
            <tr>
              <td> </td>
              <td>381-400</td>
            </tr>

            <tr>
              <td> </td>
              <td>401-450</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "5",
      text: (
        <p>
          Esta variedad es la segunda cara de la aceituna manzanilla verde, y es
          una de las variedades más prevalentes en las fincas y fábrica de
          nuestra empresa, debido a la gran demanda de la misma por parte de
          nuestros clientes externos. La manzanilla negra natural se caracteriza
          por sus grandes tamaños y atractivo color violeta, y la fabricamos en
          nuestra fábrica utilizando métodos saludables que preservan el valor
          nutricional de la semilla de aceituna, y también lo clasificamos en 3
          tipos que son: <br />
          1. Aceitunas manzanilla negras naturales enteras
          <br />
          2. Aceitunas manzanilla negras sin hueso natural <br />
          3. Aceitunas manzanilla negras naturales en rodajas <br />
          4. Aceitunas Manzanilla Negras Naturales{" "}
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descripción del artículo</th>
              <th>Tamaño(Normas griegas)</th>
              <th>Trozos de aceitunas/KG(Estándar español)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="11"> Aceitunas Manzanilla Negras Naturales</th>
              <td> colosal </td>
              <td>121-141</td>
            </tr>
            <tr>
              <td>Gigantes</td>
              <td>141-160</td>
            </tr>
            <tr>
              <td> Extra Jumbo</td>
              <td>161-180</td>
            </tr>
            <tr>
              <td> Jumbo</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Extra Grande</td>
              <td>201-230</td>
            </tr>
            <tr>
              <td> Grande</td>
              <td> 231-260</td>
            </tr>
            <tr>
              <td>Superior</td>
              <td>261-290</td>
            </tr>
            <tr>
              <td>Briliante</td>
              <td> 291-320</td>
            </tr>
            <tr>
              <td>Multa</td>
              <td> 321-350</td>
            </tr>
            <tr>
              <td> Balas</td>
              <td> 351-380</td>
            </tr>
            <tr>
              <td></td>
              <td> 381-400</td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "6",
      text: (
        <p>
          Las aceitunas negras oxidadas obtienen su color negro después de un
          proceso de oxidación industrial, durante el cual las aceitunas verdes
          conservadas en agua y sal se someten a un fuerte tratamiento alcalino.
          Contenedores Los envases se esterilizan en autoclave para obtener un
          producto delicioso, saludable y seguro. Las aceitunas negras oxidadas
          se dividen en tres: <br />
          1. Aceitunas negras enteras oxidadas
          <br />
          2. Aceitunas negras oxidadas en rodajas
          <br />
          3. Vinagre de aceitunas negras oxidadas
        </p>
      ),
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Descripción del artículo</th>
              <th>Tamaño(Normas griegas)</th>
              <th>Trozos de aceitunas/KG(Estándar español)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="14">
                {" "}
                Aceitunas negras enteras maduras Aceitunas negras maduras en
                rodajas Aceitunas negras maduras sin hueso
              </th>
              <td> Super Mamut </td>
              <td>91-100</td>
            </tr>
            <tr>
              <td> Mamut</td>
              <td> 101-110</td>
            </tr>
            <tr>
              <td> Súper colosal</td>
              <td>111-120</td>
            </tr>
            <tr>
              <td>colosal</td>
              <td>121-141</td>
            </tr>
            <tr>
              <td>Gigantes</td>
              <td>141-160</td>
            </tr>
            <tr>
              <td> Extra Jumbo</td>
              <td> 161-180</td>
            </tr>
            <tr>
              <td>Jumbo</td>
              <td>181-200</td>
            </tr>
            <tr>
              <td>Extra Grande</td>
              <td> 201-230</td>
            </tr>
            <tr>
              <td>Grande</td>
              <td> 231-260</td>
            </tr>
            <tr>
              <td>Superior </td>
              <td> 261-290</td>
            </tr>
            <tr>
              <td>Briliante</td>
              <td> 291-320</td>
            </tr>

            <tr>
              <td>Multa</td>
              <td> 321-350</td>
            </tr>

            <tr>
              <td>Balas</td>
              <td> 351-380</td>
            </tr>

            <tr>
              <td></td>
              <td> 381-400</td>
            </tr>
          </tbody>
        </table>
      ),
    },
  ];
  function showImage(x) {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-none", "d-flex");
    // console.log("ayhagaaasa")
    document.querySelector(".very").style.backgroundImage = `url(${x.src})`;
    document.querySelector(".productName").innerHTML = x.name;

    currentIndex = x.id;
    // console.log(currentIndex)
    setCurrID(currentIndex);
  }

  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }
  function showForm() {
    document
      .querySelector(".showForm")
      .classList.replace("d-none", "d-inline-block");
  }
  return (
    <>
      <ToastContainer />

      <div className="products " onClick={closeSlide}></div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="1"
                src={image2}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Aceitunas Picual Negras Naturales");
                }}
                alt="image not found"
                className="photo"
                name="Aceitunas Picual Negras Naturales"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              {" "}
              Aceitunas Picual Negras Naturales
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="2"
                src={image3}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Aceitunas Dolci Negras Naturales");
                }}
                alt="image not found"
                className="photo"
                name="Aceitunas Dolci Negras Naturales"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Aceitunas Dolci Negras Naturales
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="3"
                src={image4}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Aceitunas Sersecola Naturales Negras");
                }}
                alt="image not found"
                className="photo"
                name="Aceitunas Sersecola Naturales Negras"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Aceitunas Sersecola Naturales Negras
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="4"
                src={image5}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Aceitunas Kalamata naturales negras");
                }}
                alt="image not found"
                className="photo"
                name="Aceitunas Kalamata naturales negras"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Aceitunas Kalamata naturales negras
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="5"
                src={image6}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Aceitunas Manzanilla Negras Naturales");
                }}
                alt="image not found"
                className="photo"
                name="Aceitunas Manzanilla Negras Naturales"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Aceitunas Manzanilla Negras Naturales
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="6"
                src={image1}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("aceituna oxidada");
                }}
                alt="image not found"
                className="photo"
                name="aceituna oxidada"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              aceituna oxidada{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="lightContainer2 d-none  align-items-center">
        <div className="lightBox2  ">
          <div className="container">
            <div className="row d-flex justify-content-center ">
              <div className="ayhaga" style={{ marginTop: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img className=" very" alt="" style={{ padding: "0" }} />
                </div>
                <h4
                  className="productName"
                  style={{
                    color: "#4e732c",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></h4>
                {tmp.map((each) => {
                  if (each.id === currID) {
                    return (
                      <div>
                        <p style={{ color: "white" }}> {each.text} </p>
                        <div>{each.table}</div>
                      </div>
                    );
                  }
                })}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <button
                    className="btn get bgDark"
                    style={{ marginBottom: "5px" }}
                    onClick={showForm}
                  >
                    Obtener cotización
                  </button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <form onSubmit={sendQuote}>
                    <div className="showForm d-none ">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Introduzca su nombre"
                        style={{ marginBottom: "5px" }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <input
                        type="email"
                        className="form-control"
                        // id="exampleFormControlInput2"
                        id="email"
                        placeholder="Introduce tu correo electrónico"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px",
                        }}
                      >
                        <button
                          className="btn get"
                          style={{ marginTop: "5px" }}
                          type="submit"
                        >
                          {loading ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : (
                            "Enviar"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <img src={image1} alt="image not found" className='lightBox'/> */}

          <i
            style={{
              color: "black",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
            id="close"
            className="fa-solid fa-xmark"
            onClick={closeSlide}
          ></i>
        </div>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="contactContect">
                  <h6> contáctenos</h6>

                  <p>cuando tienes una pregunta estamos aquí para ayudarte</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}

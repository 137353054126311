import React from "react";
import image1 from "../Images/Mr.Olive/packages/plastic/Pet Plastic Jar 2500 Ml.jpg";
import image2 from "../Images/Mr.Olive/packages/plastic/Pet Plastic Jar 3100 Ml (2).jpg";
import image3 from "../Images/Mr.Olive/packages/plastic/Pet PLastic Jar 3100 Ml.jpg";
import image4 from "../Images/Mr.Olive/packages/plastic/PLastic Jar 1 Kg.jpg";
import image5 from "../Images/Mr.Olive/packages/plastic/Pet Plastic Jar 2500 ML (2).jpg";
import image6 from "../Images/Mr.Olive/packages/plastic/Bucket 12 Litres.jpg";
import image7 from "../Images/Mr.Olive/packages/plastic/Bucket 16 Litres.jpg";
import image8 from "../Images/Mr.Olive/packages/plastic/كالاماتا.jpg";
import image9 from "../Images/Mr.Olive/packages/plastic/عجيزي.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  EffectCube,
  EffectFlip,
  Autoplay,
  EffectCoverflow,
} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import "swiper/css/effect-cube";

import "swiper/css/navigation";
import "swiper/css/effect-fade";
export default function SubCategory8() {
  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }

  return (
    <>
      <div className="products " onClick={closeSlide}>

      </div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-6">
            <table class="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">Name Of Package</th>
                  <th scope="col">Items Inside The Package</th>
                  <th scope="col">Drained Weight/Kg</th>
                  <th scope="col">Gross Weight/Kg</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" rowspan="4">
                    Barrel
                  </th>
                  <td style={{ padding: "0" }}> Whole Olives </td>
                  <td style={{ padding: "0" }}> 160 KGS</td>
                  <td>250 KGS</td>
                </tr>
                <tr>
                  <td> Pitted, Sliced And stuffed Olives</td>
                  <td> 140 KGS</td>
                  <td>250 KGS </td>
                </tr>
                <tr>
                  <td> Artichoke</td>
                  <td>150 KGS </td>
                  <td> 250 KGS</td>
                </tr>
                <tr>
                  <td> Peppers</td>
                  <td>140 KGS </td>
                  <td>250 KGS </td>
                </tr>
                {/**---------------------------- */}
                <tr>
                  <th scope="row" rowspan="3">
                    Bucket
                  </th>
                  <td style={{ padding: "0" }}> All products </td>
                  <td style={{ padding: "0" }}> 10 KGS</td>
                  <td>16 KGS</td>
                </tr>
                <tr>
                  <td> All products </td>
                  <td> 5 KGS</td>
                  <td>8 KGS </td>
                </tr>
                <tr>
                  <td> All products </td>
                  <td>3 KGS </td>
                  <td> 5 KGS</td>
                </tr>

                <tr>
                  <th scope="row">Jar (PET)</th>
                  <td style={{ padding: "0" }}> All products </td>
                  <td style={{ padding: "0" }}>
                    {" "}
                    6 Jars per carton and the drained weight of jar is 1.500 kg
                  </td>
                  <td>Gross weight per carton 18 KGS</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="col-md-6"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Swiper
              // install Swiper modules
              modules={[
                Navigation,
                Pagination,
                Autoplay,
                EffectFade,
                EffectFlip,
                EffectCoverflow,
                EffectCube,
              ]}
              spaceBetween={50}
              slidesPerView={2}
              navigation
              autoplay={true}
              loop={true}
            >
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {" "}
                <figure>
                  {" "}
                  <img src={image1} alt="" style={{ width: "100%" }} />
                  <figcaption>Pet Plastic Jar 2500 Ml</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image2} alt="" style={{ width: "100%" }} />
                  <figcaption>Pet Plastic Jar 3100 Ml</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image3} alt="" style={{ width: "100%" }} />
                  <figcaption>Pet PLastic Jar 3100 Ml</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                {" "}
                <figure>
                  {" "}
                  <img src={image4} alt="" style={{ width: "100%" }} />
                  <figcaption>PLastic Jar 1 Kg</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image5} alt="" style={{ width: "100%" }} />
                  <figcaption>Pet Plastic Jar 2500 ML</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image6} alt="" style={{ width: "100%" }} />
                  <figcaption>Bucket 12 Litres</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image7} alt="" style={{ width: "100%" }} />
                  <figcaption>Bucket 16 Litres</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image8} alt="" style={{ width: "100%" }} />
                  <figcaption>Pet PLastic Jar</figcaption>
                </figure>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <figure>
                  {" "}
                  <img src={image9} alt="" style={{ width: "100%" }} />
                  <figcaption>Pet PLastic Jar</figcaption>
                </figure>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

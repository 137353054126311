import React from "react";
import "./Navbar.css";
import logoOlive from "../Images/Mr.Olive/LogoOlive.png";
import flag from "../Images/flag.png";
import spainFlag from "../Images/spain.png";
import { isMobile } from "react-device-detect";
import uk from "../Images/ukFlag.png";
import italyFlag from "../Images/italy.png";

export default function NavbarRuss({ ar, setAr }) {
  return (
    <nav
      className="navbar navbar-expand-lg position-fixed top-0 p-0 m-0 z-3 w-100 "
      style={{ backgroundColor: "#4e732c" }}
    >
      {!isMobile ? (
        <div className="container-fluid">
          <div style={{ width: "14%" }}>
            <a href="/">
              {" "}
              <img src={logoOlive} alt="" className="logoOlive navbar-brand" />
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarNavDropdown"
          >
            <div>
              <ul className="navbar-nav">
                <li className="nav-item text-white ">
                  <a
                    className="nav-link text-white px-3 fs-6"
                    aria-current="page"
                    href="/"
                  >
                    Главная
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white px-3 fs-6" href="about">
                    о нас
                  </a>
                </li>
                <li className="nav-item dropdown dropdown-hover">
                  <a
                    className="nav-link dropdown text-white px-3 fs-6"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    продукция
                  </a>
                  <ul
                    className="dropdown-menu "
                    aria-labelledby="navbarDropdownMenuLink"
                    style={{ backgroundColor: "#4e732c" }}
                  >
                    <li>
                      <a
                        className="dropdown-item fs-6"
                        href="/products/productCat"
                      >
                        Зеленая олива
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item fs-6"
                        href="/products/productCat2"
                      >
                        Черная олива
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item fs-6"
                        href="/products/productCat3"
                      >
                        Перец
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item fs-6"
                        href="/products/productMix"
                      >
                        Размешивать
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white px-3 fs-6" href="/packages">
                    Пакеты
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-white px-3 fs-6"
                    href="/photoGallery"
                  >
                    Галерея
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white px-3 fs-6" href="/contact">
                    Свяжитесь с нами
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <ul
              type="none"
              className="d-flex justify-content-center align-items-center p-0 m-0"
            >
              <li className="nav-item">
                <div className="dropdown">
                  <div
                    className="dropbtn btn"
                    onClick={() => {
                      setAr("ar");
                      localStorage.setItem("lang", "ar");
                    }}
                  >
                    <img src={flag} alt="" style={{ height: "3vh" }} />
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <div className="dropdown">
                  <div
                    className="dropbtn btn"
                    onClick={() => {
                      setAr("eng");
                      localStorage.setItem("lang", "eng");
                    }}
                  >
                    <img src={uk} alt="" style={{ height: "3vh" }} />
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <div className="dropdown">
                  <div
                    className="dropbtn btn"
                    onClick={() => {
                      setAr("spain");
                      localStorage.setItem("lang", "spain");
                    }}
                  >
                    <img src={spainFlag} alt="" style={{ height: "3vh" }} />
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <div className="dropdown">
                  <div
                    className="dropbtn btn"
                    onClick={() => {
                      setAr("italy");
                      localStorage.setItem("lang", "italy");
                    }}
                  >
                    <img src={italyFlag} alt="" style={{ height: "3vh" }} />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="container-fluid m-1">
          <div style={{ width: "40%" }}>
            <a href="/">
              {" "}
              <img src={logoOlive} alt="" className="logoOlive navbar-brand" />
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarNavDropdown"
          >
            <div>
              <ul className="navbar-nav">
                <li className="nav-item text-white ">
                  <a
                    className="nav-link text-white px-3 fs-6"
                    aria-current="page"
                    href="/"
                  >
                    Главная
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white px-3 fs-6" href="about">
                    о нас
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown text-white px-3 fs-6"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    продукция
                  </a>
                  <ul
                    className="dropdown-menu "
                    aria-labelledby="navbarDropdownMenuLink"
                    style={{ backgroundColor: "#4e732c" }}
                  >
                    <li>
                      <a
                        className="dropdown-item fs-6"
                        href="/products/productCat"
                      >
                        Зеленая олива
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item fs-6"
                        href="/products/productCat2"
                      >
                        Черная олива
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item fs-6"
                        href="/products/productCat3"
                      >
                        Перец
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item fs-6"
                        href="/products/productMix"
                      >
                        Размешивать
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white px-3 fs-6" href="/packages">
                    Пакеты
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-white px-3 fs-6"
                    href="/photoGallery"
                  >
                    Галерея
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white px-3 fs-6" href="/contact">
                    Свяжитесь с нами
                  </a>
                </li>
              </ul>
              <div>
                <ul
                  type="none"
                  className="d-flex justify-content-center align-items-center p-0 m-0"
                >
                  <li className="nav-item">
                    <div className="dropdown">
                      <div
                        className="dropbtn btn"
                        onClick={() => {
                          setAr("ar");
                          localStorage.setItem("lang", "ar");
                        }}
                      >
                        <img src={flag} alt="" style={{ height: "3vh" }} />
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="dropdown">
                      <div
                        className="dropbtn btn"
                        onClick={() => {
                          setAr("eng");
                          localStorage.setItem("lang", "eng");
                        }}
                      >
                        <img src={uk} alt="" style={{ height: "3vh" }} />
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="dropdown">
                      <div
                        className="dropbtn btn"
                        onClick={() => {
                          setAr("spain");
                          localStorage.setItem("lang", "spain");
                        }}
                      >
                        <img src={spainFlag} alt="" style={{ height: "3vh" }} />
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="dropdown">
                      <div
                        className="dropbtn btn"
                        onClick={() => {
                          setAr("italy");
                          localStorage.setItem("lang", "italy");
                        }}
                      >
                        <img src={italyFlag} alt="" style={{ height: "3vh" }} />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}

import logo from "./logo.svg";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import Home from "./HomePage/Home";
import AboutUs from "./AboutUs/AboutUs";
import ContactUs from "./ContactUs/ContactUs";
import Packages from "./Packages/Packages";
import PhotoGallery from "./PhotoGallery/PhotoGallery";
// import Products from './Products/Products';
import Products from "./Products/Products";
import NotFound from "./NotFound/NotFound";
import ProductCat from "./Products/ProductCat";
import ProductCat2 from "./Products/ProductCat2";
import ProductCat3 from "./Products/ProductCat3";
import SubCategory from "./Products/SubCategory";
import SubCategory1 from "./Products/SubCategory1";
import SubCategory2 from "./Products/SubCategory2";
import SubCategory3 from "./Products/SubCategory3";
import SubCategory4 from "./Products/SubCategory4";
import SubCategory5 from "./Products/SubCategory5";
import SubCategory6 from "./Products/SubCategory6";
import SubCategory7 from "./Products/SubCategory7";
import SubCategory8 from "./Products/SubCategory8";
import { useLayoutEffect, useState, useEffect } from "react";
import NavbarAr from "./Navbar/NavbarAr";
import HomeAr from "./HomePage/HomeAr";
import AboutUsAr from "./AboutUs/AboutUsAr";
import ContactUsAr from "./ContactUs/ContactUsAr";
import PhotoGalleryAr from "./PhotoGallery/PhotoGalleryAr";
import PackagesAr from "./Packages/PackagesAr";
import FooterAr from "./Footer/FooterAr";
import ProductsAr from "./Products/ProductsAr";
import ProductCatAr from "./Products/ProductCatAr";
import ProductCat2Ar from "./Products/ProductCat2Ar";
import ProductCat3Ar from "./Products/ProductCat3Ar";
import SubCategoryAr from "./Products/SubCategoryAr";
import SubCategory1Ar from "./Products/SubCategory1Ar";
import SubCategory2Ar from "./Products/SubCategory2Ar";
import SubCategory3Ar from "./Products/SubCategory3Ar";
import SubCategory4Ar from "./Products/SubCategory4Ar";
import SubCategory5Ar from "./Products/SubCategory5Ar";
import SubCategory6Ar from "./Products/SubCategory6Ar";
import SubCategory7Ar from "./Products/SubCategory7Ar";
import SubCategory8Ar from "./Products/SubCategory8Ar";
import ProductStuffed from "./Products/ProductStuffed";
import ProductMix from "./Products/ProductMix";
import Artichoke from "./Products/Artichoke";
import Lemon from "./Products/Lemon";
import Cauliflower from "./Products/Cauliflower";
import ProductStuffedAr from "./Products/ProductStuffedAr";
import ProductMixAr from "./Products/ProductMixAr";
import ArtichokeAr from "./Products/ArtichokeAr";
import LemonAr from "./Products/LemonAr";
import CauliflowerAr from "./Products/CauliflowerAr";
import HomeRuss from "./HomePage/HomeRuss";
import AboutUsRuss from "./AboutUs/AboutUsRuss";
import ContactUsRuss from "./ContactUs/ContactUsRuss";
import PackagesRuss from "./Packages/PackagesRuss";
import PhotoGalleryRuss from "./PhotoGallery/PhotoGalleryRuss";
import ProductCatRuss from "./Products/ProductCatRuss";
import ProductCat2Russ from "./Products/ProductCat2Russ";
import ArtichokeRuss from "./Products/ArtichokeRuss";
import LemonRuss from "./Products/LemonRuss";
import CauliflowerRuss from "./Products/CauliflowerRuss";
import ProductStuffedRuss from "./Products/ProductStuffedRuss";
import ProductCat3Russ from "./Products/ProductCat3Russ";
import ProductMixRuss from "./Products/ProductMixRuss";
import SubCategoryRuss from "./Products/subCategoryRuss";
import SubCategory1Russ from "./Products/SubCategory1Russ";
import SubCategory2Russ from "./Products/SubCategory2Russ";
import SubCategory3Russ from "./Products/SubCategory3Russ";
import SubCategory4Russ from "./Products/SubCategory4Russ";
import SubCategory5Russ from "./Products/SubCategory5Russ";
import SubCategory6Russ from "./Products/SubCategory6Russ";
import SubCategory7Russ from "./Products/SubCategory7Russ";
import SubCategory8Russ from "./Products/SubCategory8Russ";
import FooterRuss from "./Footer/FooterRuss";
import NavbarRuss from "./Navbar/NavbarRuss";
import ProductsRuss from "./Products/ProductsRuss";
import NavbarItaly from "./Navbar/NavbarItaly";
import FooterItaly from "./Footer/FooterItaly";
import AboutUsItaly from "./AboutUs/AboutUsItaly";
import ContactUsItaly from "./ContactUs/ContactUsItaly";
import HomeItaly from "./HomePage/HomeItaly";
import PackagesItaly from "./Packages/PackagesItaly";
import PhotoGalleryItaly from "./PhotoGallery/PhotoGalleryItaly";
import ProductsItaly from "./Products/ProductsItaly";
import ProductCatItaly from "./Products/ProductCatItaly";
import ProductCat2Italy from "./Products/ProductCat2Italy";
import ArtichokeItaly from "./Products/ArtichokeItaly";
import LemonItaly from "./Products/LemonItaly";
import CauliflowerItaly from "./Products/CauliflowerItaly";
import ProductStuffedItaly from "./Products/ProductStuffedItaly";
import ProductCat3Italy from "./Products/ProductCat3Italy";
import ProductMixItaly from "./Products/ProductMixItaly";
import SubCategoryItaly from "./Products/SubCategoryItaly";
import SubCategory1Italy from "./Products/SubCategory1Italy";
import SubCategory2Italy from "./Products/SubCategory2Italy";
import SubCategory3Italy from "./Products/SubCategory3Italy";
import SubCategory4Italy from "./Products/SubCategory4Italy";
import SubCategory5Italy from "./Products/SubCategory5Italy";
import SubCategory6Italy from "./Products/SubCategory6Italy";
import SubCategory7Italy from "./Products/SubCategory7Italy";
import SubCategory8Italy from "./Products/SubCategory8Italy";
import NavbarSpain from "./Navbar/NavbarSpain";
import FooterSpain from "./Footer/FooterSpain";
import HomeSpain from "./HomePage/HomeSpain";
import AboutUsSpain from "./AboutUs/AboutUsSpain";
import ContactUsSpain from "./ContactUs/ContactUsSpain";
import PackagesSpain from "./Packages/PackagesSpain";
import PhotoGallerySpain from "./PhotoGallery/PhotoGallerySpain";
import ProductsSpain from "./Products/ProductsSpain";
import ProductCatSpain from "./Products/ProductCatSpain";
import ProductCat2Spain from "./Products/ProductCat2Spain";
import ArtichokeSpain from "./Products/ArtichokeSpain";
import LemonSpain from "./Products/LemonSpain";
import CauliflowerSpain from "./Products/CauliflowerSpain";
import ProductStuffedSpain from "./Products/ProductStuffedSpain";
import ProductCat3Spain from "./Products/ProductCat3Spain";
import ProductMixSpain from "./Products/ProductMixSpain";
import SubCategorySpain from "./Products/SubCategorySpain";
import SubCategory1Spain from "./Products/SubCategory1Spain";
import SubCategory2Spain from "./Products/SubCategory2Spain";
import SubCategory3Spain from "./Products/SubCategory3Spain";
import SubCategory4Spain from "./Products/SubCategory4Spain";
import SubCategory5Spain from "./Products/SubCategory5Spain";
import SubCategory6Spain from "./Products/SubCategory6Spain";
import SubCategory7Spain from "./Products/SubCategory7Spain";
import SubCategory8Spain from "./Products/SubCategory8Spain";
import SocialMediaIcons from "./Components/SocialMediaIcons";

function App() {
  const [ar, setAr] = useState(
    localStorage.getItem("lang") ? localStorage.getItem("lang") : "eng"
  );
  // const [russ, setRuss] =  useState(true);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };
  // useEffect(()=>{
  //   setAr(JSON.parse(localStorage.getItem('lang')));
  //   // console.log(localStorage.getItem('lang'))
  //   // console.log(ar)
  // })
  return (
    <>
      <Wrapper>
        {ar === "ar" && <NavbarAr setAr={setAr} />}
        {ar === "eng" && <Navbar setAr={setAr} />}
        {ar === "russ" && <NavbarRuss setAr={setAr} />}
        {ar === "italy" && <NavbarItaly setAr={setAr} />}
        {ar === "spain" && <NavbarSpain setAr={setAr} />}
        <SocialMediaIcons />

        <Routes>
          {ar === "ar" && (
            <>
              {/* <Route  path='navbarAr' element={<NavbarAr/>}/> */}
              <Route path="/" element={<HomeAr />} />

              <Route path="home" element={<HomeAr />} />
              <Route path="about" element={<AboutUsAr />} />
              <Route path="contact" element={<ContactUsAr />} />
              <Route path="/photoGallery" element={<PhotoGalleryAr />} />
              <Route path="/packages" element={<PackagesAr />} />
              {/* <Route path='footerAr' element={<FooterAr/>} />  */}
              <Route path="/products" element={<ProductsAr />} />
              <Route path="/products/productMix" element={<ProductMixAr />} />
              <Route
                path="/products/productStuffed"
                element={<ProductStuffedAr />}
              />
              <Route path="/products/artichoke" element={<ArtichokeAr />} />
              <Route path="/products/lemon" element={<LemonAr />} />
              <Route path="/products/cauliflower" element={<CauliflowerAr />} />

              <Route path="/products/productCat" element={<ProductCatAr />} />
              <Route path="/products/productCat2" element={<ProductCat2Ar />} />
              <Route path="/products/productCat3" element={<ProductCat3Ar />} />
              <Route path="/products/subCategory" element={<SubCategoryAr />} />
              <Route
                path="/products/subCategory1"
                element={<SubCategory1Ar />}
              />
              <Route
                path="/products/subCategory2"
                element={<SubCategory2Ar />}
              />
              <Route
                path="/products/subCategory3"
                element={<SubCategory3Ar />}
              />
              <Route
                path="/products/subCategory4"
                element={<SubCategory4Ar />}
              />
              <Route
                path="/products/subCategory5"
                element={<SubCategory5Ar />}
              />
              <Route
                path="/products/subCategory6"
                element={<SubCategory6Ar />}
              />
              <Route
                path="/products/subCategory7"
                element={<SubCategory7Ar />}
              />
              <Route
                path="/products/subCategory8"
                element={<SubCategory8Ar />}
              />
            </>
          )}
          {ar === "eng" && (
            <>
              <Route path="/" element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="about" element={<AboutUs />} />
              <Route path="contact" element={<ContactUs />} />
              <Route path="/packages" element={<Packages />} />
              <Route path="/photoGallery" element={<PhotoGallery />} />
              <Route path="/products" element={<Products />} />
              <Route
                exact
                path="/products/productCat"
                element={<ProductCat />}
              />
              <Route path="/products/productCat2" element={<ProductCat2 />} />
              <Route path="/products/artichoke" element={<Artichoke />} />
              <Route path="/products/lemon" element={<Lemon />} />
              <Route path="/products/cauliflower" element={<Cauliflower />} />

              <Route
                path="/products/productStuffed"
                element={<ProductStuffed />}
              />
              <Route path="/products/productCat3" element={<ProductCat3 />} />

              <Route path="/products/productMix" element={<ProductMix />} />
              <Route path="/products/subCategory" element={<SubCategory />} />
              <Route path="/products/subCategory1" element={<SubCategory1 />} />
              <Route path="/products/subCategory2" element={<SubCategory2 />} />
              <Route path="/products/subCategory3" element={<SubCategory3 />} />
              <Route path="/products/subCategory4" element={<SubCategory4 />} />
              <Route path="/products/subCategory5" element={<SubCategory5 />} />
              <Route path="/products/subCategory6" element={<SubCategory6 />} />
              <Route path="/products/subCategory7" element={<SubCategory7 />} />
              <Route path="/products/subCategory8" element={<SubCategory8 />} />
            </>
          )}
          {ar === "russ" && (
            <>
              <Route path="/" element={<HomeRuss />} />
              <Route path="home" element={<HomeRuss />} />
              <Route path="about" element={<AboutUsRuss />} />
              <Route path="contact" element={<ContactUsRuss />} />
              <Route path="/packages" element={<PackagesRuss />} />
              <Route path="/photoGallery" element={<PhotoGalleryRuss />} />
              <Route path="/products" element={<ProductsRuss />} />
              <Route
                exact
                path="/products/productCat"
                element={<ProductCatRuss />}
              />
              <Route
                path="/products/productCat2"
                element={<ProductCat2Russ />}
              />
              <Route path="/products/artichoke" element={<ArtichokeRuss />} />
              <Route path="/products/lemon" element={<LemonRuss />} />
              <Route
                path="/products/cauliflower"
                element={<CauliflowerRuss />}
              />

              <Route
                path="/products/productStuffed"
                element={<ProductStuffedRuss />}
              />
              <Route
                path="/products/productCat3"
                element={<ProductCat3Russ />}
              />

              <Route path="/products/productMix" element={<ProductMixRuss />} />
              <Route
                path="/products/subCategory"
                element={<SubCategoryRuss />}
              />
              <Route
                path="/products/subCategory1"
                element={<SubCategory1Russ />}
              />
              <Route
                path="/products/subCategory2"
                element={<SubCategory2Russ />}
              />
              <Route
                path="/products/subCategory3"
                element={<SubCategory3Russ />}
              />
              <Route
                path="/products/subCategory4"
                element={<SubCategory4Russ />}
              />
              <Route
                path="/products/subCategory5"
                element={<SubCategory5Russ />}
              />
              <Route
                path="/products/subCategory6"
                element={<SubCategory6Russ />}
              />
              <Route
                path="/products/subCategory7"
                element={<SubCategory7Russ />}
              />
              <Route
                path="/products/subCategory8"
                element={<SubCategory8Russ />}
              />
            </>
          )}
          {ar === "italy" && (
            <>
              <Route path="/" element={<HomeItaly />} />
              <Route path="home" element={<HomeItaly />} />
              <Route path="about" element={<AboutUsItaly />} />
              <Route path="contact" element={<ContactUsItaly />} />
              <Route path="/packages" element={<PackagesItaly />} />
              <Route path="/photoGallery" element={<PhotoGalleryItaly />} />
              <Route path="/products" element={<ProductsItaly />} />
              <Route
                exact
                path="/products/productCat"
                element={<ProductCatItaly />}
              />
              <Route
                path="/products/productCat2"
                element={<ProductCat2Italy />}
              />
              <Route path="/products/artichoke" element={<ArtichokeItaly />} />
              <Route path="/products/lemon" element={<LemonItaly />} />
              <Route
                path="/products/cauliflower"
                element={<CauliflowerItaly />}
              />

              <Route
                path="/products/productStuffed"
                element={<ProductStuffedItaly />}
              />
              <Route
                path="/products/productCat3"
                element={<ProductCat3Italy />}
              />

              <Route
                path="/products/productMix"
                element={<ProductMixItaly />}
              />
              <Route
                path="/products/subCategory"
                element={<SubCategoryItaly />}
              />
              <Route
                path="/products/subCategory1"
                element={<SubCategory1Italy />}
              />
              <Route
                path="/products/subCategory2"
                element={<SubCategory2Italy />}
              />
              <Route
                path="/products/subCategory3"
                element={<SubCategory3Italy />}
              />
              <Route
                path="/products/subCategory4"
                element={<SubCategory4Italy />}
              />
              <Route
                path="/products/subCategory5"
                element={<SubCategory5Italy />}
              />
              <Route
                path="/products/subCategory6"
                element={<SubCategory6Italy />}
              />
              <Route
                path="/products/subCategory7"
                element={<SubCategory7Italy />}
              />
              <Route
                path="/products/subCategory8"
                element={<SubCategory8Italy />}
              />
            </>
          )}
          {ar === "spain" && (
            <>
              <Route path="/" element={<HomeSpain />} />
              <Route path="home" element={<HomeSpain />} />
              <Route path="about" element={<AboutUsSpain />} />
              <Route path="contact" element={<ContactUsSpain />} />
              <Route path="/packages" element={<PackagesSpain />} />
              <Route path="/photoGallery" element={<PhotoGallerySpain />} />
              <Route path="/products" element={<ProductsSpain />} />
              <Route
                exact
                path="/products/productCat"
                element={<ProductCatSpain />}
              />
              <Route
                path="/products/productCat2"
                element={<ProductCat2Spain />}
              />
              <Route path="/products/artichoke" element={<ArtichokeSpain />} />
              <Route path="/products/lemon" element={<LemonSpain />} />
              <Route
                path="/products/cauliflower"
                element={<CauliflowerSpain />}
              />

              <Route
                path="/products/productStuffed"
                element={<ProductStuffedSpain />}
              />
              <Route
                path="/products/productCat3"
                element={<ProductCat3Spain />}
              />

              <Route
                path="/products/productMix"
                element={<ProductMixSpain />}
              />
              <Route
                path="/products/subCategory"
                element={<SubCategorySpain />}
              />
              <Route
                path="/products/subCategory1"
                element={<SubCategory1Spain />}
              />
              <Route
                path="/products/subCategory2"
                element={<SubCategory2Spain />}
              />
              <Route
                path="/products/subCategory3"
                element={<SubCategory3Spain />}
              />
              <Route
                path="/products/subCategory4"
                element={<SubCategory4Spain />}
              />
              <Route
                path="/products/subCategory5"
                element={<SubCategory5Spain />}
              />
              <Route
                path="/products/subCategory6"
                element={<SubCategory6Spain />}
              />
              <Route
                path="/products/subCategory7"
                element={<SubCategory7Spain />}
              />
              <Route
                path="/products/subCategory8"
                element={<SubCategory8Spain />}
              />
            </>
          )}
          <Route path="*" element={<NotFound />} />
        </Routes>
        {ar === "ar" && <FooterAr />}
        {ar === "eng" && <Footer />}
        {ar === "russ" && <FooterRuss />}
        {ar === "italy" && <FooterItaly />}
        {ar === "spain" && <FooterSpain />}
      </Wrapper>
    </>
  );
}

export default App;

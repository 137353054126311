import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Products.css";
import contact from "../Images/Group-of-customer-service-representatives.jpg";
import image1 from "../Images/Mr.Olive/jalapeno/فلفل شيري سليم.jpg";
import image2 from "../Images/Mr.Olive/jalapeno/فلفل شيري مخلي.jpg";
import image3 from "../Images/Mr.Olive/jalapeno/فلفل هاليبينو أحمر سليم.jpg";
import image4 from "../Images/Mr.Olive/jalapeno/فلفل هاليبينو أحمر شرائح.jpg";
import image5 from "../Images/Mr.Olive/jalapeno/فلفل لمباردي.jpg";
import image6 from "../Images/Mr.Olive/jalapeno/فلفل مكسيكي.jpg";
import image7 from "../Images/Mr.Olive/jalapeno/فلفل هاليبينو أخضر سليم 1.jpg";
import image8 from "../Images/Mr.Olive/jalapeno/هاليبينو اخضر.jpg";
import image9 from "../Images/Mr.Olive/jalapeno/هريسة.jpg";
import image10 from "../Images/Mr.Olive/mix/فلفل بنانا شرائح مخلي.jpg";
import image24 from "../Images/Mr.Olive/packages/_KH13102-Recovered.jpg";
import image25 from "../Images/Mr.Olive/jalapeno/edited 3.jpg";
import image26 from "../Images/Mr.Olive/jalapeno/edited 4.jpg";

export default function ProductCat3() {
  const [currID, setCurrID] = useState("");
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  async function sendQuote(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `https://mrolive-back-end-2c3o.onrender.com/api/v1/quote`,
        {
          name: name,
          email: email,
          productName: productName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.msg);
        setName("");
        setEmail("");
        document.getElementById("name").value = "";
        document.getElementById("email").value = "";
      })
      .catch((e) => {
        toast.error(e?.data?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let imgList = Array.from(document.getElementsByClassName("imgItem"));
  let lightContainer = document.querySelector(".lightContainer");
  let lightBox = document.querySelector(".lightBox");
  let currentIndex = 0;
  let tmp = [
    {
      id: "1",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th>Length</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Whole Red Cherry Peppers</th>
              <td> 25-50 MM </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "2",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th>Length</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th> Pitted Red Cherry Peppers</th>
              <td> 25-50 MM </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "3",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th>Length</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th> Whole Red Jalapeno Peppers</th>
              <td> 4-8 CM </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "4",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th>Thickness of Slices </th>
              <th>Diameter of Slices</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th> Sliced Red Jalapeno Peppers</th>
              <td>1 cm </td>
              <td>1.5 cm : 3.5 cm </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    { id: "5", text: ``, table: "" },
    {
      id: "6",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th colSpan="2">Length</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowSpan="2">Mexican Peppers</th>
              <td> Medium </td>
              <td> 3-6 CM </td>
            </tr>
            <tr>
              <td> Large </td>
              <td> 6-10 CM </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "7",
      text: ` `,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th>Length</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th> Whole Green Jalapeno Peppers</th>
              <td> 4-8 CM </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      id: "8",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th>Thickness of Slices </th>
              <th>Diameter of Slices</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th> Sliced Green Jalapeno Peppers</th>
              <td> 0.5 cm </td>
              <td> 1.5 cm : 3 cm </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    { id: "9", text: `` },
    {
      id: "10",
      text: ``,
      table: (
        <table class="  table-bordered ">
          <thead>
            <tr>
              <th>Item Description</th>
              <th>Thickness of Slices </th>
              <th>Diameter of Slices</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th> Sliced Banana Peppers</th>
              <td> 6 MM </td>
              <td>3 cm : 6 cm </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    { id: "11", text: `` },
  ];
  function showImage(x) {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-none", "d-flex");
    // console.log("ayhagaaasa")
    document.querySelector(".very").style.backgroundImage = `url(${x.src})`;
    document.querySelector(".productName").innerHTML = x.name;

    currentIndex = x.id;
    setCurrID(currentIndex);

    // console.log(currentIndex)
  }

  function closeSlide() {
    document
      .querySelector(".lightContainer2")
      .classList.replace("d-flex", "d-none");
  }
  function showForm() {
    document
      .querySelector(".showForm")
      .classList.replace("d-none", "d-inline-block");
  }
  return (
    <>
      <ToastContainer />

      <div className="products "></div>
      <div className="container p-4">
        <div className="row p-3">
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="1"
                src={image1}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Whole Cherry pepper");
                }}
                alt="image not found"
                className="photo"
                name="Whole Cherry pepper"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Whole Cherry pepper
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="2"
                src={image2}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Pitted cherry pepper");
                }}
                alt="image not found"
                className="photo"
                name="Pitted cherry pepper"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Pitted cherry pepper
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="3"
                src={image3}
                onClick={(e) => showImage(e.target)}
                alt="image not found"
                className="photo"
                name="Whole Red Jalapeno pepper"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Whole Red Jalapeno pepper
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="4"
                src={image4}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Sliced red jalapeno pepper");
                }}
                alt="image not found"
                className="photo"
                name="Sliced red jalapeno pepper"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Sliced red jalapeno pepper
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="5"
                src={image5}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Lombardy pepper");
                }}
                alt="image not found"
                className="photo"
                name="Lombardy pepper"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Lombardy pepper
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="6"
                src={image6}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Mexican pepper");
                }}
                alt="image not found"
                className="photo"
                name="Mexican pepper"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Mexican pepper
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="7"
                src={image7}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Whole Green Jalapeno Pepper");
                }}
                alt="image not found"
                className="photo"
                name="Whole Green Jalapeno Pepper"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Whole Green Jalapeno Pepper
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="8"
                src={image8}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("sliced green jalapeno Pepper");
                }}
                alt="image not found"
                className="photo"
                name="sliced green jalapeno Pepper"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              sliced green jalapeno Pepper
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="9"
                src={image9}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("mashed red pepper");
                }}
                alt="image not found"
                className="photo"
                name="mashed red pepper"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              mashed red pepper
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className="  imgItem">
              <img
                id="10"
                src={image10}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Sliced pitted Banana Pepper");
                }}
                alt="image not found"
                className="photo"
                name="Sliced pitted Banana Pepper"
              />
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}>
              Sliced pitted Banana Pepper
            </p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className=" imgItem">
              <img
                id="11"
                src={image24}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("half pepper");
                }}
                alt="image not found"
                className="photo"
                name="half pepper"
              />{" "}
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}></p>
          </div>
          <div className="col-md-4 photoContainer2">
            <div className=" imgItem">
              <img
                id="25"
                src={image25}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("Red Pepper");
                }}
                alt="image not found"
                className="photo"
                name="Red Pepper"
              />{" "}
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}></p>
          </div>{" "}
          <div className="col-md-4 photoContainer2">
            <div className=" imgItem">
              <img
                id="26"
                src={image26}
                onClick={(e) => {
                  showImage(e.target);
                  setProductName("half red pepper");
                }}
                alt="image not found"
                className="photo"
                name="half red pepper"
              />{" "}
            </div>
            <p style={{ color: "#4e732c", textAlign: "center" }}></p>
          </div>
        </div>
      </div>
      <div className="lightContainer2 d-none  align-items-center">
        <div className="lightBox2  ">
          <div className="container">
            <div className="row d-flex justify-content-center ">
              <div className="ayhaga" style={{ marginTop: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img className=" very" alt="" style={{ padding: "0" }} />
                </div>
                <h4
                  className="productName"
                  style={{
                    color: "#4e732c",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></h4>
                {tmp.map((each) => {
                  if (each.id === currID) {
                    return (
                      <div>
                        <p style={{ color: "white" }}>
                          {" "}
                          Egypt has become one of the leading countries in the
                          production and manufacture of pepper of all kinds. Our
                          company has introduced modern methods in agriculture,
                          manufacturing and packaging, as well as global
                          competitive prices.{" "}
                        </p>
                        <div>{each.table}</div>
                      </div>
                    );
                  }
                })}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px",
                  }}
                >
                  <button
                    className="btn get bgDark"
                    style={{ marginBottom: "5px" }}
                    onClick={showForm}
                  >
                    Get Quote
                  </button>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <form onSubmit={sendQuote}>
                    <div className="showForm d-none ">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter Your Name"
                        style={{ marginBottom: "5px" }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <input
                        type="email"
                        className="form-control"
                        // id="exampleFormControlInput2"
                        id="email"
                        placeholder="Enter Your Email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px",
                        }}
                      >
                        <button
                          className="btn get"
                          style={{ marginTop: "5px" }}
                          type="submit"
                        >
                          {loading ? (
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <img src={image1} alt="image not found" className='lightBox'/> */}

          <i
            style={{
              color: "black",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
            id="close"
            className="fa-solid fa-xmark"
            onClick={closeSlide}
          ></i>
        </div>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="contactContect">
                  <h6> CONTACT US</h6>

                  <p>when you have a question we're here to help</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}
